import { useState, useEffect, useContext } from "react";
import { AddNewDeal } from "./AddNewDeal";
import { DealsTable } from "./DealsTable";
import { Grid } from "@mui/material";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import useGetDeals from "src/data/deals/useGetDeals";
import { Deal, DealFilters } from "src/business/deal";
import useUpdateDealStatus from "src/data/deals/useUpdateDealStatus";
import { useTranslation } from "react-i18next";

interface Props {
  restaurant_id?: string;
  compact?: boolean;
}

export const Deals = ({ restaurant_id, compact = false }: Props) => {
  const { t } = useTranslation();

  const { deals, refetch, isLoading } = useGetDeals({ restaurant_id });
  const { updateDealStatus } = useUpdateDealStatus(refetch);
  const { restaurants, isLoading: restaurantsLoading } =
    useContext(RestaurantContext);

  const [loading, setLoading] = useState(true);
  const [AddDealPage, setAddDealPage] = useState(false);
  const [selectedDeal, setSelectedDeal] = useState<Deal>();
  const handleOpen = () => setAddDealPage(true);
  const handleClose = () => {
    setSelectedDeal(undefined);
    setAddDealPage(false);
  };
  const [Filters, setFilters] = useState<DealFilters>({
    status: [t("deals.active"), t("deals.inactive")],
    restaurantName: [],
    type: [t("deals.deal"), t("deals.event")],
  });

  useEffect(() => {
    if (!restaurantsLoading && !isLoading) {
      var RestaurantNameList: string[] = [];
      deals.forEach((deal) => {
        const restaurantName = restaurants.find(
          (restaurant) => restaurant.restaurant_id === deal.restaurant_id
        )?.Nom as string;
        deal.restaurantName = restaurantName;
        if (RestaurantNameList.indexOf(restaurantName) === -1) {
          RestaurantNameList.push(restaurantName);
        }
      });
      setFilters({
        ...Filters,
        restaurantName: RestaurantNameList,
      });
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deals, restaurants]);

  return (
    <Grid width={"100%"}>
      {AddDealPage || selectedDeal ? (
        <AddNewDeal
          restaurants={restaurants}
          getDeals={refetch}
          handleClose={handleClose}
          deal={selectedDeal}
        />
      ) : (
        <DealsTable
          setSelectedDeal={setSelectedDeal}
          compact={compact}
          handleOpen={handleOpen}
          deals={deals}
          loading={loading}
          Filters={Filters}
          updateStatus={updateDealStatus}
        />
      )}
    </Grid>
  );
};
