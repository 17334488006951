import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const NewUserM = ({show, handleClose}) => {

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header>
            <Modal.Title>Vous y etes presque</Modal.Title>
            </Modal.Header>
            <Modal.Body>Bravo, vous venez de créer votre compte avec succés. Cependant il vous reste encore plusieurs réglages à effectuer avant que votre restaurant ne soit visible aux client. </Modal.Body>
            <Modal.Footer>
            <Button variant="primary" onClick={handleClose}>
                Allons-y
            </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default NewUserM