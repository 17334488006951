import { Grid } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import { TailSpin } from "react-loader-spinner";
import RequestsTable from "src/components/Main/Pages/AdminDashboard/AccountRequests/RequestsTable";

const AccountRequests = () => {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const { userInfo } = useContext(UserContext);

  const GetRequests = () => {
    if (!userInfo) return;
    fetch("https://laddition.ma/Website/GetAccountRequests.php", {
      method: "POST",
      body: JSON.stringify({
        ownerToken: userInfo.session_token,
        ownerId: userInfo.id,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setRequests(response.requests);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    GetRequests();
  }, []);

  return (
    <Grid width={"100%"}>
      {loading ? (
        <TailSpin color="#00BFFF" height={80} width={80} />
      ) : (
        <div>
          {requests.length === 0 ? (
            <p>No requests</p>
          ) : (
            <RequestsTable requests={requests} getRequests={GetRequests} />
          )}
        </div>
      )}
    </Grid>
  );
};

export default AccountRequests;
