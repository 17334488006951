import type { userEvent } from "src/components/Main/Pages/UserDashboard/types/userEvent";
import { useTranslation } from "react-i18next";
import { blue, orange, purple } from "src/design/ColorPalette";
import { AreaChart, ElevatedPaper } from "src/design";

interface Props {
  events?: userEvent;
  totalClicks?: number;
  progress?: number;
}

export const VisibilityClickKpi = ({
  events,
  totalClicks,
  progress,
}: Props) => {
  const { t } = useTranslation();

  if (!events) return null;

  const clickEvents = Object.values(events.RestaurantClicked);
  const dateArray = Object.keys(events.RestaurantClicked);

  return (
    <ElevatedPaper
      elevation={8}
      innerRadius={0}
      outerRadius={0}
      padding={0}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      textAlign="center"
      fullHeight
    >
      <AreaChart
        title={t("statistics.visibilityKpi.clickLabel")}
        dataKeys={["clicks"]}
        data={
          clickEvents.some((event) => event > 0)
            ? dateArray.map((date, index) => ({
                name: date,
                clicks: clickEvents[index],
              }))
            : []
        }
        palette={[blue]}
        value={totalClicks?.toString()}
        progression={progress}
      />
    </ElevatedPaper>
  );
};

export const VisibilityMenuEvents = ({ events }: Props) => {
  const { t } = useTranslation();

  if (!events) return null;

  const menuEvents = Object.values(events.MenuOpenned);
  const dateArray = Object.keys(events.MenuOpenned);

  return (
    <ElevatedPaper
      elevation={8}
      padding={0}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      textAlign="center"
      fullHeight
      outerRadius={0}
    >
      <AreaChart
        title={t("statistics.visibilityKpi.menuLabel")}
        dataKeys={["menu"]}
        data={
          menuEvents.some((menuEvent) => menuEvent > 0)
            ? dateArray.map((date, index) => ({
                name: date,
                menu: menuEvents[index],
              }))
            : []
        }
        palette={[purple]}
        value={events.TotalMenuClicks.toString()}
        progression={events.TotalMenuClicksProgression}
      />
    </ElevatedPaper>
  );
};

export const VisibilityBookingsKpi = ({ events }: Props) => {
  const { t } = useTranslation();

  if (!events) return null;

  const bookingEvents = Object.values(events.BookingsOpened);
  const dateArray = Object.keys(events.BookingsOpened);

  return (
    <ElevatedPaper
      elevation={8}
      innerRadius={0}
      outerRadius={0}
      padding={0}
      outerPadding={0}
      fullHeight
      textAlign="center"
      borderTop={"4px solid #1abc9c"}
    >
      <AreaChart
        title={t("statistics.visibilityKpi.bookingLabel")}
        dataKeys={["bookingsStarted"]}
        data={
          bookingEvents.some((booking_event) => booking_event > 0)
            ? dateArray.map((date, index) => ({
                name: date,
                bookingsStarted: bookingEvents[index],
              }))
            : []
        }
        palette={[orange]}
        value={events.TotalBookingCLicks.toString()}
        progression={events.TotalBookingCLicksProgression}
      />
    </ElevatedPaper>
  );
};
