import { useContext, useState } from "react";
import { NewDeal } from "src/business/deal";
import { UserContext } from "src/Utils/Context/UserContext";

const useAddDeal = (refetch: () => void) => {
  const { userInfo } = useContext(UserContext);
  const [uploading, setUploading] = useState(false);

  const addDeal = async (deal: NewDeal) => {
    setUploading(true);
    fetch(`https://laddition.ma/Website/AddDeal.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        description: deal.description,
        days: deal.days,
        hours: deal.hours,
        restaurant_id: deal.restaurant_id,
        is_event: Number(deal.is_event),
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          refetch();
        } else {
          console.log(response.error);
        }
        setUploading(false);
      })
      .catch((e) => {});
  };

  return { addDeal, uploading };
};

export default useAddDeal;
