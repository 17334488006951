import Homepage from "./components/HomePage";
import LanguageChangeButton from "./Utils/Translations/LanguageChangeButton";

function App({ setUserInfo }) {
  return (
    <div className="App">
      <Homepage />
      <LanguageChangeButton />
    </div>
  );
}

export default App;
