import { UserContext } from "src/Utils/Context/UserContext";
import { useContext, useState } from "react";

const useUpdateDealStatus = (refetch: () => void) => {
  const { userInfo } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  const updateDealStatus = async (dealId: string, status: number) => {
    setUpdating(true);
    fetch(`https://laddition.ma/Website/UpdateDealStatus.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        id: dealId,
        statut: status,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          refetch();
        }
        setUpdating(false);
      })
      .catch((e) => {
        setUpdating(false);
      });
  };

  return { updateDealStatus, updating };
};

export default useUpdateDealStatus;
