import { CustomModal, CustomText } from "src/design";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { CalendarPicker as MuiCalendar } from "@mui/x-date-pickers";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import { TailSpin } from "react-loader-spinner";
import dayjs, { Dayjs } from "dayjs";
import { Badge } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

const lightTheme = createTheme({ palette: { mode: "light" } });

interface LeaveModalProps {
  open: boolean;
  onClose: () => void;
  restaurantId: string;
}

const CustomPickersDay = styled(PickersDay)(({ theme, selected }) => ({
  ...(selected && {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.main,
    },
  }),
}));

const initialValue = dayjs();

function ServerDay({
  day,
  selected,
  onClick,
}: {
  day: Dayjs;
  selected: boolean;
  onClick: (day: Dayjs) => void;
}) {
  return (
    <Badge
      key={day.toString()}
      overlap="circular"
      badgeContent={selected ? "❗" : undefined}
    >
      <CustomPickersDay
        day={day}
        onDaySelect={() => onClick(day)}
        outsideCurrentMonth={false}
        selected={selected}
      />
    </Badge>
  );
}

const LeaveModal = ({ open, onClose, restaurantId }: LeaveModalProps) => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);
  const [leaves, setLeaves] = useState<string[]>([]);
  const [fetching, setFetching] = useState(false);

  useEffect(() => {
    GetLeaves();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const GetLeaves = () => {
    if (!userInfo) return;

    setFetching(true);
    fetch(`https://laddition.ma/Website/GetRestaurantLeaves.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo.id,
        ownerToken: userInfo.session_token,
        restaurantId: restaurantId,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLeaves(
            response.leaves.map((leave: { date: string }) =>
              dayjs(leave.date).format("YYYY-MM-DD")
            )
          );
        }
        setFetching(false);
      })
      .catch(() => setFetching(false));
  };

  const setLeave = (day: Dayjs) => {
    const newLeave = day.format("YYYY-MM-DD");
    const updateMethod = leaves.includes(newLeave) ? "remove" : "add";

    fetch(`https://laddition.ma/Website/UpdateRestaurantLeaves.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        restaurantId: restaurantId,
        leave: newLeave,
        method: updateMethod,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (!response.success) return;
        if (leaves.includes(day.format("YYYY-MM-DD"))) {
          setLeaves(leaves.filter((leave) => leave !== newLeave));
        } else {
          setLeaves([...leaves, newLeave]);
        }
      });
  };

  return (
    <CustomModal show={open} setShow={onClose}>
      <CustomText
        label={t("restaurant.restaurantInfoPage.leaveDays.header")}
        type="header"
      />
      <CustomText
        label={t("restaurant.restaurantInfoPage.leaveDays.subheader")}
        type="subheader"
      />
      {fetching ? (
        <TailSpin color="#00BFFF" height={80} width={80} />
      ) : (
        <ThemeProvider theme={lightTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MuiCalendar
              date={initialValue}
              onChange={() => {}}
              renderDay={(day) => (
                <ServerDay
                  day={day}
                  selected={leaves.includes(day.format("YYYY-MM-DD"))}
                  onClick={() => setLeave(day)}
                />
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      )}
    </CustomModal>
  );
};

export default LeaveModal;
