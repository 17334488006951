import { useState, useEffect, useCallback } from "react";
import { CompactRestauranDetails } from "src/business/restaurant";
import { useParams } from "react-router-dom";

const defaultRestaurant: CompactRestauranDetails = {
  id: "",
  live: false,
};

const useGetRestaurantBySlug = () => {
  const { slug, city } = useParams<{ slug: string; city: string }>();

  const [restaurant, setRestaurant] =
    useState<CompactRestauranDetails>(defaultRestaurant);
  const [isLoading, setLoading] = useState(true);

  const getRestaurantBySlug = useCallback(async () => {
    const response = await fetch(
      `https://laddition.ma/Website/GetRestaurantBySlug.php?slug=${slug}&city=${city}`
    );
    const restaurant = await response.json();
    return restaurant;
  }, [city, slug]);

  useEffect(() => {
    getRestaurantBySlug().then((restaurant: CompactRestauranDetails) => {
      setRestaurant(restaurant);
      setLoading(false);
    });
  }, [slug, city, getRestaurantBySlug]);

  return { restaurant, isLoading };
};

export default useGetRestaurantBySlug;
