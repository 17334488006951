import { Box } from "@mui/material";
import { Divider } from "./Divider";
import { CustomText } from "./CustomText";

export const SectionHeader = ({ label }) => (
  <Box
    style={{
      display: "flex",
      alignItems: "center",
      textWrap: "nowrap",
      gap: 15,
    }}
  >
    <CustomText label={label} type="section" textWrap="nowrap" />
    <Divider />
  </Box>
);
