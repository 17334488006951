import { TextKpi } from "src/design";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  seatsFilled?: number;
  progress?: number;
}

const SeatsFilledKpi = ({ seatsFilled, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item md={6} xs={12}>
      <TextKpi
        title={t("statistics.seatsFilledLabel")}
        value={seatsFilled?.toString()}
        progress={progress}
      />
    </Grid>
  );
};

export default SeatsFilledKpi;
