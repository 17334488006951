import { Grid, Button, IconButton, Link, Typography } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import { useNavigate } from "react-router-dom";
import { urls } from "src/urls";
import { CustomText } from "src/design";
import { lightBlue, emerald } from "src/design/ColorPalette";
import { useTranslation } from "react-i18next";

export const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Grid
      justifyContent={"space-between"}
      container
      rowGap={3}
      width={"100%"}
      sx={{ backgroundColor: lightBlue, color: "white", padding: 2 }}
      textAlign={"center"}
    >
      <Grid
        item
        md={3}
        container
        direction={"column"}
        textAlign={"left"}
        justifyContent={"space-between"}
      >
        <Grid sx={{ display: { xs: "none", md: "flex" } }}>
          <img
            src={require("src/assets/RestaurantIcons/laddition_icon_with_text.png")}
            alt="laaddition logo"
            height={40}
          />
        </Grid>{" "}
        <Grid item sx={{ marginLeft: 1 }}>
          <Link
            onClick={() => null}
            href="mailto:support@laddition.ma?subject=Account%20Assistance."
          >
            <CustomText
              fontcolor="white"
              type="body"
              label="email: support@laddition.ma"
              weight={"bold"}
            />
          </Link>
          <CustomText
            fontcolor="white"
            type="body"
            label={t("footer.phone", { phone: "+212 6 17 51 21 91" })}
            weight={"bold"}
          />
        </Grid>
      </Grid>
      <Grid item md={3} container direction={"column"}>
        <Button
          color="inherit"
          size="small"
          sx={{
            ":hover": {
              color: emerald,
            },
          }}
        >
          {t("footer.about")}
        </Button>
        <Button
          color="inherit"
          size="small"
          href={urls.SUPPORT}
          sx={{
            ":hover": {
              color: emerald,
            },
          }}
        >
          {t("footer.contact")}
        </Button>
        <Button
          color="inherit"
          size="small"
          onClick={() => navigate("Terms&Conditions")}
          sx={{
            ":hover": {
              color: emerald,
            },
          }}
        >
          {t("footer.termsAndConditions")}
        </Button>
        <Typography variant="body2" color="white">
          Illustration by{" "}
          <Link
            style={{ color: "white" }}
            onClick={() => null}
            href="https://icons8.com"
          >
            Icons 8
          </Link>{" "}
          from{" "}
          <Link
            style={{ color: "white" }}
            onClick={() => null}
            href="https://icons8.com/illustrations"
          >
            Ouch!
          </Link>
        </Typography>
      </Grid>
      <Grid item md={3} container direction={"column"}>
        <Button
          onClick={() => {}}
          href="https://www.instagram.com/laddition_maroc?igsh=MWxhbjdwMjFkczhqOQ=="
          color="inherit"
          size="medium"
          startIcon={<InstagramIcon />}
          sx={{
            ":hover": {
              color: emerald,
            },
          }}
        >
          Instagram
        </Button>
      </Grid>
      <Grid
        item
        md={3}
        container
        direction={"column"}
        textAlign={"center"}
        justifyContent={"space-between"}
      >
        <CustomText
          fontcolor="white"
          weight={"bold"}
          label={t("footer.downloadTheApp")}
        />
        <IconButton href="https://apps.apple.com/fr/app/laddition/id6502578813">
          <img
            src={require("src/assets/apple-app-store-logo.png")}
            width={120}
            alt="apple-store link"
          />
        </IconButton>
      </Grid>
    </Grid>
  );
};
