import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";

const theme = createTheme({
  palette: {
    primary: {
      main: "#1abc9c",
    },
  },
});

export const ToggleSelect = ({
  options,
  label,
  value,
  onChange,
  exclusive = true,
  names,
}) => (
  <ThemeProvider theme={theme}>
    <Box
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {label && <h6>{label}</h6>}
      <ToggleButtonGroup
        color="primary"
        value={value}
        exclusive={exclusive}
        onChange={onChange}
        aria-label="Platform"
        defaultValue={value}
      >
        {options.map((option, index) => (
          <ToggleButton size="small" value={option} key={index}>
            {names[index]}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  </ThemeProvider>
);
