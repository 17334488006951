import useGetEvents from "src/components/Main/Pages/UserDashboard/data/useGetEvents";
import { UserContext } from "src/Utils/Context/UserContext";
import { useContext, useState } from "react";
import { Grid } from "@mui/material";
import SearchingGif from "src/assets/Animations/Searching.gif";
import ChooseProfileGif from "src/assets/Animations/ChooseProfile.gif";
import { CustomText } from "src/design";
import dayjs from "dayjs";
import {
  VisibilityBookingsKpi,
  VisibilityClickKpi,
  VisibilityMenuEvents,
} from "src/components/Main/Pages/UserDashboard/components/VisibilityKpi";
import UsersCountryKpi from "src/components/Main/Pages/UserDashboard/components/UsersCountryKpi";
import BoookingsKpi from "src/components/Main/Pages/UserDashboard/components/BookingsKpi";
import BookingsByStatusKpi from "src/components/Main/Pages/UserDashboard/components/BookingsByStatusKpi";
import UserDashboardHeader from "src/components/Main/Pages/UserDashboard/components/UserDashboardHeader";
import AvgPartySize from "src/components/Main/Pages/UserDashboard/components/AvgPartySize";
import OccupancyKpi from "src/components/Main/Pages/UserDashboard/components/OccupancyKpi";
import SeatsFilledKpi from "src/components/Main/Pages/UserDashboard/components/SeatsFilledKpi";
import VisitorsKpi from "src/components/Main/Pages/UserDashboard/components/VisitorsKpi";
import BookingTrendByDay from "src/components/Main/Pages/UserDashboard/components/BookingTendByDay";

export const dashBoardColorPallet = [
  "#003f5c",
  "#2f4b7c",
  "#665191",
  "#a05195",
  "#d45087",
  "#f95d6a",
  "#ff7c43",
  "#ffa600",
];

const UserDashboard = () => {
  const today = dayjs().format("YYYY-MM-DD");
  const oneWeekAgo = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const { userInfo } = useContext(UserContext);
  const [restaurant, setRestaurant] = useState<string>();
  const [startDate, setStartDate] = useState<string>(oneWeekAgo.toString());
  const diff = Math.abs(dayjs(startDate).diff(dayjs(today), "day"));
  const [endDate, setEndDate] = useState<string>(today.toString());

  const { loading, events, dateArray } = useGetEvents({
    id: userInfo?.id,
    session_token: userInfo?.session_token,
    start_date: startDate,
    end_date: endDate,
    current_kpi_start: startDate,
    current_kpi_end: endDate,
    previous_kpi_start: dayjs(startDate)
      .subtract(diff, "day")
      .format("YYYY-MM-DD"),
    restaurant_id: restaurant,
  });

  return (
    <Grid container>
      <UserDashboardHeader
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        startDate={startDate}
        endDate={endDate}
        today={dayjs(today)}
        setRestaurant={setRestaurant}
      />
      <Grid item xs={12}>
        {!restaurant ? (
          <>
            <Grid container justifyContent={"center"}>
              <img src={ChooseProfileGif} alt="Choose Profile" />
            </Grid>
            <Grid container justifyContent={"center"}>
              <CustomText
                component="h1"
                type="header"
                label="Please choose a restaurant to access the dashboard"
              />
            </Grid>
          </>
        ) : (
          <Grid container justifyContent={"center"} spacing={2}>
            {loading ? (
              <img src={SearchingGif} alt="Searching" />
            ) : (
              <>
                <Grid item md={8} xs={12}>
                  <BoookingsKpi events={events?.bookingEvents} />
                </Grid>
                <Grid container item md={4} xs={6} spacing={1}>
                  <AvgPartySize
                    avgPartySize={events?.bookingEvents.simpleKpi.avgPartySize}
                  />
                  <OccupancyKpi
                    occupancyRate={
                      events?.bookingEvents.comparativeKpi.occupancyRate
                    }
                    progress={
                      events?.bookingEvents.comparativeKpi.occupancyRateProgress
                    }
                  />
                  <SeatsFilledKpi
                    seatsFilled={
                      events?.bookingEvents.comparativeKpi.seatsFilled
                    }
                    progress={
                      events?.bookingEvents.comparativeKpi.seatsFilledProgress
                    }
                  />
                  <VisitorsKpi
                    visitors={events?.userEvent.TotalRestaurantClicks}
                    progress={
                      events?.userEvent.TotalRestaurantClicksProgression
                    }
                  />
                </Grid>
                <Grid item md={6} xs={10}>
                  <BookingsByStatusKpi
                    events={events?.bookingEvents.groupedBookingsByStatus}
                    dateArray={dateArray}
                  />
                </Grid>
                <Grid item md={6} xs={10}>
                  <VisibilityClickKpi
                    events={events?.userEvent}
                    totalClicks={events?.userEvent.TotalRestaurantClicks}
                    progress={
                      events?.userEvent.TotalRestaurantClicksProgression
                    }
                  />
                </Grid>
                <Grid item md={6} xs={10}>
                  <VisibilityMenuEvents events={events?.userEvent} />
                </Grid>
                <Grid item md={6} xs={10}>
                  <BookingTrendByDay
                    groupedBookingsByDay={
                      events?.bookingEvents.groupedBookingsByDay
                    }
                  />
                </Grid>
                <Grid item md={3} xs={10}>
                  <UsersCountryKpi
                    events={events?.bookingEvents.groupedBookingsByCity}
                  />
                </Grid>
                <Grid item md={9} xs={10}>
                  <VisibilityBookingsKpi events={events?.userEvent} />
                </Grid>
              </>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default UserDashboard;
