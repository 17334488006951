import { TextKpi } from "src/design";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const AvgPartySize = ({ avgPartySize }: { avgPartySize?: number }) => {
  const { t } = useTranslation();

  return (
    <Grid item md={6} xs={12}>
      <TextKpi
        title={t("statistics.avgPartySize")}
        value={
          avgPartySize
            ? (Math.floor(avgPartySize * 100) / 100).toString()
            : "N/A"
        }
      />
    </Grid>
  );
};

export default AvgPartySize;
