import { useEffect, useState } from "react";
import Placeholder from "react-bootstrap/Placeholder";
import { SectionHeader, CustomText } from "src/design";
import { Grid, Button, IconButton } from "@mui/material";
import MenuSection from "src/components/Main/Pages/Home/RestaurantInformation/Menu/MenuSection";
import { FetchResponse } from "src/Utils/Data/Hooks";
import { groupBy } from "src/Utils/ObjectTreatment";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import { CustomLoadingButton, SnackBar } from "src/design";
import { useTranslation } from "react-i18next";

export interface SectionItem {
  name: string;
  description: string;
  price: string;
  featured: 1 | 0;
}

interface UploadMenuPayload extends SectionItem {
  sectionName: string;
}

export interface Section {
  name: string;
  active: boolean;
  featuredItem: number;
  items: SectionItem[];
}

interface MenuProps {
  restaurant_id: string;
  id: string;
  session_token: string;
}

interface MenuItem extends SectionItem {
  section: string;
}

interface GetMenuResponse extends FetchResponse {
  Menu?: MenuItem[];
}

const DEAULT_SECTIONS: Section = {
  name: "Section 1",
  active: true,
  featuredItem: 0,
  items: [
    {
      name: "example",
      price: "25",
      featured: 1,
      description: "example",
    },
  ],
};

const Menu = ({ restaurant_id, id, session_token }: MenuProps) => {
  const { t } = useTranslation();
  const [Sections, setSections] = useState<Section[]>([]);
  const [isFinished, setIsFinished] = useState(false);
  const [editing, setEditing] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  useEffect(() => {
    if (!isFinished && Sections.length > 0) {
      setIsFinished(true);
    }
    if (isFinished && !canSave) {
      setCanSave(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Sections]);

  const GetMenu = () => {
    fetch(`https://laddition.ma/Website/GetMenu.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        restaurant_id: restaurant_id,
      }),
    })
      .then((res) => res.json())
      .then((response: GetMenuResponse) => {
        if (
          response.success &&
          response.message === "Rows found" &&
          response.Menu
        ) {
          let newSections: Section[] = [];
          const groupedSections = groupBy(
            response.Menu,
            "section"
          ) as unknown as Record<string, SectionItem[]>;
          Object.keys(groupedSections).forEach((name, index) => {
            const items = groupedSections[name];
            newSections.push({
              name: name,
              active: true,
              items: items,
              featuredItem: items.map((item) => item.featured).indexOf(1),
            });
          });

          setSections(newSections);
        } else {
          setSections([{ ...DEAULT_SECTIONS }]);
        }
      });
  };

  useEffect(() => {
    if (!isFinished) GetMenu();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!isFinished) {
    return (
      <Grid item>
        <SectionHeader label="Menu" />
        <CustomText
          type="subheader"
          label="Mettez à jours le menu de restaurant"
        />
        <Placeholder animation="glow">
          <Placeholder xs={7} />
        </Placeholder>
        <Placeholder animation="glow">
          <Placeholder xs={6} /> {""}
          <Placeholder xs={3} />
          <Placeholder xs={10} />
          <Placeholder xs={10} />
          <Placeholder xs={10} />
        </Placeholder>
      </Grid>
    );
  }

  const UploadMenu = () => {
    setUploading(true);
    let section_items: UploadMenuPayload[] = [];
    Sections.forEach((section) => {
      if (section.active) {
        section.items.forEach((item, index) => {
          if (item.name && item.price) {
            section_items.push({
              ...item,
              sectionName: section.name,
              featured: section.featuredItem === index ? 1 : 0,
            });
          }
        });
      }
    });

    fetch(`https://laddition.ma/Website/AddMenu.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        restaurant_id: restaurant_id,
        items: section_items,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setUploadSuccess(true);
          setCanSave(false);
        } else {
          setUploadError(true);
        }
        setUploading(false);
      });
  };

  return (
    <Grid item>
      <Grid item sx={{ marginBottom: 4 }}>
        <SectionHeader label="Menu" />
        <Grid container alignContent="center" alignItems="center">
          <CustomText
            type="subheader"
            label="Mettez à jours le menu de restaurant"
          />
          <IconButton color="primary" onClick={() => setEditing(!editing)}>
            <AppRegistrationIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container rowSpacing={4} sx={{ padding: 3 }}>
        <Grid container md={12} xs={6} item justifyContent={"center"}>
          {Sections.map((value, index) => (
            <MenuSection
              setEditing={setEditing}
              editing={editing}
              sectionName={value.name}
              sectionActive={value.active}
              items={value.items}
              index={index}
              setSections={setSections}
              featuredItem={value.featuredItem}
            />
          ))}
        </Grid>
        <Grid container justifyContent="space-between" sx={{ marginTop: 4 }}>
          <Button
            onClick={() => {
              setSections([
                ...Sections,
                {
                  name: "Section 1",
                  active: true,
                  featuredItem: 0,
                  items: [
                    {
                      name: "example",
                      price: "25",
                      featured: 1,
                      description: "example",
                    },
                  ],
                },
              ]);
              setEditing(true);
            }}
            color="primary"
            variant="contained"
          >
            {t("restaurant.restaurantInfoPage.menu.addSection")}
          </Button>
          <CustomLoadingButton
            loading={uploading}
            disabled={!canSave}
            onSubmit={UploadMenu}
            color="success"
            variant="contained"
            label={t("common.save")}
          />
          <SnackBar
            open={uploadSuccess}
            onClose={() => setUploadSuccess(false)}
            message={t(
              "restaurant.restaurantInfoPage.menu.section.uploadSuccess"
            )}
            severity="success"
          />
          <SnackBar
            open={uploadError}
            onClose={() => setUploadError(false)}
            message={t(
              "restaurant.restaurantInfoPage.menu.section.uploadError"
            )}
            severity="error"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Menu;
