import React, { useContext, useState } from "react";
import {
  Grid,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Popper from "@mui/material/Popper";
import InfoIcon from "@mui/icons-material/Info";
import { CustomText, ElevatedPaper, TextInput } from "src/design";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import HomeHeader from "./Header";
import { useSearchParams } from "react-router-dom";
import bcrypt from "bcryptjs";
import { useNavigate } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useEffect } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import { sha256 } from "js-sha256";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const PasswordComplianceInfo = ({ open, anchorEl }) => (
  <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: 999 }}>
    <ElevatedPaper elevation={10} fullwidth>
      <Grid container direction={"column"} width={"100%"} sx={{ padding: 2 }}>
        <Typography style={{ fontWeight: "bold" }}>
          Your password must follow these rules :
        </Typography>
        <Typography>1. Password must be at least 8 characters long.</Typography>
        <Typography>
          2.Password must contain at least one uppercase letter.
        </Typography>
        <Typography>
          3.Password must contain at least one lowercase letter.
        </Typography>
        <Typography>4. Password must contain at least one digit.</Typography>
        <Typography>
          {
            "5.Password must contain at least one special character (e.g., !@#$%^&*()_+-={}[]|:;`'<>,.?/~)."
          }
        </Typography>
        <Typography>6.Password should not contain any spaces. </Typography>
      </Grid>
    </ElevatedPaper>
  </Popper>
);

const PasswordInput = ({
  password,
  onChange,
  handleMouseDownPassword,
  show,
  setShow,
  label,
  error,
}) => (
  <FormControl fullWidth error={error} size="small">
    <InputLabel htmlFor="outlined-adornment-password-login">{label}</InputLabel>
    <OutlinedInput
      id="outlined-adornment-password-login"
      type={show ? "text" : "password"}
      value={password}
      style={{
        borderRadius: "20px",
        backgroundColor: "#F5F5F5",
      }}
      name="password"
      onChange={(e) => onChange(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => setShow(!show)}
            onMouseDown={handleMouseDownPassword}
            edge="end"
            size="large"
          >
            {show ? <Visibility /> : <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      }
      label="Password"
      inputProps={{}}
    />
  </FormControl>
);

export const PasswordReset = () => {
  const { setUserInfo } = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmatioPassword, setShowConfirmationPassword] =
    useState(false);
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [email, setEmail] = useState("");
  const [token, setToken] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const navigate = useNavigate();

  const open = Boolean(anchorEl);
  const [params] = useSearchParams();

  useEffect(() => {
    setToken(params.get("token"));
  }, []);

  const ChangePassword = async (event) => {
    const passwordError =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}\[\]|\\:;'<>`,.?/~])\S*$/.test(
        password
      );

    const hashedPassword = bcrypt.hashSync(
      password,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    const userHashedPassword = sha256(password);

    if (!passwordError) {
      handleClick(event);
    } else if (password === confirmationPassword) {
      fetch(`https://laddition.ma/Website/ChangePassword.php`, {
        method: "POST",
        body: JSON.stringify({
          token: token,
          password: hashedPassword,
          userPassword: userHashedPassword,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setSuccess(true);
            setTimeout(() => navigate("/login"), 1000);
          } else {
            setSuccess(false);
            console.log(response.error);
          }
        });
    }
  };

  const SendEmail = () => {
    setEmailSent(true);
    fetch(`https://laddition.ma/Website/SendResetEmail.php`, {
      method: "POST",
      body: JSON.stringify({
        email: email,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        setEmailSent(true);
      });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
      <HomeHeader setUserInfo={setUserInfo} />
      <Grid
        container
        direction="column"
        width="100%"
        rowSpacing={5}
        sx={{ padding: 2 }}
        minHeight="90vh"
        justifyContent={"center"}
        alignItems={"center"}
      >
        {token != null ? (
          <>
            <PasswordComplianceInfo open={open} anchorEl={anchorEl} />
            <Grid item>
              <CustomText label="Modifiez votre mot de passe" type="header" />
            </Grid>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <CustomText
                label="Le mot de passe doit être conforme aux normes"
                type="header1"
              />
              <IconButton size="small" onClick={handleClick}>
                <InfoIcon fontSize="small" />
              </IconButton>
            </Grid>
            <Grid
              item
              container
              direction={"column"}
              alignItems={"center"}
              rowSpacing={2}
            >
              <Grid item width={"30%"}>
                <PasswordInput
                  label={"Nouveau Mot De Passe"}
                  password={password}
                  setShow={setShowPassword}
                  onChange={setPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  show={showPassword}
                  error={
                    password != "" &&
                    !/^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-={}\[\]|\\:;'<>`,.?/~])\S*$/.test(
                      password
                    )
                  }
                />
              </Grid>
              <Grid item width={"30%"}>
                <PasswordInput
                  label={"Confirmez Le Mot De Passe"}
                  password={confirmationPassword}
                  setShow={setShowConfirmationPassword}
                  onChange={setConfirmationPassword}
                  handleMouseDownPassword={handleMouseDownPassword}
                  show={showConfirmatioPassword}
                  error={confirmationPassword !== password}
                />
              </Grid>
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                disableElevation
                size="medium"
                variant="contained"
                color="success"
                onClick={ChangePassword}
              >
                Changer Le Mot De Passe
              </LoadingButton>
            </Grid>
          </>
        ) : (
          <>
            <Grid item>
              <CustomText label="Modifiez votre mot de passe" type="header" />
            </Grid>
            <Grid container alignItems={"center"} justifyContent={"center"}>
              <CustomText
                label="Pour réinitialiser votre mot de passe veuillez renseinger votre email"
                type="subheader"
              />
            </Grid>
            <Grid item width={"50%"}>
              <TextInput
                fullwidth
                label="Addresse Email"
                value={email}
                onChange={setEmail}
              />
            </Grid>
            <Grid item>
              <LoadingButton
                type="submit"
                loading={loading}
                disableElevation
                size="medium"
                variant="contained"
                color="success"
                onClick={SendEmail}
              >
                Confirmer
              </LoadingButton>
            </Grid>
          </>
        )}
      </Grid>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(true)}
          severity="success"
          sx={{ width: "100%" }}
        >
          The Password was updated successfully, you will be redirected to sign
          In automatically
        </Alert>
      </Snackbar>
      <Snackbar
        open={emailSent}
        autoHideDuration={6000}
        onClose={() => setEmailSent(false)}
      >
        <Alert
          onClose={() => setEmailSent(true)}
          severity="success"
          sx={{ width: "100%" }}
        >
          If this email is associated to an account an email will be sent to
          your inbox
        </Alert>
      </Snackbar>
      <Snackbar
        open={failure}
        autoHideDuration={6000}
        onClose={() => setFailure(false)}
      >
        <Alert
          onClose={() => setFailure(true)}
          severity="error"
          sx={{ width: "100%" }}
        >
          There was a problem with your request, please try again later or
          contact us directly
        </Alert>
      </Snackbar>
    </>
  );
};
