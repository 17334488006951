import { createContext } from "react";
import type { RestaurantType } from "src/Utils/Data/Restaurant";

const defaultRestaurantContext = {
  isLoading: true,
  restaurants: [],
  refetch: () => {},
}

interface RestaurantContextType {
  isLoading: boolean;
  restaurants: RestaurantType[];
  refetch: () => void;
}

export const RestaurantContext = createContext<RestaurantContextType>(
  defaultRestaurantContext
);
