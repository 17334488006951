import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Grid, Snackbar, Alert } from "@mui/material";
import HomeHeader from "src/components/HomePage/Header";
import { CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const EmailVerificationPage = () => {
  const { token } = useParams();
  const [verified, setVerified] = useState({
    success: false,
    platform: "",
  });
  const [error, setError] = useState({
    success: false,
    platform: "",
  });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [crash, setCrash] = useState(false);

  const VerifyAccount = () => {
    fetch(`https://laddition.ma/Shared/VerifyUser.php`, {
      method: "POST",
      body: JSON.stringify({
        token: token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          if (response.isMobile) {
            setVerified({
              success: true,
              platform: "mobile",
            });
          } else {
            setVerified({
              success: true,
              platform: "desktop",
            });
          }
        } else {
          if (response.isMobile) {
            setError({
              success: true,
              platform: "mobile",
            });
          } else {
            setError({
              success: true,
              platform: "desktop",
            });
          }
        }
      })
      .catch(() => setCrash(true));
  };

  useEffect(() => {
    VerifyAccount();
  }, []);

  return (
    <Grid>
      <HomeHeader isMobile />
      <Grid
        container
        sx={{ width: "100%", minHeight: "92vh" }}
        justifyContent="center"
        alignContent="center"
      >
        <Grid item>
          <CustomText
            label={t("emailVerification.body")}
            type="body"
            size={undefined}
          />
        </Grid>
        <Snackbar
          open={verified.success}
          autoHideDuration={6000}
          onClose={() => {
            setVerified({
              success: false,
              platform: "",
            });

            if (verified.platform !== "mobile") {
              navigate("/login");
            }
          }}
        >
          <Alert
            onClose={() =>
              setVerified({
                success: false,
                platform: "",
              })
            }
            severity="success"
            sx={{ width: "100%" }}
          >
            {t(
              verified.platform === "mobile"
                ? "emailVerification.mobile.success"
                : "emailVerification.website.success"
            )}
          </Alert>
        </Snackbar>
        <Snackbar
          open={error.success}
          autoHideDuration={6000}
          onClose={() => {
            setError({
              success: false,
              platform: "",
            });

            if (verified.platform !== "mobile") {
              navigate("/login");
            }
          }}
        >
          <Alert
            onClose={() => {
              setError({
                success: false,
                platform: "",
              });
            }}
            severity="error"
            sx={{ width: "100%" }}
          >
            {t(
              verified.platform === "mobile"
                ? "emailVerification.mobile.error"
                : "emailVerification.website.error"
            )}
          </Alert>
        </Snackbar>
        <Snackbar
          open={crash}
          autoHideDuration={6000}
          onClose={() => setCrash(false)}
        >
          <Alert
            onClose={() => setCrash(false)}
            severity="error"
            sx={{ width: "100%" }}
          >
            {t("emailVerification.crash")}
          </Alert>
        </Snackbar>
      </Grid>
    </Grid>
  );
};

export default EmailVerificationPage;
