import useGetDeals from "src/data/deals/useGetDeals";
import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Switch,
  Grid,
} from "@mui/material";
import { CustomText, CustomButton } from "src/design";
import { TailSpin } from "react-loader-spinner";
import { useContext } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const DealsTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading: dealsLoading, deals, refetch } = useGetDeals({ limit: 3 });
  const { userInfo } = useContext(UserContext);

  const UpdateStatus = (id: string, mode: number) => {
    fetch(`https://laddition.ma/Website/UpdateDealStatus.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        id: id,
        statut: mode ? 0 : 1,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          refetch();
        }
      })
      .catch((e) => {});
  };

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(248, 248, 248, 1)",
        padding: 2,
        paddingTop: 1,
      }}
      direction={"column"}
      gap={2}
    >
      <Grid item>
        <CustomText
          label={t("restaurant.home.dealsHeader")}
          type="header"
          fontFamily="sans-serif"
          size={16}
        />
      </Grid>
      {deals.length === 0 && !dealsLoading ? (
        <h3 style={{ textAlign: "center" }}>Vous n'avez pas encore de deal</h3>
      ) : (
        <>
          {dealsLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <TailSpin color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <Grid item>
              <Table size="small" aria-label="a deals table">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="center">description</TableCell>
                    <TableCell align="right">Restaurant</TableCell>
                    <TableCell align="right">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deals.map((deal, index) => {
                    return (
                      <TableRow key={index} sx={{ padding: 0 }}>
                        <TableCell padding="none">
                          <Switch
                            checked={deal.status ? true : false}
                            onChange={() =>
                              UpdateStatus(deal.id_offre, Number(deal.status))
                            }
                          />
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {deal.description}
                        </TableCell>
                        <TableCell padding="none" align="right">
                          {deal.restaurantName}
                        </TableCell>
                        <TableCell padding="none" align="right">
                          {deal.is_event ? "Evenement" : "Offre"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Grid>
          )}
        </>
      )}
      <Grid item container justifyContent={"center"}>
        <CustomButton
          onClick={() => {
            navigate(`/restaurant/deals`);
          }}
        >
          {t("restaurant.home.seeAll")}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default DealsTable;
