import { Grid } from "@mui/material";
import { CustomText } from "src/design/CustomText";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";

interface TextKpiProps {
  title: string;
  value: string;
  diffLabel: string;
  progress: number;
  fullHeight?: boolean;
}

const TextKpi = ({
  title,
  value,
  diffLabel,
  progress,
  fullHeight,
}: TextKpiProps) => (
  <Grid
    container
    direction={"column"}
    sx={{
      backgroundColor: "rgba(248, 248, 248, 1)",
      padding: 1,
      paddingLeft: 2,
      paddingBottom: 4,
    }}
    height={fullHeight ? "100%" : "auto"}
    justifyContent="space-between"
  >
    <Grid item>
      <CustomText
        label={title}
        type="subheader"
        component="h3"
        weight={"bold"}
        fontFamily="sans-serif"
        size={14}
      />
    </Grid>
    <Grid item>
      <Grid container width="100%" textAlign="left">
        <Grid item container alignItems="baseline">
          <CustomText
            label={value || "N/A"}
            type="header"
            component="p"
            size={45}
            fontFamily="sans-serif"
          />
          {progress > 0 ? (
            <TrendingUpIcon fontSize="large" color="success" />
          ) : (
            <TrendingDownIcon fontSize="large" color="error" />
          )}
        </Grid>
        <Grid item>
          <CustomText
            label={`${progress > 0 ? "+" : ""} ${progress} ${diffLabel}`}
            type="helper"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

export default TextKpi;
