import { ElevatedPaper } from "src/design/ElevatedPaper";
import { CustomText } from "src/design/CustomText";
import { TrendChip } from "src/design/TrendChip";
import { Grid } from "@mui/material";

interface Props {
  title: string;
  value?: string;
  progress?: number;
  difference?: string;
}

export const TextKpi = ({ title, value, progress, difference }: Props) => {
  return (
    <ElevatedPaper
      elevation={8}
      padding={3}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      outerRadius={0}
      fullHeight
    >
      <Grid width="100%" height="100%" container direction={"column"}>
        <Grid item md={4}>
          <CustomText label={title} type="subheader" component="h3" />
        </Grid>
        <Grid item md={8}>
          <Grid
            container
            width="100%"
            textAlign="center"
            justifyContent="center"
            alignItems="baseline"
          >
            <Grid item>
              <CustomText
                label={value || "N/A"}
                type="header"
                component="p"
                size={45}
                fontFamily="sans-serif"
              />
              {difference && <CustomText label={difference} type="helper" />}
            </Grid>
            {progress && (
              <Grid item>
                <TrendChip progress={progress} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </ElevatedPaper>
  );
};
