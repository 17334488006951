import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { UserInfo } from "src/Utils/Data/User";

const defualtUserContext = {
  userInfo: undefined,
  setUserInfo: (value: UserInfo) => null,
  isLoading: true,
};

interface UserContextType {
  userInfo: UserInfo | undefined;
  setUserInfo: (value: UserInfo) => void;
  isLoading: boolean;
}

export const UserContext = createContext<UserContextType>(defualtUserContext);

const UserProvider = ({ children }: PropsWithChildren<ReactNode>) => {
  const [cookies] = useCookies(["id", "session_token", "admin"]);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      if (!userInfo) {
        if (cookies.id && cookies.session_token) {
          setUserInfo({
            id: cookies.id,
            session_token: cookies.session_token,
            new: false,
            admin: !!Number(cookies.admin),
          });
        }
      }
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value = { userInfo, setUserInfo, isLoading };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
