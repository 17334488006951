import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

export const CustomModal = ({ show, children, setShow }) => (
  <Modal
    open={show}
    onClose={() => setShow(false)}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
        borderRadius: 4,
        padding: 8,
        width: "50%",
      }}
    >
      {children}
    </Box>
  </Modal>
);
