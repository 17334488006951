import { TextKpi } from "src/design";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  visitors?: number;
  progress?: number;
}

const VisitorsKpi = ({ visitors, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item md={6} xs={12}>
      <TextKpi
        title={t("statistics.visitorsLabel")}
        value={visitors?.toString()}
        progress={progress}
      />
    </Grid>
  );
};

export default VisitorsKpi;
