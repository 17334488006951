/* eslint-disable eqeqeq */
import { Booking, Filters } from "src/components/Main/Pages/Bookings/data";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { useTranslation } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { useState } from "react";
import BookingsTableHeader from "src/components/Main/Pages/Bookings/BookingsTableHeader";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import BookingsModal from "src/components/Main/Pages/Bookings/BookingsModal";

interface BookingTableProps {
  bookings: Booking[];
  filters: Filters;
  fetching: boolean;
  onConfirm: (id: string) => void;
  onCancel: (id: string) => void;
}

export interface SelectedFilter {
  restaurantName: string[];
  bookingHour: string[];
  status: string[];
}

const BookingTable = ({
  bookings,
  filters,
  fetching,
  onCancel,
  onConfirm,
}: BookingTableProps) => {
  const { t } = useTranslation();
  const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({
    restaurantName: [],
    bookingHour: [],
    status: [],
  });
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const [selectedBooking, setSelectedBooking] = useState<Booking | null>(null);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [showCancel, setShowCancel] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  return (
    <Paper sx={{ width: "100%", mb: 2, marginTop: 2 }}>
      {selectedBooking && (
        <BookingsModal
          show={showConfirm}
          setShow={setShowConfirm}
          title={t("bookings.modal.confirm.title")}
          disclaimer={t("bookings.modal.confirm.disclaimer")}
          onSubmit={() => {
            onConfirm(selectedBooking.id);
            setSelectedBooking(null);
            setShowConfirm(false);
          }}
        />
      )}
      {selectedBooking && (
        <BookingsModal
          show={showCancel}
          setShow={setShowCancel}
          title={t("bookings.modal.cancel.title")}
          disclaimer={t("bookings.modal.cancel.disclaimer")}
          onSubmit={() => {
            onCancel(selectedBooking.id);
            setSelectedBooking(null);
            setShowCancel(false);
          }}
        />
      )}
      <BookingsTableHeader
        Filters={filters}
        SelectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
      />
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="Bookings table">
          <TableHead>
            <TableRow>
              <TableCell>{t("bookings.table.status")}</TableCell>
              <TableCell align="left">{t("bookings.table.username")}</TableCell>
              <TableCell align="left">{t("bookings.table.phone")}</TableCell>
              <TableCell align="left">
                {t("bookings.table.restaurant")}
              </TableCell>
              <TableCell align="left">{t("bookings.table.slot")}</TableCell>
              <TableCell align="left">{t("bookings.table.deal")}</TableCell>
              <TableCell align="left">
                {t("bookings.table.numberOfPeople")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetching ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "center",
                      marginTop: 20,
                    }}
                  >
                    <TailSpin color="#00BFFF" height={80} width={80} />
                  </div>
                </TableCell>
              </TableRow>
            ) : (
              <>
                {bookings
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .filter((booking) => {
                    return (
                      (selectedFilter.restaurantName.length === 0 ||
                        selectedFilter.restaurantName.includes(
                          booking.restaurantName
                        )) &&
                      (selectedFilter.bookingHour.length === 0 ||
                        selectedFilter.bookingHour.includes(
                          booking.bookingHour
                        )) &&
                      (selectedFilter.status.length === 0 ||
                        selectedFilter.status.includes(booking.status))
                    );
                  })
                  .map((booking) => (
                    <TableRow key={booking.id}>
                      <TableCell component="th" scope="row">
                        <ToggleButtonGroup
                          color={
                            booking.status === "Confirmed"
                              ? "success"
                              : booking.status === "Pending"
                              ? "standard"
                              : "error"
                          }
                          value={booking.status}
                          exclusive
                          size="small"
                          onChange={(_, value) => {
                            if (value === "Confirmed") {
                              setSelectedBooking(booking);
                              setShowConfirm(true);
                            } else if (value === "Cancelled") {
                              setSelectedBooking(booking);
                              setShowCancel(true);
                            }
                          }}
                        >
                          <ToggleButton
                            value="Confirmed"
                            disabled={booking.status === "Cancelled"}
                            sx={{ textTransform: "none" }}
                          >
                            {t("bookings.status.confirmed")}
                          </ToggleButton>
                          <ToggleButton
                            value="Pending"
                            disabled={booking.status != "Pending"}
                            color="primary"
                            sx={{ textTransform: "none" }}
                          >
                            {t("bookings.status.pending")}
                          </ToggleButton>
                          <ToggleButton
                            value="Cancelled"
                            sx={{ textTransform: "none" }}
                          >
                            {t("bookings.status.cancelled")}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      </TableCell>
                      <TableCell align="left">{booking.userName}</TableCell>
                      <TableCell align="left">{booking.phone}</TableCell>
                      <TableCell align="left">
                        {booking.restaurantName}
                      </TableCell>
                      <TableCell align="left">
                        {booking.bookingHour} h
                      </TableCell>
                      <TableCell align="left">{booking.deal}</TableCell>
                      <TableCell align="left">
                        {booking.numberOfPeople}
                      </TableCell>
                    </TableRow>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={bookings.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          sx={{
            alignItems: "center",
            "& p": {
              margin: 0,
            },
          }}
        />
      </TableContainer>
    </Paper>
  );
};

export default BookingTable;
