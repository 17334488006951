import { Box, Grid } from "@mui/material";
import React from "react";
import { InputSelect } from "src/design";
import { DealFilters } from "src/business/deal";
import { SelectedFilters } from "src/components/Main/Pages/Deals/DealsTable";
import { useTranslation } from "react-i18next";

interface Props {
  Filters: DealFilters;
  SelectedFilter: SelectedFilters;
  setSelectedFilter: (value: SelectedFilters) => void;
}

export const TableHeader = ({
  Filters,
  SelectedFilter,
  setSelectedFilter,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Grid container justifyContent="space-between" alignItems={"center"}>
        <Grid item xs={12} md={10}>
          <Grid
            container
            width={"100%"}
            justifyContent="flex-start"
            spacing={3}
            sx={{ paddingLeft: { xs: 3, md: 0 } }}
          >
            <Grid item md={3} sm={5} xs={10}>
              <InputSelect
                label={t("deals.table.status")}
                value={SelectedFilter.status}
                options={Filters.status}
                onChange={(e: React.ChangeEvent<{ value: string }>) => {
                  setSelectedFilter({
                    ...SelectedFilter,
                    status: e.target.value,
                  });
                }}
                size="small"
                variant="standard"
              />
            </Grid>
            {Filters.restaurantName.length > 0 && (
              <Grid item md={3} sm={5} xs={10}>
                <InputSelect
                  multiple
                  label={t("deals.table.restaurant")}
                  value={SelectedFilter.restaurantName}
                  options={Filters.restaurantName}
                  onChange={(e: React.ChangeEvent<{ value: string[] }>) => {
                    setSelectedFilter({
                      ...SelectedFilter,
                      restaurantName: e.target.value,
                    });
                  }}
                  size="small"
                  variant="standard"
                />
              </Grid>
            )}
            <Grid item md={3} sm={5} xs={10}>
              <InputSelect
                multiple
                label={t("deals.table.type")}
                value={SelectedFilter.type}
                options={Filters.type}
                onChange={(e: React.ChangeEvent<{ value: string[] }>) => {
                  setSelectedFilter({
                    ...SelectedFilter,
                    type: e.target.value,
                  });
                }}
                size="small"
                variant="standard"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
