import {
  PieChart as RechartsPie,
  ResponsiveContainer,
  Pie,
  Cell,
} from "recharts";
import { chartsPalette } from "src/design/ColorPalette";
import { CustomText } from "src/design/CustomText";
import { Grid, Tooltip as MuiTooltip, IconButton } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { Box } from "@mui/system";
import NoDataFound from "src/assets/no-data-found.png";

interface Props {
  data: {
    name: string;
    value: number;
  }[];
  title?: string;
  tooltip?: string;
  palette?: string[];
}

interface CustomLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: CustomLabelProps) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export const PieChart = ({ data, title, tooltip, palette }: Props) => {
  const colorPalette = palette || chartsPalette[`${data.length}`];

  return (
    <Grid container width={"100%"} height={"100%"}>
      <Grid item textAlign={"left"} marginLeft={5} width={"100%"}>
        {title && (
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid item>
              <CustomText component={"h3"} type="subheader" label={title} />
            </Grid>
            {tooltip && (
              <MuiTooltip title={tooltip} sx={{ padding: 0, marginLeft: 0.5 }}>
                <IconButton>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
            )}
          </Grid>
        )}
      </Grid>
      {data.length === 0 ? (
        <Grid container justifyContent={"center"}>
          <img src={NoDataFound} alt="No data found" />
        </Grid>
      ) : (
        <ResponsiveContainer width={"100%"} height={350}>
          <RechartsPie>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              labelLine={false}
              label={renderCustomizedLabel}
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={colorPalette[index % colorPalette.length]}
                />
              ))}
            </Pie>
          </RechartsPie>
        </ResponsiveContainer>
      )}
      <Grid container justifyContent={"center"} spacing={1}>
        {data.map((value, index) => (
          <Grid key={index} item>
            <Grid
              container
              direction={"row"}
              alignItems={"center"}
              spacing={0.3}
            >
              <Grid item>
                <Box
                  width={10}
                  height={10}
                  bgcolor={colorPalette[index]}
                  borderRadius={5}
                />
              </Grid>
              <Grid item>
                <CustomText component={"p"} type="helper" label={value.name} />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
