import { useContext, useState } from "react";
import { Autocomplete, Grid, TextField, Box } from "@mui/material";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { CustomText } from "src/design";
import dayjs, { Dayjs } from "dayjs";
import { useTranslation } from "react-i18next";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DateRangePopover from "src/components/Main/Pages/UserDashboard/components/DateRangePopover";

const optionIcon = (label: string) => (
  <Box
    sx={{
      borderRadius: 16,
      backgroundColor: "rgba(0, 0, 0, 0.13)",

      fontSize: 12,
      padding: 0.7,
    }}
  >
    {label}
  </Box>
);

export const dateRangePickeOptions = [
  {
    starteIcon: optionIcon("1 day"),
    title: "Past day",
    value: {
      startDay: dayjs().subtract(1, "day"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: optionIcon("2 days"),
    title: "Pas 2 days",
    value: {
      startDay: dayjs().subtract(2, "day"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: optionIcon("1 week"),
    title: "Past week",
    value: {
      startDay: dayjs().subtract(1, "week"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: optionIcon("2 week"),
    title: "Past 2 week",
    value: {
      startDay: dayjs().subtract(2, "week"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: optionIcon("1 month"),
    title: "Past month",
    value: {
      startDay: dayjs().subtract(1, "month"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: optionIcon("1 year"),
    title: "Past year",
    value: {
      startDay: dayjs().subtract(1, "year"),
      endDay: dayjs(),
    },
  },
  {
    starteIcon: <CalendarMonthIcon />,
    title: "Select a specific date",
    value: null,
  },
];

interface UserDashboardHeaderProps {
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  startDate: string;
  endDate: string;
  today: Dayjs;
  setRestaurant: (restaurant: string | undefined) => void;
}

const UserDashboardHeader = ({
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  today,
  setRestaurant,
}: UserDashboardHeaderProps) => {
  const [nameFilter, setNameFilter] = useState<string>("");
  const { restaurants } = useContext(RestaurantContext);
  const { t } = useTranslation();

  const onRangeSelected = (start: Dayjs, end: Dayjs) => {
    setStartDate(start.format("YYYY-MM-DD").toString());
    setEndDate(end.format("YYYY-MM-DD").toString());
  };

  return (
    <>
      <CustomText
        label={t("statistics.header.title")}
        type="header"
        component="h1"
      />
      <Grid
        item
        xs={12}
        container
        style={{ marginBottom: 30, marginTop: 10 }}
        justifyContent={"flex-start"}
      >
        <Grid item md={3} xs={8}>
          <Autocomplete
            freeSolo
            options={restaurants.map((res) => res.Nom)}
            onChange={(event, newValue) => {
              const restaurant = restaurants.find(
                (res) => res.Nom === newValue
              );
              setRestaurant(restaurant?.restaurant_id);
            }}
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  endAdornment: null,
                }}
                style={{ borderRadius: 16 }}
                InputLabelProps={{ style: { color: "#9fa6bc" } }}
                label="Selecte a restaurant"
                variant="outlined"
                value={nameFilter}
                onChange={(value) => {
                  setNameFilter(value.target.value);
                }}
                size="medium"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <DateRangePopover
            startDate={startDate}
            endDate={endDate}
            options={dateRangePickeOptions}
            today={today}
            onRangeSelected={onRangeSelected}
            onSelectOption={(start, end) => {
              setStartDate(start);
              setEndDate(end);
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default UserDashboardHeader;
