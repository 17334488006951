import { Grid, Switch, capitalize } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { DEFAULT_HOURS } from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/constants";
import DayHoursPicker from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/DayHoursPicker";
import { useTranslation } from "react-i18next";
import { CustomText, SectionHeader } from "src/design";
import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { CustomLoadingButton, SnackBar } from "src/design";
import HoursPickerModal from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/HoursPickerModal";
import LeaveModal from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/LeavesModal";
import { styled } from "@mui/material/styles";
import { lightBlue, darkBlue } from "src/design/ColorPalette";
import LoadingButton from "@mui/lab/LoadingButton";
import { Stack } from "@mui/system";
import { UserContext } from "src/Utils/Context/UserContext";
import useUpdateRestaurantBookingDuration from "src/data/restaurant/useUpdateRestaurantBookingDuration";

const ColorLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.getContrastText(lightBlue),
  backgroundColor: lightBlue,
  "&:hover": {
    backgroundColor: darkBlue,
  },
}));

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

export interface DayHours {
  morning: {
    open: Dayjs | string;
    close: Dayjs | string;
  };
  afternoon: {
    open: Dayjs | string;
    close: Dayjs | string;
  };
}

const HoursPicker = ({
  Horaires,
  id,
  session_token,
  restaurant_id,
  booking_duration,
}: {
  Horaires: string;
  id: string;
  session_token: string;
  restaurant_id: string;
  booking_duration: number;
}) => {
  const { t } = useTranslation();
  const { userInfo } = useContext(UserContext);

  const { updateRestaurantBookingDuration } =
    useUpdateRestaurantBookingDuration({
      id: userInfo?.id,
      session_token: userInfo?.session_token,
    });

  const [hours, setHours] = useState<Record<string, DayHours>>({
    ...DEFAULT_HOURS,
  });
  const [hasChanged, setHasChanged] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const UploadHours = () => {
    setUploading(true);
    let stringifiedHours = "";
    Object.keys(hours).forEach((day, index) => {
      const { morning, afternoon } = hours[day];
      if (morning.open && morning.close) {
        if (
          typeof morning.open !== "string" &&
          typeof morning.close !== "string"
        ) {
          stringifiedHours += `${capitalize(day)}=${morning.open.format(
            "HH:mm"
          )}-${morning.close.format("HH:mm")}`;
        }
        if (afternoon.open && afternoon.close) {
          if (
            typeof afternoon.open !== "string" &&
            typeof afternoon.close !== "string"
          ) {
            stringifiedHours += `/${afternoon.open.format(
              "HH:mm"
            )}-${afternoon.close.format("HH:mm")}`;
          }
        }
        if (index < Object.keys(hours).length - 1) {
          stringifiedHours += ";";
        }
      }
    });

    fetch(`https://laddition.ma/Website/UpdateHours.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        restaurant_id: restaurant_id,
        hours: stringifiedHours,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setHasChanged(false);
          setUploadSuccess(true);
        } else {
          setUploadError(true);
        }
        setUploading(false);
      });
  };

  const GetHours = () => {
    if (!Horaires) return;
    const days = Horaires.split(";");
    if (days.length > 0) {
      let hoursObj: Record<string, DayHours> = {};
      days.forEach((day) => {
        if (day !== "") {
          const [dayName, hours] = day.split("=");
          const dayHours = hours.split("/");
          const morning = dayHours[0].split("-");
          let afternoon;
          if (dayHours.length > 1) {
            afternoon = dayHours[1].split("-");
          }
          hoursObj[dayName.toLowerCase()] = {
            morning: {
              open: dayjs(`2021-01-01T${morning[0]}`),
              close: dayjs(`2021-01-01T${morning[1]}`),
            },
            afternoon: afternoon
              ? {
                  open: dayjs(`2021-01-01T${afternoon[0]}`),
                  close: dayjs(`2021-01-01T${afternoon[1]}`),
                }
              : {
                  open: "",
                  close: "",
                },
          };
        }
      });
      setHours({ ...DEFAULT_HOURS, ...hoursObj });
    } else {
      setHours({ ...DEFAULT_HOURS });
    }
  };

  useEffect(() => {
    GetHours();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LeaveModal
        open={open}
        onClose={() => setOpen(false)}
        restaurantId={restaurant_id}
      />
      <HoursPickerModal
        show={show}
        setShow={setShow}
        onSave={(selectedHours) => {
          // apply to all days of the object hours
          const newHours = { ...hours };
          Object.keys(newHours).forEach((day) => {
            newHours[day] = selectedHours;
          });
          setHours(newHours);
          setHasChanged(true);
        }}
      />
      <Grid item sx={{ marginBottom: 5 }}>
        <SectionHeader
          label={t("restaurant.restaurantInfoPage.hoursPicker.header")}
        />
        <Grid container justifyContent={"space-between"}>
          <CustomText
            type="subheader"
            label={t("restaurant.restaurantInfoPage.hoursPicker.subheader")}
          />
          <Grid
            container
            justifyContent={"center"}
            md={1.5}
            textAlign={"center"}
          >
            <Stack direction="row" spacing={1} alignItems="center">
              <CustomText type="helper" label="30 min" />
              <AntSwitch
                defaultChecked={booking_duration === 60}
                onChange={(value) => {
                  updateRestaurantBookingDuration(
                    restaurant_id,
                    value.target.checked ? 60 : 30
                  );
                }}
                inputProps={{ "aria-label": "ant design" }}
              />
              <CustomText type="helper" label="1 h" />
            </Stack>
            <CustomText
              type="helper"
              label={t("restaurant.restaurantInfoPage.hoursPicker.switch")}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        sx={{ marginBottom: 5 }}
        width="100%"
        justifyContent={"space-around"}
        container
      >
        <Grid item xs={6} md={2.5}>
          <ColorLoadingButton
            variant="outlined"
            disabled={false}
            loading={false}
            onClick={() => setShow(true)}
            color="info"
            fullWidth
          >
            {t("restaurant.restaurantInfoPage.hoursPicker.applyToAll")}
          </ColorLoadingButton>
        </Grid>
        <Grid item xs={6} md={3}>
          <CustomLoadingButton
            variant="outlined"
            disabled={false}
            loading={false}
            onSubmit={() => setOpen(true)}
            color="error"
            label={t("restaurant.restaurantInfoPage.leaveDays.addLeaveDay")}
          />
        </Grid>
        <Grid item xs={6} md={2.5}>
          <CustomLoadingButton
            disabled={!hasChanged}
            loading={uploading}
            onSubmit={UploadHours}
            color="success"
            label={t("common.save")}
          />
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"space-evenly"}
        columnGap={1}
        rowGap={4}
        width={"100%"}
      >
        {Object.keys(hours).map((day, index) => (
          <DayHoursPicker
            key={day}
            day={t(`common.days.${day}`)}
            hours={hours[day]}
            setHours={(newHours) => {
              if (!hasChanged) setHasChanged(true);
              setHours({ ...hours, [day]: newHours });
            }}
            index={index}
          />
        ))}
      </Grid>
      <SnackBar
        open={uploadSuccess}
        onClose={() => setUploadSuccess(false)}
        severity="success"
        message={t("restaurant.restaurantInfoPage.hoursPicker.uploadSuccess")}
      />
      <SnackBar
        open={uploadError}
        onClose={() => setUploadError(false)}
        severity="error"
        message={t("restaurant.restaurantInfoPage.hoursPicker.uploadError")}
      />
    </>
  );
};

export default HoursPicker;
