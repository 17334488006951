import { Grid, Tooltip as MuiTooltip, IconButton } from "@mui/material";
import {
  AreaChart as RechartsArea,
  Tooltip,
  XAxis,
  Area,
  ResponsiveContainer,
} from "recharts";
import { CustomText } from "src/design/CustomText";
import { TrendChip } from "src/design/TrendChip";
import { chartsPalette } from "src/design/ColorPalette";
import InfoIcon from "@mui/icons-material/Info";
import NoDataFound from "src/assets/no-data-found.png";

interface Props {
  data: Record<string, string | number>[];
  title?: string;
  value?: string;
  progression?: number;
  palette?: string[];
  dataKeys: string[];
  tooltip?: string;
  compact?: boolean;
}

export const AreaChart = ({
  data,
  title,
  value,
  progression,
  palette,
  dataKeys,
  tooltip,
  compact,
}: Props) => {
  const colorPalette = palette || chartsPalette[`${dataKeys.length}`];

  return (
    <Grid container width={"100%"} height={"100%"}>
      <Grid item textAlign={"left"} marginLeft={5} width={"100%"}>
        {title && (
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid item>
              <CustomText component={"h3"} type="subheader" label={title} />
            </Grid>
            {tooltip && (
              <MuiTooltip title={tooltip} sx={{ padding: 0, marginLeft: 0.5 }}>
                <IconButton>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
            )}
          </Grid>
        )}
        {value && data.length > 0 && (
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={1}
            alignItems={"center"}
          >
            <Grid item>
              <CustomText
                component={"h4"}
                type="section"
                label={
                  Number(value) > 100000 ? `${Number(value) / 100}k` : value
                }
                fontFamily="sans-serif"
              />
            </Grid>
            {progression && (
              <Grid item>
                <TrendChip progress={progression} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {data.length === 0 ? (
        <Grid container justifyContent={"center"}>
          <img src={NoDataFound} alt="No data found" />
        </Grid>
      ) : (
        <ResponsiveContainer width={"100%"} height={compact ? 200 : 350}>
          <RechartsArea width={1000} height={350} data={data}>
            <defs>
              {dataKeys.map((key, index) => (
                <linearGradient
                  key={index}
                  id={key + "-" + index}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={colorPalette[index]}
                    stopOpacity={0.8}
                  />
                  <stop
                    offset="95%"
                    stopColor={colorPalette[index]}
                    stopOpacity={0}
                  />
                </linearGradient>
              ))}
            </defs>
            <XAxis dataKey={"name"} />
            <Tooltip />
            {dataKeys.map((key, index) => (
              <Area
                key={index}
                type="monotone"
                dataKey={key}
                stroke={colorPalette[index]}
                fillOpacity={1}
                fill={`url(#${key + "-" + index})`}
              />
            ))}
          </RechartsArea>
        </ResponsiveContainer>
      )}
    </Grid>
  );
};
