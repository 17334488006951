import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import * as React from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import MuiAppBar from "@mui/material/AppBar";
import { styled, alpha } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { emerald, lightBlue } from "src/design/ColorPalette";

const AppBar = styled(
  MuiAppBar,
  {}
)(({ theme }) => ({
  backgroundColor: lightBlue,
  color: "white",
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    width: "100%",
    backgroundColor: lightBlue,
    color: "white",
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: "white",
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const HeaderButton = ({ label, onClick }) => (
  <Button
    onClick={onClick}
    sx={{
      my: 2,
      color: "white",
      display: "block",
      ":hover": {
        color: emerald,
      },
    }}
  >
    {label}
  </Button>
);

function HomeHeader({ isMobile }) {
  const { t } = useTranslation();
  const ref = React.useRef(null);
  const [cookies, setCookie, removeCookie] = useCookies([
    "id",
    "session_token",
  ]);
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const navigate = useNavigate();

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(ref.current);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const SignIn = () => {
    removeCookie("id", { path: "/" });
    navigate(`/login`);
  };

  const SignUp = () => {
    navigate("/Home/SignUp");
  };

  return (
    <AppBar position="sticky" ref={ref}>
      <Container maxWidth={null}>
        <Toolbar disableGutters>
          <IconButton
            onClick={() => {
              navigate("/Home/About");
            }}
            sx={{ display: { xs: "none", md: "flex" }, mr: 1 }}
          >
            <img
              src={require("src/assets/RestaurantIcons/laddition_icon_with_text.png")}
              alt="laaddition logo"
              height={45}
            />
          </IconButton>
          {!isMobile && (
            <>
              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                >
                  <MenuIcon />
                </IconButton>
                <StyledMenu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem onClick={handleCloseNavMenu}>
                    <Typography textAlign="center">{t("About")}</Typography>
                  </MenuItem>
                  <Divider style={{ backgroundColor: "grey", height: "2px" }} />
                  <MenuItem onClick={SignIn}>
                    <Typography textAlign="center">
                      {t("signIn.cta")}
                    </Typography>
                  </MenuItem>
                  <Divider style={{ backgroundColor: "grey", height: "2px" }} />
                  <MenuItem onClick={SignUp}>
                    <Typography textAlign="center">
                      {t("signUp.cta")}
                    </Typography>
                  </MenuItem>
                </StyledMenu>
              </Box>
              <Grid sx={{ display: { xs: "flex", md: "none" }, mr: 1 }}>
                <img
                  src={require("src/assets/RestaurantIcons/laddition_icon_with_text.png")}
                  alt=""
                  height={45}
                />
              </Grid>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "flex-end",
                }}
              >
                <HeaderButton
                  label={t("About")}
                  onClick={() => navigate("About")}
                />
                <HeaderButton label={t("signIn.cta")} onClick={SignIn} />
                <HeaderButton label={t("signUp.cta")} onClick={SignUp} />
              </Box>
            </>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default HomeHeader;
