import { useEffect, useState } from "react";
import { Autocomplete, Chip, Grid, TextField } from "@mui/material";
import { CustomText, SnackBar, CustomLoadingButton } from "src/design";
import { useParams } from "react-router-dom";
import { UserContext } from "src/Utils/Context/UserContext";
import { useContext } from "react";
import { useDebounce } from "src/Utils/ObjectTreatment";

interface Owner {
  id: string;
  label: string;
  type: "owner" | "newAccount";
}

const LinkRestaurant = () => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [selectedOwner, setSelectedOwner] = useState<Owner | null>(null);
  const [ownerSearch, setOwnerSearch] = useState("");
  const [ownerList, setOwnerList] = useState<Owner[]>([]);

  const { userInfo } = useContext(UserContext);
  const { restaurantId } = useParams();
  const debouncedOwnerSearch = useDebounce(ownerSearch, 500);

  const [linkSuccess, setLinkSuccess] = useState(false);
  const [linkFailure, setLinkFailure] = useState(false);

  const LinkRestaurantToOwner = () => {
    if (!selectedOwner) return;
    fetch(`https://laddition.ma/Website/LinkRestaurantToOwner.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        restaurantId: restaurantId,
        selectedOwner: selectedOwner.id,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setLinkSuccess(true);
        } else {
          setLinkFailure(true);
        }
        setLoading(false);
        setDisabled(true);
      });
  };

  const SearchOwner = () => {
    fetch(`https://laddition.ma/Website/SearchOwner.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        selectedOwner: debouncedOwnerSearch,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setOwnerList(data.ownerList);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    if (debouncedOwnerSearch) {
      SearchOwner();
    }
  }, [debouncedOwnerSearch]);

  if (!restaurantId)
    return <CustomText label="Select a restaurant to proceed" type="section" />;

  return (
    <Grid width={"100%"}>
      <CustomText label="Link a restaurant to an owner" type="header" />
      <Grid container justifyContent={"space-between"}>
        <Grid item xs={6}>
          <Autocomplete
            freeSolo
            options={ownerList}
            onChange={(event, newValue) => {
              setDisabled(false);
              setSelectedOwner(newValue as unknown as Owner);
            }}
            handleHomeEndKeys
            renderOption={(props, option) => (
              <li {...props}>
                <Grid width={"100%"} justifyContent={"space-between"} container>
                  <Grid item>{option.label}</Grid>
                  {option.type === "newAccount" && (
                    <Grid item>
                      <Chip size="small" label="new" />
                    </Grid>
                  )}
                </Grid>
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: 16 },
                  endAdornment: null,
                }}
                style={{ borderRadius: 16 }}
                InputLabelProps={{ style: { color: "#9fa6bc" } }}
                label="Selecte a restaurant"
                variant="outlined"
                value={ownerSearch}
                onChange={(value) => {
                  setOwnerSearch(value.target.value);
                }}
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <CustomLoadingButton
            label="Link Restaurant"
            disabled={disabled}
            loading={loading}
            onSubmit={() => {
              LinkRestaurantToOwner();
            }}
            variant="contained"
          />
        </Grid>
      </Grid>
      <SnackBar
        open={linkSuccess}
        onClose={() => setLinkSuccess(false)}
        message="Restaurant linked successfully"
        severity="success"
      />
      <SnackBar
        open={linkFailure}
        onClose={() => setLinkFailure(false)}
        message="Failed to link restaurant"
        severity="error"
      />
    </Grid>
  );
};

export default LinkRestaurant;
