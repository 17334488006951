import React from "react";
import { useTheme, styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useMediaQuery, Typography } from "@mui/material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useCookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { transparentEmerald } from "src/design/ColorPalette";
import { useTranslation } from "react-i18next";

const drawerWidth = 240;

const IconTheme = createTheme({
  palette: {
    primary: { main: "#9fa6bc" },
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  backgroundColor: "#222834",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  backgroundColor: "#222834",
  overflowX: "hidden",
  width: `calc(${theme.spacing(8)} + 1px)`,
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideBar({
  leftDrawerOpened,
  setleftDrawerOpened,
  setUserInfo,
  admin,
}) {
  const { t } = useTranslation();
  const [cookies, setCookie, removeCookie] = useCookies([
    "id",
    "session_token",
  ]);
  let navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Drawer
      anchor="left"
      open={leftDrawerOpened}
      variant="permanent"
      onClose={() => setleftDrawerOpened(false)}
    >
      <ThemeProvider theme={IconTheme}>
        <DrawerHeader>
          <IconButton
            onClick={() => setleftDrawerOpened(false)}
            sx={{
              ":hover": {
                backgroundColor: "#31374a",
              },
            }}
          >
            {theme.direction === "rtl" ? (
              <ChevronRightIcon color="primary" />
            ) : (
              <ChevronLeftIcon color="primary" />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List style={{ color: "#9fa6bc", fontSize: 10 }}>
          {!admin && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  if (matchDownSM) {
                    setleftDrawerOpened(false);
                  }
                  navigate(`/restaurant/home`);
                }}
                style={{
                  backgroundColor: location.pathname.includes("home")
                    ? transparentEmerald
                    : "",
                }}
                sx={{
                  ":hover": {
                    backgroundColor: "#31374a",
                  },
                }}
              >
                <ListItemIcon>
                  <DashboardIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={t("sideBar.mydashboard")}
                  primaryTypographyProps={{ style: { fontSize: 13 } }}
                />
              </ListItemButton>
            </ListItem>
          )}
          <ListItem disablePadding>
            <ListItemButton
              onClick={() => {
                if (matchDownSM) {
                  setleftDrawerOpened(false);
                }
                navigate(`/restaurant/restaurant-list`);
              }}
              style={{
                backgroundColor: location.pathname.includes("restaurant-list")
                  ? transparentEmerald
                  : "",
              }}
              sx={{
                ":hover": {
                  backgroundColor: "#31374a",
                },
              }}
            >
              <ListItemIcon>
                <MenuBookIcon color="primary" fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={t("sideBar.myRestaurants")}
                primaryTypographyProps={{ style: { fontSize: 13 } }}
              />
            </ListItemButton>
          </ListItem>
          {!admin && (
            <>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (matchDownSM) {
                      setleftDrawerOpened(false);
                    }
                    navigate(`/restaurant/bookings`);
                  }}
                  style={{
                    backgroundColor: location.pathname.includes("bookings")
                      ? transparentEmerald
                      : "",
                  }}
                  sx={{
                    ":hover": {
                      backgroundColor: "#31374a",
                    },
                  }}
                >
                  <ListItemIcon>
                    <ReceiptLongIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("sideBar.bookings")}
                    primaryTypographyProps={{ style: { fontSize: 13 } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (matchDownSM) {
                      setleftDrawerOpened(false);
                    }
                    navigate(`/restaurant/deals`);
                  }}
                  style={{
                    backgroundColor: location.pathname.includes("deals")
                      ? transparentEmerald
                      : "",
                  }}
                  sx={{
                    ":hover": {
                      backgroundColor: "#31374a",
                    },
                  }}
                >
                  <ListItemIcon>
                    <LocalOfferIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("sideBar.deals")}
                    primaryTypographyProps={{ style: { fontSize: 13 } }}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    if (matchDownSM) {
                      setleftDrawerOpened(false);
                    }
                    navigate(`/restaurant/statistics`);
                  }}
                  style={{
                    backgroundColor: location.pathname.includes("statistics")
                      ? transparentEmerald
                      : "",
                  }}
                  sx={{
                    ":hover": {
                      backgroundColor: "#31374a",
                    },
                  }}
                >
                  <ListItemIcon>
                    <EqualizerIcon color="primary" fontSize="small" />
                  </ListItemIcon>
                  <ListItemText
                    primary={t("sideBar.statistics")}
                    primaryTypographyProps={{ style: { fontSize: 13 } }}
                  />
                </ListItemButton>
              </ListItem>
            </>
          )}
          {admin && (
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  if (matchDownSM) {
                    setleftDrawerOpened(false);
                  }
                  navigate(`/restaurant/admin-dashboard`);
                }}
                style={{
                  backgroundColor: location.pathname.includes("admin-dashboard")
                    ? transparentEmerald
                    : "",
                }}
                sx={{
                  ":hover": {
                    backgroundColor: "#31374a",
                  },
                }}
              >
                <ListItemIcon>
                  <EqualizerIcon color="primary" fontSize="small" />
                </ListItemIcon>
                <ListItemText
                  primary={"Admin Dashboard"}
                  primaryTypographyProps={{ style: { fontSize: 13 } }}
                />
              </ListItemButton>
            </ListItem>
          )}
          <Divider style={{ backgroundColor: "grey" }} />
          <ListItemButton
            onClick={() => {
              setUserInfo(undefined);
              removeCookie("id", { path: "/" });
              navigate(`/login`);
            }}
            sx={{
              ":hover": {
                backgroundColor: "#31374a",
              },
            }}
          >
            <ListItemIcon>
              <LogoutIcon color="primary" fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography style={{ fontSize: 13 }} variant="body2">
                  {t("sideBar.logout")}
                </Typography>
              }
            />
          </ListItemButton>
        </List>
      </ThemeProvider>
    </Drawer>
  );
}
