import { useState } from "react";

interface Props {
  id?: string;
  session_token?: string;
}

const useUpdateRestaurantBookingDuration = ({ id, session_token }: Props) => {
  const [updating, setUpdating] = useState(false);

  const updateRestaurantBookingDuration = async (
    restaurantId: string,
    duration: number
  ) => {
    if (!id || !session_token) return;

    setUpdating(true);
    const response = await fetch(
      `https://laddition.ma/Website/UpdateBookingDuration.php`,
      {
        method: "POST",
        body: JSON.stringify({
          ownerId: id,
          ownerToken: session_token,
          restaurant_id: restaurantId,
          duration,
        }),
      }
    );
    const data = await response.json();
    setUpdating(false);
    return data;
  };

  return { updateRestaurantBookingDuration, updating };
};

export default useUpdateRestaurantBookingDuration;
