import AnimateButton from "src/components/Main/MainLayout/ui-component/extended/AnimateButton";
import LoadingButton from "@mui/lab/LoadingButton";
import { CSSProperties } from "react";

export interface LoadingButtonProps {
  loading: boolean;
  onSubmit: () => void;
  label: string;
  type?: "submit" | "button";
  disabled?: boolean;
  style?: CSSProperties;
  color?: "success" | "error" | "info" | "warning";
  variant?: "contained" | "outlined" | "text";
  size?: "small" | "medium" | "large";
  fullwidth?: boolean;
}

export const CustomLoadingButton = ({
  loading,
  onSubmit,
  label,
  type = "button",
  disabled = false,
  style = {},
  color = "success",
  variant = "contained",
  size = "medium",
  fullwidth = true,
}: LoadingButtonProps) => (
  <AnimateButton>
    <LoadingButton
      type={type}
      loading={loading}
      disabled={disabled}
      disableElevation
      fullWidth={fullwidth}
      size={size}
      variant={variant}
      color={color}
      onClick={onSubmit}
      style={style}
    >
      {label}
    </LoadingButton>
  </AnimateButton>
);
