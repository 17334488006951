import { groupedBookingsByStatusType } from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { useTranslation } from "react-i18next";
import { BarChart } from "src/design/BarChart";
import { ElevatedPaper } from "src/design";

const BookingsByStatusKpi = ({
  events,
  dateArray,
}: {
  events?: groupedBookingsByStatusType;
  dateArray: string[];
}) => {
  const { t } = useTranslation();

  if (!events) return null;

  const data =
    Object.keys(events).length > 0
      ? dateArray.map((date) => {
          if (events[date]) {
            return {
              name: date,
              pending: events[date].pending,
              confirmed: events[date].confirmed,
              rejected: events[date].rejected,
            };
          } else {
            return {
              name: date,
              pending: 0,
              confirmed: 0,
              rejected: 0,
            };
          }
        })
      : [];

  return (
    <ElevatedPaper
      elevation={8}
      padding={0}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      outerRadius={0}
      textAlign="center"
    >
      <BarChart
        data={data}
        dataKeys={["pending", "confirmed", "rejected"]}
        title={t("statistics.bookingsByStatus.title")}
        tooltip={t("statistics.bookingsByStatus.tooltip")}
      />
    </ElevatedPaper>
  );
};

export default BookingsByStatusKpi;
