export const DEFAULT_HOURS = {
    monday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    tuesday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    wednesday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    thursday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    friday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    saturday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
    sunday: {
      morning: {
        open: "",
        close: "",
      },
      afternoon: {
        open: "",
        close: "",
      },
    },
  }
