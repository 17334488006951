import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs-react";
import AnimateButton from "../Main/MainLayout/ui-component/extended/AnimateButton";
import { useCookies } from "react-cookie";
import {
  Grid,
  Stack,
  Typography,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import LoadingButton from "@mui/lab/LoadingButton";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { UserContext } from "src/Utils/Context/UserContext";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import { darkBlue, lightBlue } from "src/design/ColorPalette";

const ColorLoadingButton = styled(LoadingButton)(({ theme }) => ({
  color: theme.palette.getContrastText(lightBlue),
  backgroundColor: lightBlue,
  "&:hover": {
    backgroundColor: darkBlue,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function SignIn() {
  const { t } = useTranslation();
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const {
    setUserInfo,
    userInfo,
    isLoading: userInfoLoading,
  } = useContext(UserContext);
  const [loading, setloading] = useState(false);
  const [cookies, setCookie] = useCookies(["id", "session_token", "admin"]);
  const [checked, setChecked] = useState(true);
  let navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [credentials, setcredentials] = useState({
    email: "",
    password: "",
  });
  const [error, setError] = useState("");

  useEffect(() => {
    if (!userInfoLoading && userInfo) {
      navigate(`/restaurant/home`, { replace: true });
    }
  }, [navigate, userInfo, userInfoLoading]);

  const handleCloseError = () => {
    setError("");
  };

  const updateEmail = (text) => {
    setcredentials((oldcredentials) => {
      return { ...oldcredentials, email: text };
    });
  };

  const updatePwd = (text) => {
    setcredentials((oldcredentials) => {
      return { ...oldcredentials, password: text };
    });
  };

  const ResetPassword = () => {
    navigate("/reset-password");
  };

  const Authenticate = () => {
    setloading(true);
    const hashedPassword = bcrypt.hashSync(
      credentials.password,
      process.env.REACT_APP_ENCRYPTION_KEY
    );
    fetch(`https://laddition.ma/Website/Owner_Authentification.php`, {
      method: "POST",
      body: JSON.stringify({
        email: credentials.email,
        password: hashedPassword,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          if (checked) {
            setCookie("id", response.owner_id, { path: "/", maxAge: 3600 });
            setCookie("session_token", response.session_token, {
              path: "/",
              maxAge: 3600,
            });
            setCookie("admin", response.admin, { path: "/", maxAge: 3600 });
          }
          setUserInfo({
            id: response.owner_id,
            session_token: response.session_token,
            new: false,
            admin: !!response.admin,
          });
          const rootPath = "/restaurant/home";
          navigate(rootPath, { replace: true });
        } else {
          setError(response.error);
          setloading(false);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="flex-end"
      sx={{ minHeight: "100vh" }}
    >
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: "calc(100vh - 68px)" }}
        >
          <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <Grid
              container
              spacing={2}
              alignItems="center"
              justifyContent="center"
              style={{
                border: "solid 2px darkgrey",
                borderRadius: "24px",
                maxWidth: "450px",
                marginRight: "auto",
                marginLeft: "auto",
                padding: "30px",
              }}
            >
              <Grid item sx={{ mb: 3 }}>
                <img
                  src={require("../../assets/RestaurantIcons/laddition_icon_black.png")}
                  onClick={() => {
                    navigate("/");
                  }}
                  width={80}
                  style={{ cursor: "pointer" }}
                  alt=""
                />
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="center"
                  direction={matchDownSM ? "column-reverse" : "row"}
                >
                  <Grid item>
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography
                        color={"#D84315"}
                        gutterBottom
                        variant={matchDownSM ? "h3" : "h2"}
                        style={{ fontSize: "20px", fontWeight: "bold" }}
                      >
                        {t("signIn.header")}
                      </Typography>
                      <Typography
                        variant="caption"
                        fontSize="16px"
                        textAlign={"center"}
                        style={{
                          color: "darkgrey",
                        }}
                      >
                        {t("signIn.subHeader")}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": { m: 1, width: "25ch" },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <FormControl
                    fullWidth
                    sx={{ ...theme.typography.customInput, marginBottom: 4 }}
                  >
                    <InputLabel htmlFor="outlined-adornment-email-login">
                      {t("signIn.email")}
                    </InputLabel>
                    <OutlinedInput
                      value={credentials.email}
                      onChange={(e) => {
                        updateEmail(e.target.value);
                      }}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#F5F5F5",
                      }}
                      id="outlined-adornment-email-login"
                      type="email"
                      name="email"
                      label="Email Address / Username"
                      inputProps={{}}
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    sx={{ ...theme.typography.customInput }}
                  >
                    <InputLabel htmlFor="outlined-adornment-password-login">
                      {t("signIn.password")}
                    </InputLabel>
                    <OutlinedInput
                      id="outlined-adornment-password-login"
                      type={showPassword ? "text" : "password"}
                      value={credentials.password}
                      style={{
                        borderRadius: "20px",
                        backgroundColor: "#F5F5F5",
                      }}
                      name="password"
                      onChange={(e) => {
                        updatePwd(e.target.value);
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Password"
                      inputProps={{}}
                    />
                  </FormControl>
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={(event) => setChecked(event.target.checked)}
                          name="checked"
                          color="primary"
                        />
                      }
                      label={t("signIn.rememberMe")}
                    />
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      sx={{ textDecoration: "none", cursor: "pointer" }}
                      onClick={ResetPassword}
                    >
                      {t("signIn.forgotPassword")}
                    </Typography>
                  </Stack>
                  <Box
                    sx={{
                      mt: 2,
                      width: "70%",
                      marginLeft: "auto",
                      marginRight: "auto",
                    }}
                  >
                    <AnimateButton>
                      <ColorLoadingButton
                        type="submit"
                        loading={loading}
                        disableElevation
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={Authenticate}
                      >
                        {t("signIn.cta")}
                      </ColorLoadingButton>
                    </AnimateButton>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  item
                  container
                  direction="column"
                  alignItems="center"
                  xs={12}
                >
                  <Typography
                    component={Link}
                    to="/Home/SignUp"
                    variant="subtitle1"
                    sx={{ textDecoration: "none" }}
                  >
                    {t("signIn.noAccount")}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={error !== ""}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default SignIn;
