import { useState, useContext } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { TablePagination, Typography } from "@mui/material";
import { SnackBar } from "src/design";
import { UserContext } from "src/Utils/Context/UserContext";
import bcrypt from "bcryptjs-react";

interface Request {
  id: number;
  phone: string;
  ownerName: string;
  email: string;
  status: "pending" | "accepted" | "rejected";
  requestedRestaurant: string;
  city: string;
  date: string;
  restaurantName: string;
}

const RequestsTable = ({
  requests,
  getRequests,
}: {
  requests: Request[];
  getRequests: () => void;
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [createAccountSuccess, setCreateAccountSuccess] = useState(false);
  const [createAccountFailure, setCreateAccountFailure] = useState(false);
  const { userInfo } = useContext(UserContext);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const generatePassword = () => {
    const length = 10;
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789/*-+!@#$%^&*()_+=-{}[]|:;?/>.<,";
    let retVal = "";
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  const acceptRequest = (id: number) => {
    if (!userInfo) return;

    const password = generatePassword();
    const hashedPassword = bcrypt.hashSync(
      password,
      process.env.REACT_APP_ENCRYPTION_KEY
    );

    fetch("https://laddition.ma/Website/AddOwner.php", {
      method: "POST",
      body: JSON.stringify({
        ownerToken: userInfo.session_token,
        ownerId: userInfo.id,
        requestID: id,
        password: password,
        hashedPassword: hashedPassword,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          getRequests();
          setCreateAccountSuccess(true);
        } else {
          setCreateAccountFailure(true);
        }
      })
      .catch((error) => {
        setCreateAccountFailure(true);
      });
  };

  return (
    <Paper sx={{ width: "100%", mb: 2, marginTop: 2 }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="a deals table">
          <TableHead>
            <TableRow>
              <TableCell>Request Date</TableCell>
              <TableCell align="left">Owner Name</TableCell>
              <TableCell align="left">Phone</TableCell>
              <TableCell align="left">email</TableCell>
              <TableCell align="left">Linked Restaurant</TableCell>
              <TableCell align="left">Requested Restaurant</TableCell>
              <TableCell align="left">City</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {requests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((request, index) => (
                <TableRow key={index}>
                  <TableCell align="left">{request.date}</TableCell>
                  <TableCell align="left">{request.ownerName}</TableCell>
                  <TableCell align="left">{request.phone}</TableCell>
                  <TableCell align="left">{request.email}</TableCell>
                  <TableCell align="left">
                    {request.restaurantName ? (
                      request.restaurantName
                    ) : (
                      <Typography color="error">
                        {"*/ No restaurant linked /*"}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {request.requestedRestaurant}
                  </TableCell>
                  <TableCell align="left">{request.city}</TableCell>
                  <TableCell align="right">
                    <ToggleButtonGroup
                      color={
                        request.status === "accepted"
                          ? "success"
                          : request.status === "pending"
                          ? "standard"
                          : "error"
                      }
                      value={request.status}
                      exclusive
                      size="small"
                      onChange={(_: any, value: string) => null}
                    >
                      <ToggleButton
                        value="rejected"
                        disabled={request.status === "accepted"}
                      >
                        {request.status !== "rejected" ? "reject" : "rejected"}
                      </ToggleButton>
                      <ToggleButton value="pending" disabled>
                        {request.status !== "pending" ? "pending" : "pending"}
                      </ToggleButton>
                      <ToggleButton
                        value="accepted"
                        disabled={
                          request.status === "rejected" ||
                          !request.restaurantName
                        }
                        onClick={() => acceptRequest(request.id)}
                      >
                        {request.status !== "accepted" ? "accept" : "accepted"}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 50, 100]}
          component="div"
          count={requests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(+event.target.value);
            setPage(0);
          }}
          sx={{
            alignItems: "center",
            "& p": {
              margin: 0,
            },
          }}
        />
      </TableContainer>
      <SnackBar
        open={createAccountSuccess}
        onClose={() => setCreateAccountSuccess(false)}
        message="Account Created Successfully!"
        severity="success"
      />
      <SnackBar
        open={createAccountFailure}
        onClose={() => setCreateAccountFailure(false)}
        message="Account Creation Failed!"
        severity="error"
      />
    </Paper>
  );
};

export default RequestsTable;
