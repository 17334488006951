import { groupedBookingsByCityType } from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { PieChart, ElevatedPaper } from "src/design";
import { useTranslation } from "react-i18next";

interface Props {
  events?: groupedBookingsByCityType;
}

const UsersCountryKpi = ({ events }: Props) => {
  const { t } = useTranslation();
  if (!events) return null;

  return (
    <ElevatedPaper
      elevation={8}
      padding={0}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      outerRadius={0}
      textAlign="center"
      fullHeight
    >
      <PieChart
        title={t("statistics.usersCountryKpi.header")}
        data={
          events.morrocan > 0 || events.tourist > 0
            ? [
                {
                  name: "Tourists",
                  value: events.tourist,
                },
                { name: "Locals", value: events.morrocan },
              ]
            : []
        }
      />
    </ElevatedPaper>
  );
};

export default UsersCountryKpi;
