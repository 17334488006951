import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../MainLayout/Header";
import { Box } from "@mui/material";
import SideBar from "../../MainLayout/SideBar";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import { Outlet } from "react-router-dom";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { UserContext } from "src/Utils/Context/UserContext";
import { OwnerContext } from "src/Utils/Context/OwnerContext";

// material-ui
import { styled } from "@mui/material/styles";

//Modals
import NewUserM from "../../MainLayout/Modals/NewUserModal";
import LanguageChangeButton from "src/Utils/Translations/LanguageChangeButton";

const drawerWidth = 240;
const headerHeight = 75;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#141824",
  height: headerHeight,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Main = styled(Box, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  marginTop: headerHeight,
  backgroundColor: "",
  marginLeft: `calc(${theme.spacing(8)} + 1px)`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px) `,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function getWindowDimensions() {
  const { innerWidth: width } = window;
  return {
    width,
  };
}

function PageRestau() {
  const { userInfo, setUserInfo, isLoading } = useContext(UserContext);
  const [show, setShow] = useState(false);

  const [ResList, setResList] = useState([]);
  const [ownerName, setOwnerName] = useState("");
  const [ListLoading, setListLoading] = useState(true);
  const [NameFilter, setNameFilter] = useState("");
  const [closedDrawer, setClosedDrawer] = useState(false);
  const [leftDrawerOpened, setleftDrawerOpened] = useState(true);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleDrawerOpen = () => {
    setleftDrawerOpened(!leftDrawerOpened);
  };
  const handleClose = () => setShow(false);
  let navigate = useNavigate();

  useEffect(() => {
    if (windowDimensions.width < 1250) {
      setleftDrawerOpened(false);
      setClosedDrawer(true);
    } else {
      setleftDrawerOpened(true);
      setClosedDrawer(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (ResList.length === 0 && !isLoading) {
      if (userInfo) {
        GetRestaurant();
      } else {
        navigate(`/login`, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ResList.length, isLoading, navigate, userInfo]);

  const GetRestaurant = () => {
    let apiFile;
    if (userInfo.admin) {
      apiFile = "GetAllRestaurants";
    } else {
      apiFile = "GetOwnerRestaurants";
    }
    fetch(`https://laddition.ma/Website/${apiFile}.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo.id,
        ownerToken: userInfo.session_token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setResList(response.Restaurant_list);
          if (!userInfo.admin) {
            setOwnerName(response.Owner_info.name);
          }
        } else {
          console.log(response.error);
        }
        setListLoading(false);
      })
      .catch((e) => console.log(e));
  };

  const contextValue = {
    isLoading: ListLoading,
    restaurants: ResList,
    refetch: GetRestaurant,
  };

  const ownerContextValue = {
    isLoading: ListLoading,
    ownerInfo: {
      name: ownerName,
    },
    refetch: GetRestaurant,
  };

  return (
    <RestaurantContext.Provider value={contextValue}>
      <OwnerContext.Provider value={ownerContextValue}>
        <LanguageChangeButton left />
        <NewUserM show={show} handleClose={handleClose} />
        <CssBaseline />
        <AppBar position="fixed" open={leftDrawerOpened}>
          <Toolbar>
            <Header
              handleDrawerOpen={handleDrawerOpen}
              leftDrawerOpened={leftDrawerOpened}
              closedDrawer={closedDrawer}
              NameFilter={NameFilter}
              Restaurant_list={ResList}
            />
          </Toolbar>
        </AppBar>
        <SideBar
          leftDrawerOpened={leftDrawerOpened}
          setleftDrawerOpened={setleftDrawerOpened}
          setUserInfo={setUserInfo}
          admin={userInfo?.admin}
        />
        {!ListLoading && (
          <Main open={leftDrawerOpened}>
            <Box
              sx={{
                padding: { xs: 1, md: 2 },
                backgroundColor: "rgb(245, 247, 250)",
                minHeight: `calc(98vh - ${headerHeight}px)`,
              }}
            >
              <Outlet />
            </Box>
          </Main>
        )}
      </OwnerContext.Provider>
    </RestaurantContext.Provider>
  );
}

export default PageRestau;
