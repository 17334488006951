import { Grid, capitalize, IconButton } from "@mui/material";
import { CustomText } from "src/design";
import type { DayHours } from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker";
import { ElevatedPaper } from "src/design";
import { useTranslation } from "react-i18next";
import { TimeClockPicker } from "src/design";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import dayjs, { Dayjs } from "dayjs";

interface Props {
  day: string;
  hours: DayHours;
  setHours: (hours: DayHours) => void;
  fullwidth?: boolean;
  index?: number;
}

const HourComponent = ({
  label,
  value,
  onChange,
  maxTime,
  minTime,
  disabled,
}: {
  label: string;
  value: Dayjs | string;
  onChange: (value: Dayjs) => void;
  maxTime?: dayjs.Dayjs;
  minTime?: dayjs.Dayjs;
  disabled?: boolean;
}) => (
  <Grid
    container
    item
    justifyContent={"space-between"}
    xs={5}
    md={12}
    direction={"row"}
    sx={{ lineHeight: "2.5" }}
  >
    <Grid item md={2}>
      <CustomText label={label} type="subheader" />
    </Grid>
    <Grid item md={9}>
      <TimeClockPicker
        value={value}
        disabled={disabled}
        onChange={onChange}
        minTime={minTime}
        maxTime={maxTime}
      />
    </Grid>
  </Grid>
);

const DayHoursPicker = ({ day, hours, setHours, fullwidth, index }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      sx={{ borderTop: "4px solid #1abc9c" }}
      gap={3}
      lg={fullwidth ? 12 : 2.5}
      md={fullwidth ? 12 : 5}
      xs={12}
      key={index}
    >
      <ElevatedPaper onClick={() => {}} elevation={6} padding={15} fullwidth>
        <Grid container justifyContent={"space-between"}>
          <CustomText
            label={capitalize(day)}
            type="section"
            size={"22px"}
            fontcolor="#1abc9c"
          />
          <IconButton
            onClick={() =>
              setHours({
                morning: { open: "", close: "" },
                afternoon: { open: "", close: "" },
              })
            }
            disabled={hours.morning.open === ""}
          >
            <DeleteForeverIcon
              color={hours.morning.open ? "error" : "disabled"}
            />
          </IconButton>
        </Grid>
        <CustomText
          label={t("restaurant.restaurantInfoPage.hoursPicker.registerMorning")}
          type="subheader"
        />
        <Grid
          container
          sx={{ padding: 2 }}
          columnGap={1}
          justifyContent={"space-between"}
        >
          <HourComponent
            label={t("restaurant.restaurantInfoPage.hoursPicker.open")}
            value={hours.morning.open}
            onChange={(value) =>
              setHours({
                ...hours,
                morning: { ...hours.morning, open: value },
              })
            }
            minTime={dayjs("00:00", "HH:mm")}
            maxTime={dayjs(hours.morning.close, "HH:mm").subtract(1, "minute")}
          />
          <HourComponent
            label={t("restaurant.restaurantInfoPage.hoursPicker.close")}
            value={hours.morning.close}
            onChange={(value) =>
              setHours({
                ...hours,
                morning: { ...hours.morning, close: value },
              })
            }
            minTime={dayjs(hours.morning.open, "HH:mm").add(1, "minute")}
            maxTime={dayjs("23:59", "HH:mm")}
            disabled={hours.morning.open === ""}
          />
        </Grid>
        <CustomText
          label={t(
            "restaurant.restaurantInfoPage.hoursPicker.registerAfternoon"
          )}
          type="subheader"
        />
        <Grid
          container
          sx={{ padding: 2 }}
          columnGap={1}
          justifyContent={"space-between"}
        >
          <HourComponent
            label={t("restaurant.restaurantInfoPage.hoursPicker.open")}
            value={hours.afternoon.open}
            onChange={(value) =>
              setHours({
                ...hours,
                afternoon: { ...hours.afternoon, open: value },
              })
            }
            minTime={dayjs("00:00", "HH:mm")}
            maxTime={dayjs(hours.afternoon.close, "HH:mm").subtract(
              1,
              "minute"
            )}
            disabled={hours.morning.close === ""}
          />
          <HourComponent
            label={t("restaurant.restaurantInfoPage.hoursPicker.close")}
            value={hours.afternoon.close}
            onChange={(value) =>
              setHours({
                ...hours,
                afternoon: { ...hours.afternoon, close: value },
              })
            }
            minTime={dayjs(hours.afternoon.open, "HH:mm").add(1, "minute")}
            maxTime={dayjs("23:59", "HH:mm")}
            disabled={hours.afternoon.open === ""}
          />
        </Grid>
      </ElevatedPaper>
    </Grid>
  );
};

export default DayHoursPicker;
