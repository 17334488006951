import { styled } from "@mui/material/styles";
import { darkBlue, lightBlue } from "src/design/ColorPalette";
import LoadingButton from "@mui/lab/LoadingButton";

export const CustomButton = styled(LoadingButton)(
  ({ theme, color, variant, sx }) => ({
    color: color || theme.palette.getContrastText(lightBlue),
    width: "fit-content",
    textTransform: "none",
    fontWeight: "bold",
    backgroundColor: color || lightBlue,
    "&:hover": {
      backgroundColor: color || darkBlue,
    },
  })
);
