import { Grid, SelectChangeEvent } from "@mui/material";
import { UserSignUpInfo } from "src/Utils/Data/User";
import { InputSelect, TextInput } from "src/design";
import { useTranslation } from "react-i18next";
import { Cities } from "src/Utils/Data";

interface SignUpFormProps {
  onChange: (value: string, attribute: string) => void;
  info: UserSignUpInfo;
  incorrectInfo: {
    firstName: boolean;
    lastName: boolean;
    phone: boolean;
    email: boolean;
    restaurantName: boolean;
    city: boolean;
  };
}

const SignUpForm = ({ onChange, info, incorrectInfo }: SignUpFormProps) => {
  const { t } = useTranslation();

  return (
    <Grid item container spacing={4} md={8}>
      <Grid container item justifyContent="space-between" columnSpacing={3}>
        <Grid item md={6} xs={10}>
          <TextInput
            fullwidth
            error={incorrectInfo.firstName}
            label={t("signUp.form.firstName")}
            onChange={(value) => onChange(value, "firstName")}
            value={info.firstName}
          />
        </Grid>
        <Grid item md={6} xs={10}>
          <TextInput
            fullwidth
            error={incorrectInfo.lastName}
            label={t("signUp.form.lastName")}
            onChange={(value) => onChange(value, "lastName")}
            value={info.lastName}
          />
        </Grid>
      </Grid>
      <Grid item md={6} xs={10}>
        <TextInput
          fullwidth
          error={incorrectInfo.email}
          label={t("signUp.form.email")}
          onChange={(value) => onChange(value, "email")}
          value={info.email}
          type="email"
        />
      </Grid>
      <Grid item md={6} xs={10}>
        <TextInput
          fullwidth
          error={incorrectInfo.phone}
          label={t("signUp.form.phone")}
          onChange={(value) => onChange(value, "phone")}
          value={info.phone}
        />
      </Grid>
      <Grid item md={6} xs={10}>
        <TextInput
          fullwidth
          error={incorrectInfo.restaurantName}
          label={t("signUp.form.restaurantName")}
          onChange={(value) => onChange(value, "restaurantName")}
          value={info.restaurantName}
        />
      </Grid>
      <Grid item md={6} xs={10}>
        <InputSelect
          multiple={false}
          options={Cities.map((city) => city.city)}
          label={t("signUp.form.city")}
          value={info.city}
          onChange={(e: SelectChangeEvent) => onChange(e.target.value, "city")}
        />
      </Grid>
    </Grid>
  );
};

export default SignUpForm;
