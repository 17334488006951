import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { TextField } from "@mui/material";
import type { Dayjs } from "dayjs";

interface TimeClockPickerProps {
  label?: string;
  disabled?: boolean;
  value: Dayjs | string;
  onChange: (value: Dayjs) => void;
  minTime?: Dayjs;
  maxTime?: Dayjs;
  hoursOnly?: boolean;
  minutesStep?: number;
}

export const TimeClockPicker = ({
  label = "",
  disabled = false,
  value,
  onChange,
  minTime,
  maxTime,
  hoursOnly,
}: TimeClockPickerProps) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <TimePicker
      minutesStep={30}
      views={hoursOnly ? ["hours"] : ["hours", "minutes"]}
      ampm={false}
      label={label}
      disabled={disabled}
      value={value}
      onAccept={(newValue) => onChange(newValue as Dayjs)}
      onChange={(newValue) => null}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          variant="standard"
          error={false}
          fullWidth
        />
      )}
      minTime={minTime}
      maxTime={maxTime}
    />
  </LocalizationProvider>
);
