import { createContext } from "react";

const defaultOwnerContext = {
  isLoading: true,
  ownerInfo: {
    name: "",
  },
  refetch: () => {},
};

interface OwnerContextType {
  isLoading: boolean;
  ownerInfo: {
    name: string;
  };
  refetch: () => void;
}

export const OwnerContext =
  createContext<OwnerContextType>(defaultOwnerContext);
