import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";

const lightTheme = createTheme({ palette: { mode: "light" } });

interface ElevatedPaperProps {
  elevation: number;
  children: React.ReactNode;
  textAlign?: "left" | "center" | "right";
  Tappable?: boolean;
  onClick?: () => void;
  fullwidth?: boolean;
  fullHeight?: boolean;
  padding?: number;
  outerPadding?: number;
  maxHeight?: number | null;
  innerRadius?: number;
  outerRadius?: number;
  borderTop?: string;
}

export const ElevatedPaper = ({
  elevation,
  children,
  textAlign = "left",
  Tappable = false,
  onClick,
  fullwidth,
  fullHeight,
  padding = 0,
  outerPadding = 0,
  maxHeight = null,
  innerRadius = 0,
  outerRadius = 8,
  borderTop,
}: ElevatedPaperProps) => {
  const Item = styled(Paper)(({ theme }) => ({
    // @ts-ignore
    ...theme.typography.body2,
    textAlign: textAlign,
    color: theme.palette.text.secondary,
    lineHeight: "60px",
    padding: padding,
    cursor: Tappable ? "pointer" : "auto",
    borderRadius: 0,
    overflow: "hidden",
    maxHeight: maxHeight,
    height: fullHeight ? "100%" : "auto",
  }));

  return (
    <ThemeProvider theme={lightTheme}>
      <Box
        sx={{
          p: fullwidth ? 0 : 2,
          borderRadius: outerRadius,
          bgcolor: "background.default",
          gridTemplateColumns: { md: "1fr 1fr" },
          padding: outerPadding,
          borderTop: borderTop,
          height: fullHeight ? "100%" : "auto",
          minHeight: 180,
        }}
      >
        <Item
          elevation={elevation}
          style={{
            borderRadius: innerRadius,
          }}
          onClick={
            Tappable
              ? onClick // Check if onClick is defined before invoking it
              : () => {}
          }
        >
          {children}
        </Item>
      </Box>
    </ThemeProvider>
  );
};
