import {
  Grid,
  ButtonGroup,
  Button,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useEffect, useContext, useState } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import { Outlet, useNavigate } from "react-router-dom";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";

const ADMINPAGES = [
  { name: "Account Requests", path: "account-requests", index: 0 },
  { name: "Link Restaurant", path: "restaurant-link/", index: 1 },
  { name: "Reservations", path: "bookings/", index: 2 },
  { name: "Deals", path: "deals/", index: 3 },
  { name: "Feature Switches", path: "feature-switches", index: 4 },
  { name: "Website Kpis", path: "kpis", index: 5 },
];

const AdminDashboard = () => {
  const { userInfo, isLoading: userLoading } = useContext(UserContext);
  const { restaurants, isLoading: restaurantsLoading } = useContext(
    RestaurantContext
  );
  const [selectedRestaurant, setSelectedRestaurant] = useState<
    string | undefined
  >();
  const [NameFilter] = useState("");
  const [filter, setFilter] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/login", { replace: true });
  };

  useEffect(() => {
    const selectedOption = ADMINPAGES.filter((value) =>
      window.location.pathname.includes(value.path)
    );
    if (selectedOption.length > 0) {
      setCurrentPage(selectedOption[0].index);
    } else {
      navigate("/restaurant/admin-dashboard/account-requests", { replace: true });
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (userLoading) return;
    if (!userInfo) {
      handleLogout();
    } else {
      fetch("https://laddition.ma/Website/Admin_Authentication.php", {
        method: "POST",
        body: JSON.stringify({
          ownerId: userInfo.id,
          ownerToken: userInfo.session_token,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (!response.success) {
            handleLogout();
          }
        })
        .catch((err) => {
          handleLogout();
        });
    }
  }, []);

  if (!restaurants) return;

  return (
    <Grid container spacing={10}>
      <Grid item container justifyContent={"center"} spacing={5}>
        <Grid item xs={8} md={3}>
          <Autocomplete
            freeSolo
            options={restaurants
              .filter((restaurant) => {
                if (filter === "") return true;
                else
                  return restaurant.Nom.toLowerCase().includes(
                    filter.toLowerCase()
                  );
              })
              .map((res) => res.Nom)}
            onChange={(event, newValue) => {
              const restaurant = restaurants.find(
                (res) => res.Nom === newValue
              );
              setSelectedRestaurant(restaurant?.restaurant_id);
              if (
                [1, 2, 3].includes(currentPage) &&
                restaurant?.restaurant_id
              ) {
                navigate(
                  `/restaurant/admin-dashboard/${ADMINPAGES[currentPage].path}${restaurant?.restaurant_id}`,
                  { replace: true }
                );
              }
            }}
            handleHomeEndKeys
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  type: "search",
                  style: { borderRadius: 16 },
                  endAdornment: null,
                }}
                style={{ borderRadius: 16 }}
                InputLabelProps={{ style: { color: "#9fa6bc" } }}
                label="Selecte a restaurant"
                variant="outlined"
                value={NameFilter}
                onChange={(value) => {}}
                size="small"
                fullWidth
              />
            )}
          />
        </Grid>
        <Grid item>
          <ButtonGroup variant="text">
            {ADMINPAGES.map((page, index) => (
              <Button
                key={index}
                variant={index === currentPage ? "contained" : "text"}
                onClick={() => {
                  navigate(
                    `${page.path}${
                      page.path.includes("/") ? selectedRestaurant || "" : ""
                    }`
                  );
                }}
              >
                {page.name}
              </Button>
            ))}
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item container>
        <Outlet />
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
