import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1abc9c",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          "--TextField-brandBorderColor": "#E0E3E7",
          "--TextField-brandBorderHoverColor": "#B2BAC2",
          "--TextField-brandBorderFocusedColor": "#6F7E8C",
          "& label.Mui-focused": {
            color: "var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          "&:before, &:after": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          "&:before": {
            borderBottom: "2px solid var(--TextField-brandBorderColor)",
          },
          "&:hover:not(.Mui-disabled, .Mui-error):before": {
            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)",
          },
          "&.Mui-focused:after": {
            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)",
          },
        },
      },
    },
  },
});

interface TextInputProps {
  variant?: "standard" | "outlined" | "filled";
  label?: string;
  value: string;
  onChange: (value: string) => void;
  color?: "primary" | "secondary";
  fullwidth?: boolean;
  required?: boolean;
  type?: string;
  darkMode?: boolean;
  size?: "small" | "medium";
  multiline?: boolean;
  maxLength?: number;
  error?: boolean;
  disabled?: boolean;
}

export const TextInput = ({
  variant = "outlined",
  label = "",
  value,
  onChange,
  color = "primary",
  fullwidth = false,
  required = false,
  type = "",
  darkMode = false,
  size = "medium",
  multiline = false,
  maxLength,
  error = false,
  disabled,
}: TextInputProps) => {
  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <ThemeProvider theme={customTheme}>
        <TextField
          disabled={disabled}
          multiline={multiline}
          rows={5}
          style={
            darkMode
              ? { backgroundColor: "rgb(34, 40, 52)", borderRadius: 16 }
              : {}
          }
          InputLabelProps={darkMode ? { style: { color: "#9fa6bc" } } : {}}
          inputProps={darkMode ? { style: { color: "#9fa6bc" } } : {}}
          label={label}
          variant={variant}
          value={value}
          onChange={(event) => onChange(event.target.value)}
          color={color}
          fullWidth={fullwidth}
          required={required}
          type={type}
          size={size}
          error={maxLength ? value.length > maxLength : false || error}
          helperText={maxLength ? `${value.length}/${maxLength}` : ""}
        />
      </ThemeProvider>
    </Box>
  );
};
