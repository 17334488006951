import { useEffect, useState } from "react";
import SignUpForm from "src/components/Authentication/SignUp/SignUpForm";
import { Grid } from "@mui/material";
import { CustomText, CustomLoadingButton, SnackBar } from "src/design";
import { emerald } from "src/design/ColorPalette";
import type { UserSignUpInfo } from "src/Utils/Data/User";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const SignUp = () => {
  const { t } = useTranslation();
  const [canCreate, setCanCreate] = useState(false);
  const [creating, setCreating] = useState(false);
  const [creationSuccess, setCreationSuccess] = useState(false);
  const [creationError, setCreationError] = useState(false);
  const [emailTaken, setEmailTaken] = useState(false);
  const [info, setInfo] = useState<UserSignUpInfo>({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    restaurantName: "",
    city: "",
  });

  const [incorrectInfo, setIncorrectInfo] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    restaurantName: false,
    city: false,
  });

  const onInfoChange = (value: string, attribute: string) => {
    setIncorrectInfo({ ...incorrectInfo, [attribute]: false });
    setInfo({ ...info, [attribute]: value });
  };

  useEffect(() => {
    if (
      info.firstName &&
      info.lastName &&
      info.phone &&
      info.email &&
      info.restaurantName &&
      info.city
    ) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [info]);

  const verifyInfo = () => {
    if (
      info.firstName &&
      info.lastName &&
      /[0-9]{10}/g.test(info.phone) &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(info.email) &&
      info.restaurantName &&
      info.city
    ) {
      return true;
    } else {
      setIncorrectInfo({
        firstName: !info.firstName,
        lastName: !info.lastName,
        phone: !/[0-9]{10}/g.test(info.phone),
        email: !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(
          info.email
        ),
        restaurantName: !info.restaurantName,
        city: !info.city,
      });
      return false;
    }
  };

  const CreateAccount = () => {
    setCreating(true);
    if (!verifyInfo()) {
      setCreating(false);
      return;
    }

    fetch(`https://laddition.ma/Website/SubmitAccountRequest.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerName: info.firstName + " " + info.lastName,
        email: info.email,
        phone: info.phone.toString(),
        restaurantName: info.restaurantName,
        city: info.city,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setCreationSuccess(true);
        } else {
          if (response.error === "email already exists") {
            setEmailTaken(true);
          } else {
            setCreationError(true);
          }
        }
        setCanCreate(false);
        setCreating(false);
      })
      .catch((e) => {
        setCanCreate(true);
        setCreationError(true);
      });
  };

  return (
    <Grid
      container
      width="100%"
      sx={{ marginLeft: "80px" }}
      alignContent={"center"}
      justifyContent={"center"}
      spacing={5}
    >
      <Grid item md={8}>
        <CustomText
          fontcolor={emerald}
          label={t("signUp.header")}
          type="header"
        />
        <CustomText label={t("signUp.subheader")} type="subheader" />
      </Grid>
      <SignUpForm
        info={info}
        onChange={onInfoChange}
        incorrectInfo={incorrectInfo}
      />
      <Grid item md={6}>
        <Box sx={{ width: "50%", marginRight: "auto", marginLeft: "auto" }}>
          <CustomLoadingButton
            disabled={!canCreate}
            onSubmit={CreateAccount}
            loading={creating}
            label={t("signUp.createAccount")}
          />
        </Box>
      </Grid>
      <SnackBar
        open={creationSuccess}
        onClose={() => setCreationSuccess(false)}
        message={t("signUp.creationSuccess")}
        severity="success"
      />
      <SnackBar
        open={creationError}
        onClose={() => setCreationError(false)}
        message={t("signUp.creationError")}
        severity="error"
      />
      <SnackBar
        open={emailTaken}
        onClose={() => setEmailTaken(false)}
        message={t("signUp.emailTaken")}
        severity="warning"
      />
    </Grid>
  );
};

export default SignUp;
