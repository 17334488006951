import { CustomText } from "src/design";
import { BaseTextFieldProps, InputAdornment, TextField } from "@mui/material";
import { CSSProperties } from "react";

interface ItemFieldProps {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  type?: "header" | "subheader" | "section" | "body";
  color?: CSSProperties["color"];
  disabled: boolean;
  editing: boolean;
  label: string;
  fullWidth?: boolean;
  inputType?: BaseTextFieldProps["type"];
  endAdornment?: string;
}

const ItemField = ({
  value,
  label,
  onChange,
  disabled,
  type = "body",
  color = "",
  inputType = "",
  editing,
  fullWidth,
  endAdornment,
}: ItemFieldProps) =>
  editing ? (
    <TextField
      fullWidth={fullWidth}
      label={label}
      variant="standard"
      disabled={disabled}
      value={value}
      onChange={onChange}
      type={inputType}
      InputProps={{
        endAdornment: endAdornment ? (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ) : null,
      }}
    />
  ) : (
    <CustomText
      label={`${value} ${value && endAdornment ? endAdornment : ""}`}
      fontcolor={color}
      type={type}
    />
  );

export default ItemField;
