import { Grid } from "@mui/material";
import { CustomText } from "src/design";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";
import HompageImage from "src/assets/HomePageImage.png";
import { Trans } from "react-i18next";
import { styled } from "@mui/material/styles";

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.blue.light),
  backgroundColor: theme.palette.blue.light,
  "&:hover": {
    backgroundColor: theme.palette.blue.main,
  },
}));

export const About = () => {
  const { t } = useTranslation();

  return (
    <Grid item>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        height={"max-content"}
        style={{ marginTop: 40 }}
      >
        <Grid item xs={12} md={7}>
          <CustomText
            label={t("homePage.header")}
            type="header"
            size={45}
            component={"h1"}
            fontFamily="Bogart-bold"
          />
          <CustomText
            label={t("homePage.secondHeader")}
            type="header"
            size={45}
            component={"h1"}
            fontFamily="Bogart-bold"
          />
          <h2
            style={{
              fontSize: 35,
              marginTop: 75,
              fontFamily: "Bogart-light",
              lineHeight: 1.5,
            }}
          >
            <Trans i18nKey="homePage.subheader" />
          </h2>
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <img src={HompageImage} alt="home page" width={"90%"} />
        </Grid>
      </Grid>
      <Grid width={"100%"} container justifyContent={"center"}>
        <ColorButton variant="contained" size="large" style={{ marginTop: 50 }}>
          {t("homePage.comingSoon")}
        </ColorButton>
      </Grid>
    </Grid>
  );
};
