import { Box, Button, Grid } from "@mui/material";
import ImageUploading, { ImageListType } from "react-images-uploading";
import Compressor from "compressorjs";
import { CustomLoadingButton } from "src/design";
import { useTranslation } from "react-i18next";
import type { Image } from "src/components/Main/Pages/Home/RestaurantInformation/ImageGallery";

interface ImageUploadProps {
  image: Image[];
  uploadimg: () => void;
  uploading: boolean;
  setImages: any;
  multiple?: boolean;
  uploadLabel: string;
  canSave: boolean;
}

export const ImageUpload = ({
  image,
  uploadimg,
  uploading,
  setImages,
  multiple = false,
  uploadLabel,
  canSave,
}: ImageUploadProps) => {
  const { t } = useTranslation();
  const onChange = (imageList: ImageListType) => {
    var newImageList = [...imageList];
    newImageList.forEach((newimage, index) => {
      var file = newimage.file;
      if (newimage.file) {
        if (file && file.size > 400000) {
          new Compressor(file, {
            quality: 400000 / file.size,
            success: (compressedFile) => {
              newImageList[index].file = new File(
                [compressedFile],
                (compressedFile as File).name,
                {
                  type: "image/webp",
                }
              );
            },
          });
        }
      }
    });
    setImages(newImageList);
  };

  const onSave = (
    onImageUpload: { (): void; (): void },
    onImageUpdate: { (index: number): void; (arg0: number): void }
  ) => {
    if (image.length > 0 && !multiple) {
      onImageUpdate(0);
    } else {
      onImageUpload();
    }
  };

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
      }}
    >
      <ImageUploading
        value={image}
        onChange={onChange}
        dataURLKey="data_url"
        multiple={multiple}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
        }) => (
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
            sx={{ marginTop: 4 }}
            width={"100%"}
          >
            <Grid item xs={8} md={5}>
              <Button
                variant="outlined"
                size="large"
                onClick={() => onSave(onImageUpload, onImageUpdate)}
                {...dragProps}
              >
                {uploadLabel}
              </Button>
            </Grid>
            <Grid item xs={8} md={5}>
              <CustomLoadingButton
                loading={uploading}
                style={{ margin: 10 }}
                onSubmit={uploadimg}
                disabled={!canSave}
                label={t("common.save")}
                variant="outlined"
              />
            </Grid>
          </Grid>
        )}
      </ImageUploading>
    </Box>
  );
};
