import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { IconButton, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserContext } from "src/Utils/Context/UserContext";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const ProfileDropDown = () => {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [success, setSuccess] = React.useState(false);
  const [failure, setFailure] = React.useState(false);

  const [cookies, setCookie, removeCookie] = useCookies([
    "id",
    "session_token",
  ]);
  const { setUserInfo } = useContext(UserContext);

  let navigate = useNavigate();

  const handleReset = () => {
    fetch(`https://laddition.ma/Website/SendResetEmail.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: cookies.id,
        ownerToken: cookies.session_token,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setSuccess(true);
        } else {
          setFailure(true);
          console.log(response.error);
        }
      });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = () => {
    setUserInfo(undefined);
    removeCookie("id", { path: "/" });
    removeCookie("session_token", { path: "/" });
    navigate(`/login`);
  };

  return (
    <>
      <IconButton
        onClick={handleClick}
        size="medium"
        sx={{ ml: 2 }}
        aria-controls={open ? "account-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
      >
        <Avatar
          sx={{ width: 32, height: 32 }}
          src="src/assets/AvatarIcon.jpg"
        />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleReset}>
          <ListItemIcon>
            <Settings fontSize="small" />
          </ListItemIcon>
          <Typography fontSize={14}>
            {t("profileDropDown.changePassword")}
          </Typography>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          <Typography fontSize={14}>{t("profileDropDown.logout")}</Typography>
        </MenuItem>
      </Menu>
      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={() => setSuccess(false)}
      >
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("profileDropDown.emailSent")}
        </Alert>
      </Snackbar>
      <Snackbar
        open={failure}
        autoHideDuration={6000}
        onClose={() => setFailure(false)}
      >
        <Alert
          onClose={() => setFailure(true)}
          severity="error"
          sx={{ width: "100%" }}
        >
          There was a problem please try again or contact us if the problem
          persists
        </Alert>
      </Snackbar>
    </>
  );
};
