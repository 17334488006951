import { bookingEvents } from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { ElevatedPaper, AreaChart } from "src/design";
import { useTranslation } from "react-i18next";

const BoookingsKpi = ({ events }: { events?: bookingEvents }) => {
  const { t } = useTranslation();
  if (!events) return null;

  return (
    <ElevatedPaper
      elevation={8}
      padding={10}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      outerRadius={0}
      textAlign="center"
    >
      <AreaChart
        value={`${events.comparativeKpi.totalBookings}`}
        title={t("statistics.bookingsKpiLabel")}
        data={Object.keys(events.groupedBookingsByDate).map((key) => ({
          name: key,
          bookings: events.groupedBookingsByDate[key],
        }))}
        dataKeys={["bookings"]}
      />
    </ElevatedPaper>
  );
};

export default BoookingsKpi;
