import type { ConstantsType } from "./Constants";

export interface ConfigurationType {
  constants: ConstantsType;
  finishedLoading: boolean;
  language: string;
}

export const defaultConfiguration: ConfigurationType = {
  constants: {
    tags: [],
  },
  finishedLoading: false,
  language: "fr",
};
