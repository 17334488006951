import { useParams } from "react-router-dom";
import { Deals } from "src/components/Main/Pages/Deals";
import { CustomText } from "src/design";
import AddIcon from "@mui/icons-material/Add";
import Fab from "@mui/material/Fab";
import { AddNewDeal } from "src/components/Main/Pages/Deals/AddNewDeal";
import { useContext, useState } from "react";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";

const AdminDeals = () => {
  const { restaurants, refetch } = useContext(RestaurantContext);

  const { restaurantId } = useParams();

  const [AddDealPage, setAddDealPage] = useState(false);
  const handleOpen = () => setAddDealPage(true);
  const handleClose = () => setAddDealPage(false);

  return (
    <>
      {AddDealPage ? (
        <div style={{ width: "100%" }}>
          <AddNewDeal
            restaurants={restaurants}
            handleClose={handleClose}
            getDeals={refetch}
          />
        </div>
      ) : (
        <>
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: "fixed",
              bottom: 16,
            }}
            onClick={handleOpen}
          >
            <AddIcon />
          </Fab>
          {!restaurantId ? (
            <div>
              <CustomText
                label="Select a restaurant to proceed"
                type="section"
              />
            </div>
          ) : (
            <Deals restaurant_id={restaurantId} />
          )}
        </>
      )}
    </>
  );
};

export default AdminDeals;
