import PropTypes from "prop-types";
// material-ui
import { Grid, TextField } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { ProfileDropDown } from "./ProfileDropDown";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({
  handleDrawerOpen,
  leftDrawerOpened,
  closedDrawer,
  NameFilter,
  Restaurant_list,
}) => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState("");

  return (
    <Grid
      container
      width="100%"
      alignItems="center"
      justifyContent="space-between"
    >
      {!leftDrawerOpened && !closedDrawer && (
        <Grid item md={1}>
          <IconButton
            style={{ borderRadius: 16, height: 35, alignSelf: "center" }}
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        </Grid>
      )}
      <Grid item md={6}>
        <Autocomplete
          size="small"
          options={Restaurant_list.filter((restaurant) => {
            if (filter === "") return true;
            else
              return restaurant.Nom.toLowerCase().includes(
                filter.toLowerCase()
              );
          }).map((res) => res.Nom)}
          onChange={(event, newValue) => {
            if (newValue) {
              navigate(
                `/restaurant/${
                  Restaurant_list.find((res) => res.Nom === newValue)
                    .restaurant_id
                }`
              );
            } else {
              navigate("/restaurant/restaurant-list");
            }
          }}
          handleHomeEndKeys
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                type: "search",
                style: { color: "#9fa6bc" },
                endAdornment: null,
              }}
              style={{ backgroundColor: "rgb(34, 40, 52)", borderRadius: 16 }}
              InputLabelProps={{ style: { color: "#9fa6bc" } }}
              label="Recherche ..."
              variant="outlined"
              value={NameFilter}
              onChange={(value) => {}}
              size="small"
            />
          )}
        />
      </Grid>
      <Grid item md={2}>
        <Grid container justifyContent="flex-end">
          <ProfileDropDown />
        </Grid>
      </Grid>
    </Grid>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
