import { Grid, Link } from "@mui/material";
import dayjs from "dayjs";
import { useContext, useState } from "react";
import HomeHeader from "src/components/Main/Pages/HomeDashboard/components/HomeHeader";
import useGetDashEvents from "src/components/Main/Pages/HomeDashboard/data/useGetDashEvents";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { useTranslation } from "react-i18next";
import TextKpi from "src/components/Main/Pages/HomeDashboard/components/TextKpi";
import { AreaChart } from "src/design";
import SearchingGif from "src/assets/Animations/Searching.gif";
import DealsTable from "src/components/Main/Pages/HomeDashboard/components/DealsTable";
import BookingTable from "src/components/Main/Pages/HomeDashboard/components/BookingsTable";

const getLabelKey = (index: number) => {
  switch (index) {
    case 0:
      return "restaurant.home.kpi.day";
    case 1:
      return "restaurant.home.kpi.twoDays";
    case 2:
      return "restaurant.home.kpi.week";
    case 3:
      return "restaurant.home.kpi.twoWeeks";
    case 4:
      return "restaurant.home.kpi.month";
    case 5:
      return "restaurant.home.kpi.year";
    default:
      return "restaurant.home.kpi.day";
  }
};

const HomeDashboard = () => {
  const { t } = useTranslation();
  const today = dayjs().format("YYYY-MM-DD");
  const oneWeekAgo = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState<string>(oneWeekAgo.toString());
  const [endDate, setEndDate] = useState<string>(today.toString());
  const diff = Math.abs(dayjs(endDate).diff(dayjs(startDate), "day"));

  const [optionIndex, setOptionIndex] = useState<number>(2);
  const { restaurants } = useContext(RestaurantContext);
  const label = getLabelKey(optionIndex);

  const { isLoading, dashEvents } = useGetDashEvents({
    start_date: startDate,
    end_date: endDate,
    previous_kpi_start_date: dayjs(startDate)
      .subtract(diff, "day")
      .format("YYYY-MM-DD"),
    restaurant_ids: restaurants
      .map((restaurant) => restaurant.restaurant_id)
      .join(","),
  });

  return (
    <Grid container width={"100%"}>
      <Grid item xs={12}>
        <HomeHeader
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setOptionIndex={setOptionIndex}
        />
      </Grid>
      <Grid container item spacing={3}>
        <Grid item xs={12} md={5}>
          {isLoading ? (
            <img src={SearchingGif} alt="Searching" width={"100%"} />
          ) : (
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                container
                justifyContent={"space-evenly"}
                spacing={3}
              >
                <Grid item xs={12} md={6}>
                  <TextKpi
                    fullHeight
                    value={dashEvents.totalBookings.toString()}
                    title={t("restaurant.home.kpi.bookings")}
                    diffLabel={t(label)}
                    progress={dashEvents.bookingsProgress}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextKpi
                    value={dashEvents.seatsFilled.toString()}
                    title={t("restaurant.home.kpi.visitors")}
                    diffLabel={t(label)}
                    progress={dashEvents.seatsFilledProgress}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <AreaChart
                  dataKeys={["bookings"]}
                  data={Object.keys(dashEvents.groupedBookingsByDate).map(
                    (key) => ({
                      name: key,
                      bookings: dashEvents.groupedBookingsByDate[key],
                    })
                  )}
                  title={t("restaurant.home.kpi.bookings")}
                  progression={
                    dashEvents.totalBookings / dashEvents.bookingsProgress - 1
                  }
                  value={dashEvents.totalBookings.toString()}
                  tooltip={t("restaurant.home.kpi.bookingsTooltip")}
                  compact
                />
              </Grid>
              <Grid container justifyContent={"center"}>
                <Link href="/restaurant/statistics" color="primary">
                  {t("restaurant.home.seeStats")}
                </Link>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container direction={"row"} gap={3}>
            <Grid xs={12} item>
              <BookingTable />
            </Grid>
            <Grid xs={12} item>
              <DealsTable />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeDashboard;
