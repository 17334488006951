import { createContext, useEffect, useMemo, useState } from "react";
import type { PropsWithChildren, ReactNode } from "react";
import { defaultConfiguration, ConfigurationType } from "../Data/Configuration";
import { useTranslation } from "react-i18next";
import type { ConstantsType } from "../Data/Constants";

export const ConfigurationContext = createContext<ConfigurationType>(
  defaultConfiguration
);

export const ConfigurationProvider = ({ children }: PropsWithChildren<ReactNode>) => {
  const [constants, setConstants] = useState<ConstantsType>(defaultConfiguration.constants);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const { i18n } = useTranslation();
  const language = i18n.language;

  useEffect(() => {
    if (!finishedLoading) {
      fetch(
        `https://laddition.ma/Website/GetConfiguration.php?language=${language}`
      )
        .then((res) => res.json())
        .then((data) => {
          if (data.success) {
            setConstants({ ...constants, tags: data.tags });
          } else {
            setConstants(defaultConfiguration.constants);
          }
          setFinishedLoading(true);
        })
        .catch((e) => {
          setConstants(defaultConfiguration.constants);
        });
    }
  }, [language]);

  const configuration = useMemo(
    () => ({
      constants,
      finishedLoading,
      language,
    }),
    [i18n, constants, finishedLoading]
  );

  return (
    <ConfigurationContext.Provider value={configuration}>
      {children}
    </ConfigurationContext.Provider>
  );
};
