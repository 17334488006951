import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TailSpin } from "react-loader-spinner";
import { CustomText } from "src/design";
import Fab from "@mui/material/Fab";
import { Switch, TablePagination } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { TableHeader } from "./TableHeader";
import { Deal } from "src/business/deal";
import { useTranslation } from "react-i18next";

export interface SelectedFilters {
  status: string;
  restaurantName: string[];
  type: string[];
  date: string;
}

interface Props {
  handleOpen: () => void;
  deals: Deal[];
  loading: boolean;
  Filters: any;
  updateStatus: (dealId: string, status: number) => void;
  compact?: boolean;
  setSelectedDeal: (value: Deal) => void;
}

export const DealsTable = ({
  handleOpen,
  deals,
  loading,
  Filters,
  updateStatus,
  compact,
  setSelectedDeal,
}: Props) => {
  const { t } = useTranslation();

  const [SelectedFilter, setSelectedFilter] = useState<SelectedFilters>({
    status: "",
    restaurantName: [],
    type: [],
    date: "",
  });
  const [page, setPage] = useState(0);
  const [dealsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
      {!compact && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{
            position: "fixed",
            bottom: 16,
          }}
          onClick={handleOpen}
        >
          <AddIcon />
        </Fab>
      )}
      <CustomText label={t("deals.header")} type="header" />
      <CustomText label={t("deals.subheader")} type={"subheader"} />
      {deals.length === 0 ? (
        <h3 style={{ textAlign: "center" }}>Vous n'avez pas encore de deal</h3>
      ) : (
        <>
          {loading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <TailSpin color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <Paper sx={{ width: "100%", mb: 2, marginTop: 2 }}>
              <TableHeader
                Filters={Filters}
                SelectedFilter={SelectedFilter}
                setSelectedFilter={setSelectedFilter}
              />
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 650 }}
                  size="small"
                  aria-label="deals table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>{t("deals.table.status")}</TableCell>
                      <TableCell align="center">
                        {t("deals.table.description")}
                      </TableCell>
                      <TableCell align="right">
                        {t("deals.table.restaurant")}
                      </TableCell>
                      <TableCell align="right">
                        {t("deals.table.type")}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deals
                      .filter((row) => {
                        const { status, restaurantName, type, date } =
                          SelectedFilter;

                        const RestaurantStatus = row.status
                          ? t("deals.active")
                          : t("deals.inactive");
                        if (status !== "" && status !== RestaurantStatus) {
                          return false;
                        }
                        if (
                          restaurantName.length > 0 &&
                          restaurantName.indexOf(row.restaurantName) === -1
                        ) {
                          return false;
                        }
                        if (
                          type.length > 0 &&
                          type.indexOf(
                            row.is_event ? t("deals.event") : t("deals.deal")
                          ) === -1
                        ) {
                          return false;
                        }
                        if (date !== "" && row.jours.indexOf(date) === -1) {
                          return false;
                        }
                        return true;
                      })
                      .slice(
                        page * dealsPerPage,
                        page * dealsPerPage + dealsPerPage
                      )
                      .map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{ ":hover": { cursor: "pointer" } }}
                          onClick={() => {
                            setSelectedDeal(row);
                          }}
                        >
                          <TableCell>
                            <Switch
                              checked={row.status ? true : false}
                              onChange={() =>
                                updateStatus(row.id_offre, Number(!row.status))
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            {row.description}
                          </TableCell>
                          <TableCell align="right">
                            {row.restaurantName}
                          </TableCell>
                          <TableCell align="right">
                            {row.is_event ? "Evenement" : "Offre"}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
                <TablePagination
                  component="div"
                  rowsPerPageOptions={[10, 50, 100]}
                  count={deals.length}
                  rowsPerPage={dealsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{
                    alignItems: "center",
                    "& p": {
                      margin: 0,
                    },
                  }}
                />
              </TableContainer>
            </Paper>
          )}
        </>
      )}
    </>
  );
};
