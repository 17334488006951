import {
  BarChart as RechartsBar,
  Tooltip,
  XAxis,
  Bar,
  ResponsiveContainer,
  YAxis,
  Label,
} from "recharts";
import { chartsPalette } from "src/design/ColorPalette";
import {
  Box,
  Button,
  Grid,
  Tooltip as MuiTooltip,
  IconButton,
} from "@mui/material";
import { CustomText } from "src/design/CustomText";
import { TrendChip } from "src/design/TrendChip";
import { useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import NoDataFound from "src/assets/no-data-found.png";

interface Props {
  data: Record<string, string | number>[];
  title?: string;
  dataKeys: string[];
  progress?: number;
  value?: string;
  palette?: string[];
  disableTooltip?: boolean;
  yAxis?: boolean;
  tooltip?: string;
  Xlabel?: string;
}

export const BarChart = ({
  data,
  title,
  dataKeys,
  progress,
  value,
  disableTooltip,
  palette,
  yAxis,
  tooltip,
  Xlabel,
}: Props) => {
  const [filter, setFilter] = useState<string[]>([]);
  const colorPalette = palette || chartsPalette[`${dataKeys.length}`];

  return (
    <Grid container width={"100%"} height={"100%"}>
      <Grid item textAlign={"left"} marginLeft={5} width={"100%"}>
        {title && (
          <Grid container alignItems={"center"} width={"100%"}>
            <Grid item>
              <CustomText component={"h3"} type="subheader" label={title} />
            </Grid>
            {tooltip && (
              <MuiTooltip title={tooltip}>
                <IconButton sx={{ padding: 0, marginLeft: 0.5 }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </MuiTooltip>
            )}
          </Grid>
        )}
        {value && data.length > 0 && (
          <Grid
            container
            direction={"row"}
            justifyContent={"flex-start"}
            spacing={1}
            alignItems={"center"}
          >
            <Grid item>
              <CustomText
                component={"h4"}
                type="section"
                label={Number(value) > 1000 ? value + "k" : value}
                fontFamily="sans-serif"
              />
            </Grid>
            {progress && (
              <Grid item>
                <TrendChip progress={progress} />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      {data.length === 0 ? (
        <Grid container justifyContent={"center"}>
          <img src={NoDataFound} alt="No data found" />
        </Grid>
      ) : (
        <ResponsiveContainer width={"100%"} height={350}>
          <RechartsBar
            data={data}
            margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
          >
            <XAxis dataKey="name">
              {Xlabel && (
                <Label value={Xlabel} offset={0} position="insideBottom" />
              )}
            </XAxis>
            {yAxis && <YAxis />}
            {!disableTooltip && <Tooltip />}
            {dataKeys
              .filter((value) => {
                if (filter.length === 0) return true;
                return filter.includes(value);
              })
              .map((key, index) => (
                <Bar
                  key={index}
                  stackId="a"
                  dataKey={key}
                  fill={colorPalette[dataKeys.indexOf(key)]}
                />
              ))}
          </RechartsBar>
        </ResponsiveContainer>
      )}
      <Grid container justifyContent={"center"} spacing={1}>
        {dataKeys.map((key, index) => (
          <Grid key={index} item>
            <Button
              sx={{
                borderRadius: 6,
              }}
              onClick={() => {
                if (filter.includes(key)) {
                  setFilter(filter.filter((value) => value !== key));
                } else {
                  if (filter.length === dataKeys.length - 1) {
                    setFilter([]);
                  } else {
                    setFilter([...filter, key]);
                  }
                }
              }}
            >
              <Grid
                container
                direction={"row"}
                alignItems={"center"}
                spacing={0.3}
              >
                <Grid item>
                  <Box
                    width={10}
                    height={10}
                    bgcolor={
                      filter.includes(key) || filter.length === 0
                        ? colorPalette[index]
                        : "lightgrey"
                    }
                    borderRadius={5}
                  />
                </Grid>
                <Grid item>
                  <CustomText
                    fontcolor={
                      filter.includes(key) || filter.length === 0
                        ? undefined
                        : "lightgray"
                    }
                    component={"p"}
                    type="helper"
                    label={key}
                  />
                </Grid>
              </Grid>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
