import dayjs, { Dayjs } from "dayjs";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  CalendarPicker as MuiCalendar,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { useState } from "react";
import { emerald, transparentEmerald } from "src/design/ColorPalette";

const lightTheme = createTheme({ palette: { mode: "light" } });
const initialValue = dayjs();

const isBetween = (date: Dayjs, start: Dayjs, end: Dayjs) =>
  date.isAfter(start) && date.isBefore(end);

interface CustomPickerDayProps extends PickersDayProps<Dayjs> {
  isHovered: boolean;
  isSelected: boolean;
}

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "hovered" && prop !== "isSelected",
})<CustomPickerDayProps>(({ theme, isSelected, isHovered }) => ({
  ...(isSelected && {
    backgroundColor: emerald,
    color: theme.palette.primary.contrastText,
    "&:hover, &:focus": {
      backgroundColor: emerald,
    },
  }),
  ...(isHovered && {
    backgroundColor: transparentEmerald,
    "&:hover, &:focus": {
      backgroundColor: transparentEmerald,
    },
  }),
}));

function ServerDay({
  day,
  selected,
  onClick,
  onPointerEnter,
  onPointerLeave,
  isHovered,
}: {
  day: Dayjs;
  selected: boolean;
  onClick: (day: Dayjs) => void;
  onPointerEnter: (day: Dayjs) => void;
  onPointerLeave: () => void;
  isHovered: boolean;
}) {
  return (
    <CustomPickersDay
      key={day.toString()}
      onPointerEnter={() => onPointerEnter(day)}
      onPointerLeave={onPointerLeave}
      day={day}
      onDaySelect={() => onClick(day)}
      outsideCurrentMonth={false}
      isSelected={selected}
      isHovered={isHovered}
    />
  );
}

interface RangeCalendarProps {
  onRangeSelected: (start: Dayjs, end: Dayjs) => void;
}

export const RangeCalendar = ({ onRangeSelected }: RangeCalendarProps) => {
  const [startDay, setStartDay] = useState<Dayjs | null>(null);
  const [endDay, setEndDay] = useState<Dayjs | null>(null);
  const [hoveredDay, setHoveredDay] = useState<Dayjs | null>(null);

  const onPointerEnter = (day: Dayjs) => {
    setHoveredDay(day);
  };

  const onPointerLeave = () => {
    setHoveredDay(null);
  };

  const isHovered = (day: Dayjs) => {
    if (!startDay) return day.isSame(hoveredDay);

    if (endDay) {
      return (
        isBetween(day, startDay, endDay) || isBetween(day, endDay, startDay)
      );
    } else {
      return hoveredDay
        ? day.isSame(hoveredDay) ||
            isBetween(day, startDay, hoveredDay) ||
            isBetween(day, hoveredDay, startDay)
        : false;
    }
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <MuiCalendar
          date={initialValue}
          onChange={() => {}}
          renderDay={(day) => (
            <ServerDay
              day={day}
              selected={day.isSame(startDay) || day.isSame(endDay)}
              onClick={() => {
                if (!startDay) {
                  setStartDay(day);
                } else if (!endDay) {
                  setEndDay(day);
                  if (startDay.isBefore(day)) {
                    onRangeSelected(startDay, day);
                  } else {
                    onRangeSelected(day, startDay);
                  }
                } else {
                  setStartDay(day);
                  setEndDay(null);
                }
              }}
              onPointerEnter={onPointerEnter}
              onPointerLeave={onPointerLeave}
              isHovered={isHovered(day)}
            />
          )}
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
};
