export const PhoneCodes = [{"country":"Afghanistan","code":"93","iso":"AF"},
{"country":"Albania","code":"355","iso":"AL"},
{"country":"Algeria","code":"213","iso":"DZ"},
{"country":"American Samoa","code":"1-684","iso":"AS"},
{"country":"Andorra","code":"376","iso":"AD"},
{"country":"Angola","code":"244","iso":"AO"},
{"country":"Anguilla","code":"1-264","iso":"AI"},
{"country":"Antarctica","code":"672","iso":"AQ"},
{"country":"Antigua and Barbuda","code":"1-268","iso":"AG"},
{"country":"Argentina","code":"54","iso":"AR"},
{"country":"Armenia","code":"374","iso":"AM"},
{"country":"Aruba","code":"297","iso":"AW"},
{"country":"Australia","code":"61","iso":"AU"},
{"country":"Austria","code":"43","iso":"AT"},
{"country":"Azerbaijan","code":"994","iso":"AZ"},
{"country":"Bahamas","code":"1-242","iso":"BS"},
{"country":"Bahrain","code":"973","iso":"BH"},
{"country":"Bangladesh","code":"880","iso":"BD"},
{"country":"Barbados","code":"1-246","iso":"BB"},
{"country":"Belarus","code":"375","iso":"BY"},
{"country":"Belgium","code":"32","iso":"BE"},
{"country":"Belize","code":"501","iso":"BZ"},
{"country":"Benin","code":"229","iso":"BJ"},
{"country":"Bermuda","code":"1-441","iso":"BM"},
{"country":"Bhutan","code":"975","iso":"BT"},
{"country":"Bolivia","code":"591","iso":"BO"},
{"country":"Bosnia and Herzegovina","code":"387","iso":"BA"},
{"country":"Botswana","code":"267","iso":"BW"},
{"country":"Brazil","code":"55","iso":"BR"},
{"country":"British Indian Ocean Territory","code":"246","iso":"IO"},
{"country":"British Virgin Islands","code":"1-284","iso":"VG"},
{"country":"Brunei","code":"673","iso":"BN"},
{"country":"Bulgaria","code":"359","iso":"BG"},
{"country":"Burkina Faso","code":"226","iso":"BF"},
{"country":"Burundi","code":"257","iso":"BI"},
{"country":"Cambodia","code":"855","iso":"KH"},
{"country":"Cameroon","code":"237","iso":"CM"},
{"country":"Canada","code":"1","iso":"CA"},
{"country":"Cape Verde","code":"238","iso":"CV"},
{"country":"Cayman Islands","code":"1-345","iso":"KY"},
{"country":"Central African Republic","code":"236","iso":"CF"},
{"country":"Chad","code":"235","iso":"TD"},
{"country":"Chile","code":"56","iso":"CL"},
{"country":"China","code":"86","iso":"CN"},
{"country":"Christmas Island","code":"61","iso":"CX"},
{"country":"Cocos Islands","code":"61","iso":"CC"},
{"country":"Colombia","code":"57","iso":"CO"},
{"country":"Comoros","code":"269","iso":"KM"},
{"country":"Cook Islands","code":"682","iso":"CK"},
{"country":"Costa Rica","code":"506","iso":"CR"},
{"country":"Croatia","code":"385","iso":"HR"},
{"country":"Cuba","code":"53","iso":"CU"},
{"country":"Curacao","code":"599","iso":"CW"},
{"country":"Cyprus","code":"357","iso":"CY"},
{"country":"Czech Republic","code":"420","iso":"CZ"},
{"country":"Democratic Republic of the Congo","code":"243","iso":"CD"},
{"country":"Denmark","code":"45","iso":"DK"},
{"country":"Djibouti","code":"253","iso":"DJ"},
{"country":"Dominica","code":"1-767","iso":"DM"},
{"country":"Dominican Republic","code":"1-809, 1-829, 1-849","iso":"DO"},
{"country":"East Timor","code":"670","iso":"TL"},
{"country":"Ecuador","code":"593","iso":"EC"},
{"country":"Egypt","code":"20","iso":"EG"},
{"country":"El Salvador","code":"503","iso":"SV"},
{"country":"Equatorial Guinea","code":"240","iso":"GQ"},
{"country":"Eritrea","code":"291","iso":"ER"},
{"country":"Estonia","code":"372","iso":"EE"},
{"country":"Ethiopia","code":"251","iso":"ET"},
{"country":"Falkland Islands","code":"500","iso":"FK"},
{"country":"Faroe Islands","code":"298","iso":"FO"},
{"country":"Fiji","code":"679","iso":"FJ"},
{"country":"Finland","code":"358","iso":"FI"},
{"country":"France","code":"33","iso":"FR"},
{"country":"French Polynesia","code":"689","iso":"PF"},
{"country":"Gabon","code":"241","iso":"GA"},
{"country":"Gambia","code":"220","iso":"GM"},
{"country":"Georgia","code":"995","iso":"GE"},
{"country":"Germany","code":"49","iso":"DE"},
{"country":"Ghana","code":"233","iso":"GH"},
{"country":"Gibraltar","code":"350","iso":"GI"},
{"country":"Greece","code":"30","iso":"GR"},
{"country":"Greenland","code":"299","iso":"GL"},
{"country":"Grenada","code":"1-473","iso":"GD"},
{"country":"Guam","code":"1-671","iso":"GU"},
{"country":"Guatemala","code":"502","iso":"GT"},
{"country":"Guernsey","code":"44-1481","iso":"GG"},
{"country":"Guinea","code":"224","iso":"GN"},
{"country":"Guinea-Bissau","code":"245","iso":"GW"},
{"country":"Guyana","code":"592","iso":"GY"},
{"country":"Haiti","code":"509","iso":"HT"},
{"country":"Honduras","code":"504","iso":"HN"},
{"country":"Hong Kong","code":"852","iso":"HK"},
{"country":"Hungary","code":"36","iso":"HU"},
{"country":"Iceland","code":"354","iso":"IS"},
{"country":"India","code":"91","iso":"IN"},
{"country":"Indonesia","code":"62","iso":"ID"},
{"country":"Iran","code":"98","iso":"IR"},
{"country":"Iraq","code":"964","iso":"IQ"},
{"country":"Ireland","code":"353","iso":"IE"},
{"country":"Isle of Man","code":"44-1624","iso":"IM"},
{"country":"Israel","code":"972","iso":"IL"},
{"country":"Italy","code":"39","iso":"IT"},
{"country":"Ivory Coast","code":"225","iso":"CI"},
{"country":"Jamaica","code":"1-876","iso":"JM"},
{"country":"Japan","code":"81","iso":"JP"},
{"country":"Jersey","code":"44-1534","iso":"JE"},
{"country":"Jordan","code":"962","iso":"JO"},
{"country":"Kazakhstan","code":"7","iso":"KZ"},
{"country":"Kenya","code":"254","iso":"KE"},
{"country":"Kiribati","code":"686","iso":"KI"},
{"country":"Kosovo","code":"383","iso":"XK"},
{"country":"Kuwait","code":"965","iso":"KW"},
{"country":"Kyrgyzstan","code":"996","iso":"KG"},
{"country":"Laos","code":"856","iso":"LA"},
{"country":"Latvia","code":"371","iso":"LV"},
{"country":"Lebanon","code":"961","iso":"LB"},
{"country":"Lesotho","code":"266","iso":"LS"},
{"country":"Liberia","code":"231","iso":"LR"},
{"country":"Libya","code":"218","iso":"LY"},
{"country":"Liechtenstein","code":"423","iso":"LI"},
{"country":"Lithuania","code":"370","iso":"LT"},
{"country":"Luxembourg","code":"352","iso":"LU"},
{"country":"Macao","code":"853","iso":"MO"},
{"country":"Macedonia","code":"389","iso":"MK"},
{"country":"Madagascar","code":"261","iso":"MG"},
{"country":"Malawi","code":"265","iso":"MW"},
{"country":"Malaysia","code":"60","iso":"MY"},
{"country":"Maldives","code":"960","iso":"MV"},
{"country":"Mali","code":"223","iso":"ML"},
{"country":"Malta","code":"356","iso":"MT"},
{"country":"Marshall Islands","code":"692","iso":"MH"},
{"country":"Mauritania","code":"222","iso":"MR"},
{"country":"Mauritius","code":"230","iso":"MU"},
{"country":"Mayotte","code":"262","iso":"YT"},
{"country":"Mexico","code":"52","iso":"MX"},
{"country":"Micronesia","code":"691","iso":"FM"},
{"country":"Moldova","code":"373","iso":"MD"},
{"country":"Monaco","code":"377","iso":"MC"},
{"country":"Mongolia","code":"976","iso":"MN"},
{"country":"Montenegro","code":"382","iso":"ME"},
{"country":"Montserrat","code":"1-664","iso":"MS"},
{"country":"Morocco","code":"212","iso":"MA"},
{"country":"Mozambique","code":"258","iso":"MZ"},
{"country":"Myanmar","code":"95","iso":"MM"},
{"country":"Namibia","code":"264","iso":"NA"},
{"country":"Nauru","code":"674","iso":"NR"},
{"country":"Nepal","code":"977","iso":"NP"},
{"country":"Netherlands","code":"31","iso":"NL"},
{"country":"Netherlands Antilles","code":"599","iso":"AN"},
{"country":"New Caledonia","code":"687","iso":"NC"},
{"country":"New Zealand","code":"64","iso":"NZ"},
{"country":"Nicaragua","code":"505","iso":"NI"},
{"country":"Niger","code":"227","iso":"NE"},
{"country":"Nigeria","code":"234","iso":"NG"},
{"country":"Niue","code":"683","iso":"NU"},
{"country":"North Korea","code":"850","iso":"KP"},
{"country":"Northern Mariana Islands","code":"1-670","iso":"MP"},
{"country":"Norway","code":"47","iso":"NO"},
{"country":"Oman","code":"968","iso":"OM"},
{"country":"Pakistan","code":"92","iso":"PK"},
{"country":"Palau","code":"680","iso":"PW"},
{"country":"Palestine","code":"970","iso":"PS"},
{"country":"Panama","code":"507","iso":"PA"},
{"country":"Papua New Guinea","code":"675","iso":"PG"},
{"country":"Paraguay","code":"595","iso":"PY"},
{"country":"Peru","code":"51","iso":"PE"},
{"country":"Philippines","code":"63","iso":"PH"},
{"country":"Pitcairn","code":"64","iso":"PN"},
{"country":"Poland","code":"48","iso":"PL"},
{"country":"Portugal","code":"351","iso":"PT"},
{"country":"Puerto Rico","code":"1-787, 1-939","iso":"PR"},
{"country":"Qatar","code":"974","iso":"QA"},
{"country":"Republic of the Congo","code":"242","iso":"CG"},
{"country":"Reunion","code":"262","iso":"RE"},
{"country":"Romania","code":"40","iso":"RO"},
{"country":"Russia","code":"7","iso":"RU"},
{"country":"Rwanda","code":"250","iso":"RW"},
{"country":"Saint Barthelemy","code":"590","iso":"BL"},
{"country":"Saint Helena","code":"290","iso":"SH"},
{"country":"Saint Kitts and Nevis","code":"1-869","iso":"KN"},
{"country":"Saint Lucia","code":"1-758","iso":"LC"},
{"country":"Saint Martin","code":"590","iso":"MF"},
{"country":"Saint Pierre and Miquelon","code":"508","iso":"PM"},
{"country":"Saint Vincent and the Grenadines","code":"1-784","iso":"VC"},
{"country":"Samoa","code":"685","iso":"WS"},
{"country":"San Marino","code":"378","iso":"SM"},
{"country":"Sao Tome and Principe","code":"239","iso":"ST"},
{"country":"Saudi Arabia","code":"966","iso":"SA"},
{"country":"Senegal","code":"221","iso":"SN"},
{"country":"Serbia","code":"381","iso":"RS"},
{"country":"Seychelles","code":"248","iso":"SC"},
{"country":"Sierra Leone","code":"232","iso":"SL"},
{"country":"Singapore","code":"65","iso":"SG"},
{"country":"Sint Maarten","code":"1-721","iso":"SX"},
{"country":"Slovakia","code":"421","iso":"SK"},
{"country":"Slovenia","code":"386","iso":"SI"},
{"country":"Solomon Islands","code":"677","iso":"SB"},
{"country":"Somalia","code":"252","iso":"SO"},
{"country":"South Africa","code":"27","iso":"ZA"},
{"country":"South Korea","code":"82","iso":"KR"},
{"country":"South Sudan","code":"211","iso":"SS"},
{"country":"Spain","code":"34","iso":"ES"},
{"country":"Sri Lanka","code":"94","iso":"LK"},
{"country":"Sudan","code":"249","iso":"SD"},
{"country":"Suriname","code":"597","iso":"SR"},
{"country":"Svalbard and Jan Mayen","code":"47","iso":"SJ"},
{"country":"Swaziland","code":"268","iso":"SZ"},
{"country":"Sweden","code":"46","iso":"SE"},
{"country":"Switzerland","code":"41","iso":"CH"},
{"country":"Syria","code":"963","iso":"SY"},
{"country":"Taiwan","code":"886","iso":"TW"},
{"country":"Tajikistan","code":"992","iso":"TJ"},
{"country":"Tanzania","code":"255","iso":"TZ"},
{"country":"Thailand","code":"66","iso":"TH"},
{"country":"Togo","code":"228","iso":"TG"},
{"country":"Tokelau","code":"690","iso":"TK"},
{"country":"Tonga","code":"676","iso":"TO"},
{"country":"Trinidad and Tobago","code":"1-868","iso":"TT"},
{"country":"Tunisia","code":"216","iso":"TN"},
{"country":"Turkey","code":"90","iso":"TR"},
{"country":"Turkmenistan","code":"993","iso":"TM"},
{"country":"Turks and Caicos Islands","code":"1-649","iso":"TC"},
{"country":"Tuvalu","code":"688","iso":"TV"},
{"country":"U.S. Virgin Islands","code":"1-340","iso":"VI"},
{"country":"Uganda","code":"256","iso":"UG"},
{"country":"Ukraine","code":"380","iso":"UA"},
{"country":"United Arab Emirates","code":"971","iso":"AE"},
{"country":"United Kingdom","code":"44","iso":"GB"},
{"country":"United States","code":"1","iso":"US"},
{"country":"Uruguay","code":"598","iso":"UY"},
{"country":"Uzbekistan","code":"998","iso":"UZ"},
{"country":"Vanuatu","code":"678","iso":"VU"},
{"country":"Vatican","code":"379","iso":"VA"},
{"country":"Venezuela","code":"58","iso":"VE"},
{"country":"Vietnam","code":"84","iso":"VN"},
{"country":"Wallis and Futuna","code":"681","iso":"WF"},
{"country":"Western Sahara","code":"212","iso":"EH"},
{"country":"Yemen","code":"967","iso":"YE"},
{"country":"Zambia","code":"260","iso":"ZM"},
{"country":"Zimbabwe","code":"263","iso":"ZW"}];

export  const Cuisine = [
    {"name": 'french'},
    {"name": 'chinese'},
    {"name": 'japanese'},
    {"name": 'indian'},
    {"name": 'italian'},
    {"name": 'greek'},
    {"name": 'spanish'},
    {"name": 'mediterranean'},
    {"name": 'lebanese'},
    {"name": 'moroccan'},
    {"name": 'turkish'},
    {"name": 'thai'}
]

export const  Cities = [
	{
		"city": "Ad Dakhla",
		"lat": "23.7141",
		"lng": "-15.9368",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "",
		"capital": "",
		"population": "89292",
		"population_proper": "75000"
	},
	{
		"city": "Ad Darwa",
		"lat": "33.4167",
		"lng": "-7.5333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "47719",
		"population_proper": "47719"
	},
	{
		"city": "Agadir",
		"lat": "30.4167",
		"lng": "-9.5833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "admin",
		"population": "421844",
		"population_proper": "421844"
	},
	{
		"city": "Aguelmous",
		"lat": "33.1500",
		"lng": "-5.8333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "35626",
		"population_proper": "35626"
	},
	{
		"city": "Ain Aicha",
		"lat": "34.4833",
		"lng": "-4.7000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "25417",
		"population_proper": "25417"
	},
	{
		"city": "Ain El Aouda",
		"lat": "33.8111",
		"lng": "-6.7922",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "49816",
		"population_proper": "49816"
	},
	{
		"city": "Ait Ali",
		"lat": "30.1739",
		"lng": "-9.4881",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "76646",
		"population_proper": "76646"
	},
	{
		"city": "Ait Melloul",
		"lat": "30.3342",
		"lng": "-9.4972",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "171847",
		"population_proper": "171847"
	},
	{
		"city": "Ait Ourir",
		"lat": "31.5644",
		"lng": "-7.6628",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "39108",
		"population_proper": "39108"
	},
	{
		"city": "Al Aaroui",
		"lat": "35.0104",
		"lng": "-3.0073",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "47599",
		"population_proper": "47599"
	},
	{
		"city": "Al Fqih Ben Çalah",
		"lat": "32.5000",
		"lng": "-6.7000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "102019",
		"population_proper": "102019"
	},
	{
		"city": "Al Hoceïma",
		"lat": "35.2472",
		"lng": "-3.9322",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "56716",
		"population_proper": "56716"
	},
	{
		"city": "Al Khmissat",
		"lat": "33.8100",
		"lng": "-6.0600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "131542",
		"population_proper": "131542"
	},
	{
		"city": "Aourir",
		"lat": "30.4833",
		"lng": "-9.6333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "36948",
		"population_proper": "36948"
	},
	{
		"city": "Arbaoua",
		"lat": "34.9000",
		"lng": "-5.9167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "32690",
		"population_proper": "32690"
	},
	{
		"city": "Arfoud",
		"lat": "31.4361",
		"lng": "-4.2328",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "29279",
		"population_proper": "29279"
	},
	{
		"city": "Asilah",
		"lat": "35.4667",
		"lng": "-6.0333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "31147",
		"population_proper": "31147"
	},
	{
		"city": "Ayt Mohamed",
		"lat": "32.5667",
		"lng": "-6.9833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "23696",
		"population_proper": "23696"
	},
	{
		"city": "Azemmour",
		"lat": "33.2833",
		"lng": "-8.3333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "40920",
		"population_proper": "40920"
	},
	{
		"city": "Aziylal",
		"lat": "31.9600",
		"lng": "-6.5600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "38520",
		"population_proper": "38520"
	},
	{
		"city": "Azrou",
		"lat": "33.4300",
		"lng": "-5.2100",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "54350",
		"population_proper": "54350"
	},
	{
		"city": "Aïn Harrouda",
		"lat": "33.6372",
		"lng": "-7.4483",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "62420",
		"population_proper": "62420"
	},
	{
		"city": "Aïn Taoujdat",
		"lat": "33.9333",
		"lng": "-5.2167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "28288",
		"population_proper": "28288"
	},
	{
		"city": "Aït Faska",
		"lat": "31.5058",
		"lng": "-7.7161",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "26210",
		"population_proper": "26210"
	},
	{
		"city": "Bahharet Oulad Ayyad",
		"lat": "34.7703",
		"lng": "-6.3047",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "31860",
		"population_proper": "31860"
	},
	{
		"city": "Barrechid",
		"lat": "33.2700",
		"lng": "-7.5872",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "136634",
		"population_proper": "136634"
	},
	{
		"city": "Bellaa",
		"lat": "30.0314",
		"lng": "-9.5542",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "27699",
		"population_proper": "27699"
	},
	{
		"city": "Ben Guerir",
		"lat": "32.2300",
		"lng": "-7.9500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "88626",
		"population_proper": "88626"
	},
	{
		"city": "Beni Enzar",
		"lat": "35.2569",
		"lng": "-2.9342",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "56582",
		"population_proper": "56582"
	},
	{
		"city": "Beni Yakhlef",
		"lat": "33.6681",
		"lng": "-7.2514",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "48338",
		"population_proper": "48338"
	},
	{
		"city": "Benslimane",
		"lat": "33.6122",
		"lng": "-7.1211",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "57101",
		"population_proper": "57101"
	},
	{
		"city": "Berkane",
		"lat": "34.9167",
		"lng": "-2.3167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "109237",
		"population_proper": "109237"
	},
	{
		"city": "Biougra",
		"lat": "30.2144",
		"lng": "-9.3708",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "37933",
		"population_proper": "37933"
	},
	{
		"city": "Bni Frassen",
		"lat": "34.3819",
		"lng": "-4.3761",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "23429",
		"population_proper": "23429"
	},
	{
		"city": "Bou Ahmed",
		"lat": "33.1119",
		"lng": "-7.4058",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "33105",
		"population_proper": "33105"
	},
	{
		"city": "Bou Arfa",
		"lat": "32.5310",
		"lng": "-1.9631",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "28846",
		"population_proper": "28846"
	},
	{
		"city": "Boujad",
		"lat": "32.7600",
		"lng": "-6.4000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "46893",
		"population_proper": "46893"
	},
	{
		"city": "Bouknadel",
		"lat": "34.1245",
		"lng": "-6.7480",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "25255",
		"population_proper": "25255"
	},
	{
		"city": "Bourdoud",
		"lat": "34.5922",
		"lng": "-4.5492",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "26236",
		"population_proper": "26236"
	},
	{
		"city": "Boureït",
		"lat": "34.9833",
		"lng": "-4.9167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "25872",
		"population_proper": "25872"
	},
	{
		"city": "Bouskoura",
		"lat": "33.4489",
		"lng": "-7.6486",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "103026",
		"population_proper": "103026"
	},
	{
		"city": "Bouznika",
		"lat": "33.7894",
		"lng": "-7.1597",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "37238",
		"population_proper": "37238"
	},
	{
		"city": "Béni Mellal",
		"lat": "32.3394",
		"lng": "-6.3608",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "admin",
		"population": "192676",
		"population_proper": "192676"
	},
	{
		"city": "Casablanca",
		"lat": "33.5992",
		"lng": "-7.6200",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "admin",
		"population": "4370000",
		"population_proper": "4370000"
	},
	{
		"city": "Chefchaouene",
		"lat": "35.1714",
		"lng": "-5.2697",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "42786",
		"population_proper": "42786"
	},
	{
		"city": "Chichaoua",
		"lat": "31.5333",
		"lng": "-8.7667",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "27869",
		"population_proper": "27869"
	},
	{
		"city": "Dar Bel Hamri",
		"lat": "34.1889",
		"lng": "-5.9697",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "28156",
		"population_proper": "28156"
	},
	{
		"city": "Dar Ould Zidouh",
		"lat": "32.3167",
		"lng": "-6.9000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "31170",
		"population_proper": "31170"
	},
	{
		"city": "Demnat",
		"lat": "31.7311",
		"lng": "-7.0361",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "29504",
		"population_proper": "29504"
	},
	{
		"city": "Douar Olad. Salem",
		"lat": "32.8739",
		"lng": "-8.8589",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "24775",
		"population_proper": "24775"
	},
	{
		"city": "Douar Oulad Aj-jabri",
		"lat": "32.2567",
		"lng": "-6.7839",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "28438",
		"population_proper": "28438"
	},
	{
		"city": "Douar Oulad Hssine",
		"lat": "33.0681",
		"lng": "-8.5108",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "32130",
		"population_proper": "32130"
	},
	{
		"city": "Douar Ouled Ayad",
		"lat": "32.4167",
		"lng": "-7.1000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "23818",
		"population_proper": "23818"
	},
	{
		"city": "Douar ’Ayn Dfali",
		"lat": "33.9500",
		"lng": "-4.4500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "24241",
		"population_proper": "24241"
	},
	{
		"city": "Echemmaia Est",
		"lat": "32.0705",
		"lng": "-8.6532",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "24303",
		"population_proper": "24303"
	},
	{
		"city": "El Aïoun",
		"lat": "34.5853",
		"lng": "-2.5056",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "41832",
		"population_proper": "41832"
	},
	{
		"city": "El Ghiate",
		"lat": "32.0331",
		"lng": "-9.1625",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "25162",
		"population_proper": "25162"
	},
	{
		"city": "El Hajeb",
		"lat": "33.6928",
		"lng": "-5.3711",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "35282",
		"population_proper": "35282"
	},
	{
		"city": "El Jadid",
		"lat": "33.2566",
		"lng": "-8.5025",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "180470",
		"population_proper": "147549"
	},
	{
		"city": "El Kelaa des Srarhna",
		"lat": "32.0500",
		"lng": "-7.4000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "1450021",
		"population_proper": "1450021"
	},
	{
		"city": "Errachidia",
		"lat": "31.9319",
		"lng": "-4.4244",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "admin",
		"population": "92374",
		"population_proper": "92374"
	},
	{
		"city": "Essaouira",
		"lat": "31.5130",
		"lng": "-9.7687",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "77966",
		"population_proper": "77966"
	},
	{
		"city": "Fnidq",
		"lat": "35.8500",
		"lng": "-5.3500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "77436",
		"population_proper": "77436"
	},
	{
		"city": "Fritissa",
		"lat": "33.6167",
		"lng": "-3.5500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "29460",
		"population_proper": "29460"
	},
	{
		"city": "Fès",
		"lat": "34.0433",
		"lng": "-5.0033",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "admin",
		"population": "1112072",
		"population_proper": "1112072"
	},
	{
		"city": "Guelmim",
		"lat": "28.9833",
		"lng": "-10.0667",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Guelmim-Oued Noun",
		"capital": "admin",
		"population": "118318",
		"population_proper": "118318"
	},
	{
		"city": "Guercif",
		"lat": "34.2300",
		"lng": "-3.3600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "90880",
		"population_proper": "90880"
	},
	{
		"city": "Gueznaia",
		"lat": "35.7200",
		"lng": "-5.8940",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "23601",
		"population_proper": "23601"
	},
	{
		"city": "Imzouren",
		"lat": "35.1448",
		"lng": "-3.8505",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "33852",
		"population_proper": "33852"
	},
	{
		"city": "Inezgane",
		"lat": "30.3658",
		"lng": "-9.5381",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "130333",
		"population_proper": "130333"
	},
	{
		"city": "Jerada",
		"lat": "34.3100",
		"lng": "-2.1600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "43506",
		"population_proper": "43506"
	},
	{
		"city": "Kenitra",
		"lat": "34.2500",
		"lng": "-6.5833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "431282",
		"population_proper": "431282"
	},
	{
		"city": "Khenichet-sur Ouerrha",
		"lat": "34.4383",
		"lng": "-5.6844",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "23707",
		"population_proper": "23707"
	},
	{
		"city": "Khénifra",
		"lat": "32.9300",
		"lng": "-5.6600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "117510",
		"population_proper": "117510"
	},
	{
		"city": "Kouribga",
		"lat": "32.8800",
		"lng": "-6.9000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "196196",
		"population_proper": "196196"
	},
	{
		"city": "Ksar El Kebir",
		"lat": "35.0000",
		"lng": "-5.9000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "126617",
		"population_proper": "126617"
	},
	{
		"city": "Ksebia",
		"lat": "34.2933",
		"lng": "-6.1594",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "27059",
		"population_proper": "27059"
	},
	{
		"city": "Lalla Mimouna",
		"lat": "34.8500",
		"lng": "-6.0669",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "29479",
		"population_proper": "29479"
	},
	{
		"city": "Lamzoudia",
		"lat": "31.5833",
		"lng": "-8.4833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "25674",
		"population_proper": "25674"
	},
	{
		"city": "Laouamra",
		"lat": "35.0656",
		"lng": "-6.0939",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "40605",
		"population_proper": "40605"
	},
	{
		"city": "Larache",
		"lat": "35.1833",
		"lng": "-6.1500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "125008",
		"population_proper": "125008"
	},
	{
		"city": "Laâyoune",
		"lat": "27.1500",
		"lng": "-13.2000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "",
		"capital": "",
		"population": "217732",
		"population_proper": "217732"
	},
	{
		"city": "Loudaya",
		"lat": "31.6507",
		"lng": "-8.3021",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "33767",
		"population_proper": "33767"
	},
	{
		"city": "Lqoliaa",
		"lat": "30.2942",
		"lng": "-9.4544",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "83235",
		"population_proper": "83235"
	},
	{
		"city": "Marrakech",
		"lat": "31.6295",
		"lng": "-7.9811",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "admin",
		"population": "928850",
		"population_proper": "928850"
	},
	{
		"city": "Martil",
		"lat": "35.6100",
		"lng": "-5.2700",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "64355",
		"population_proper": "64355"
	},
	{
		"city": "Mechraa Bel Ksiri",
		"lat": "34.5787",
		"lng": "-5.9630",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "31497",
		"population_proper": "31497"
	},
	{
		"city": "Mediouna",
		"lat": "33.4500",
		"lng": "-7.5100",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "34250",
		"population_proper": "34250"
	},
	{
		"city": "Mehdya",
		"lat": "34.2557",
		"lng": "-6.6745",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "28636",
		"population_proper": "28636"
	},
	{
		"city": "Meknès",
		"lat": "33.8833",
		"lng": "-5.5500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "520428",
		"population_proper": "520428"
	},
	{
		"city": "Midalt",
		"lat": "32.6800",
		"lng": "-4.7400",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "55304",
		"population_proper": "55304"
	},
	{
		"city": "Missour",
		"lat": "33.0500",
		"lng": "-3.9908",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "25584",
		"population_proper": "25584"
	},
	{
		"city": "Mnasra",
		"lat": "34.7667",
		"lng": "-5.5167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "34429",
		"population_proper": "34429"
	},
	{
		"city": "Mograne",
		"lat": "34.4167",
		"lng": "-6.4333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "31292",
		"population_proper": "31292"
	},
	{
		"city": "Mohammedia",
		"lat": "33.6833",
		"lng": "-7.3833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "208612",
		"population_proper": "208612"
	},
	{
		"city": "Moulay Abdallah",
		"lat": "33.1978",
		"lng": "-8.5883",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "74671",
		"population_proper": "74671"
	},
	{
		"city": "Moulay Bousselham",
		"lat": "34.8786",
		"lng": "-6.2933",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "26608",
		"population_proper": "26608"
	},
	{
		"city": "Mrirt",
		"lat": "33.1667",
		"lng": "-5.5667",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "42730",
		"population_proper": "42730"
	},
	{
		"city": "M’diq",
		"lat": "35.6857",
		"lng": "-5.3251",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "56227",
		"population_proper": "56227"
	},
	{
		"city": "Nador",
		"lat": "35.1667",
		"lng": "-2.9333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "161726",
		"population_proper": "161726"
	},
	{
		"city": "Ouaoula",
		"lat": "31.8667",
		"lng": "-6.7500",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "24790",
		"population_proper": "24790"
	},
	{
		"city": "Oued Zem",
		"lat": "32.8600",
		"lng": "-6.5600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "95267",
		"population_proper": "95267"
	},
	{
		"city": "Ouezzane",
		"lat": "34.8000",
		"lng": "-5.6000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "59606",
		"population_proper": "59606"
	},
	{
		"city": "Oujda-Angad",
		"lat": "34.6900",
		"lng": "-1.9100",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "admin",
		"population": "409391",
		"population_proper": "405253"
	},
	{
		"city": "Oulad Barhil",
		"lat": "30.6388",
		"lng": "-8.4732",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "24288",
		"population_proper": "24288"
	},
	{
		"city": "Oulad Hammou",
		"lat": "33.2500",
		"lng": "-8.3347",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "28449",
		"population_proper": "28449"
	},
	{
		"city": "Oulad Hassoune",
		"lat": "31.6503",
		"lng": "-7.8361",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "23475",
		"population_proper": "23475"
	},
	{
		"city": "Oulad Said",
		"lat": "32.6320",
		"lng": "-8.8456",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "25650",
		"population_proper": "25650"
	},
	{
		"city": "Oulad Tayeb",
		"lat": "33.9598",
		"lng": "-4.9954",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "24594",
		"population_proper": "24594"
	},
	{
		"city": "Oulad Teïma",
		"lat": "30.4000",
		"lng": "-9.2100",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "89387",
		"population_proper": "89387"
	},
	{
		"city": "Oulad Yaïch",
		"lat": "32.4167",
		"lng": "-6.3333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "27476",
		"population_proper": "27476"
	},
	{
		"city": "Oulad Zemam",
		"lat": "32.3500",
		"lng": "-6.6333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "33652",
		"population_proper": "33652"
	},
	{
		"city": "Qasbat Tadla",
		"lat": "32.6000",
		"lng": "-6.2600",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "47343",
		"population_proper": "47343"
	},
	{
		"city": "Rabat",
		"lat": "34.0253",
		"lng": "-6.8361",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "primary",
		"population": "572717",
		"population_proper": "572717"
	},
	{
		"city": "Sabaa Aiyoun",
		"lat": "33.8969",
		"lng": "-5.3611",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "26277",
		"population_proper": "26277"
	},
	{
		"city": "Safi",
		"lat": "32.2833",
		"lng": "-9.2333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "308508",
		"population_proper": "308508"
	},
	{
		"city": "Safsaf",
		"lat": "34.5581",
		"lng": "-6.0078",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "25075",
		"population_proper": "25075"
	},
	{
		"city": "Sale",
		"lat": "34.0500",
		"lng": "-6.8167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "850403",
		"population_proper": "850403"
	},
	{
		"city": "Sa’ada",
		"lat": "31.6258",
		"lng": "-8.1028",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "67086",
		"population_proper": "67086"
	},
	{
		"city": "Sefrou",
		"lat": "33.8300",
		"lng": "-4.8300",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "79887",
		"population_proper": "79887"
	},
	{
		"city": "Semara",
		"lat": "26.7333",
		"lng": "-11.6833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "",
		"capital": "",
		"population": "48149",
		"population_proper": "48149"
	},
	{
		"city": "Settat",
		"lat": "33.0023",
		"lng": "-7.6198",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "142250",
		"population_proper": "142250"
	},
	{
		"city": "Setti Fatma",
		"lat": "31.2256",
		"lng": "-7.6758",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "24129",
		"population_proper": "24129"
	},
	{
		"city": "Sidi Bennour",
		"lat": "32.6550",
		"lng": "-8.4292",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "55815",
		"population_proper": "55815"
	},
	{
		"city": "Sidi Bibi",
		"lat": "30.2333",
		"lng": "-9.5333",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "39042",
		"population_proper": "39042"
	},
	{
		"city": "Sidi Mohamed Lahmar",
		"lat": "34.7167",
		"lng": "-6.2667",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "42637",
		"population_proper": "42637"
	},
	{
		"city": "Sidi Qacem",
		"lat": "34.2100",
		"lng": "-5.7000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "75672",
		"population_proper": "75672"
	},
	{
		"city": "Sidi Slimane",
		"lat": "34.2600",
		"lng": "-5.9300",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "92989",
		"population_proper": "92989"
	},
	{
		"city": "Sidi Smai’il",
		"lat": "32.8167",
		"lng": "-8.5000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "28733",
		"population_proper": "28733"
	},
	{
		"city": "Sidi Yahia El Gharb",
		"lat": "34.3058",
		"lng": "-6.3058",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "37979",
		"population_proper": "37979"
	},
	{
		"city": "Sidi Yahya Zaer",
		"lat": "33.8261",
		"lng": "-6.9039",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "57790",
		"population_proper": "57790"
	},
	{
		"city": "Skhirate",
		"lat": "33.8500",
		"lng": "-7.0300",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "59775",
		"population_proper": "59775"
	},
	{
		"city": "Skoura",
		"lat": "31.0672",
		"lng": "-6.5397",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "24055",
		"population_proper": "24055"
	},
	{
		"city": "Souk et Tnine Jorf el Mellah",
		"lat": "34.4833",
		"lng": "-5.5169",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "28681",
		"population_proper": "28681"
	},
	{
		"city": "Tahla",
		"lat": "34.0476",
		"lng": "-4.4289",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "27729",
		"population_proper": "27729"
	},
	{
		"city": "Tameslouht",
		"lat": "31.5000",
		"lng": "-8.1000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "28978",
		"population_proper": "28978"
	},
	{
		"city": "Tamorot",
		"lat": "34.9333",
		"lng": "-4.7833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "26748",
		"population_proper": "26748"
	},
	{
		"city": "Tan-Tan",
		"lat": "28.4333",
		"lng": "-11.1000",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Guelmim-Oued Noun",
		"capital": "",
		"population": "73209",
		"population_proper": "73209"
	},
	{
		"city": "Tangier",
		"lat": "35.7767",
		"lng": "-5.8039",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "admin",
		"population": "947952",
		"population_proper": "947952"
	},
	{
		"city": "Taounate",
		"lat": "34.5358",
		"lng": "-4.6400",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "37616",
		"population_proper": "37616"
	},
	{
		"city": "Taourirt",
		"lat": "34.4100",
		"lng": "-2.8900",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "321876",
		"population_proper": "321876"
	},
	{
		"city": "Taroudannt",
		"lat": "30.4711",
		"lng": "-8.8778",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "80149",
		"population_proper": "80149"
	},
	{
		"city": "Taza",
		"lat": "34.2144",
		"lng": "-4.0088",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Fès-Meknès",
		"capital": "",
		"population": "148456",
		"population_proper": "148456"
	},
	{
		"city": "Taza",
		"lat": "35.0639",
		"lng": "-5.2025",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "28713",
		"population_proper": "28713"
	},
	{
		"city": "Temara",
		"lat": "33.9234",
		"lng": "-6.9076",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "313510",
		"population_proper": "313510"
	},
	{
		"city": "Temsia",
		"lat": "30.3633",
		"lng": "-9.4144",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "40780",
		"population_proper": "40780"
	},
	{
		"city": "Tifariti",
		"lat": "26.0928",
		"lng": "-10.6089",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "",
		"capital": "primary",
		"population": "",
		"population_proper": ""
	},
	{
		"city": "Tiflet",
		"lat": "33.9000",
		"lng": "-6.3300",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Rabat-Salé-Kénitra",
		"capital": "",
		"population": "86709",
		"population_proper": "86709"
	},
	{
		"city": "Tineghir",
		"lat": "31.5147",
		"lng": "-5.5328",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "42044",
		"population_proper": "42044"
	},
	{
		"city": "Tit Mellil",
		"lat": "33.5581",
		"lng": "-7.4858",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "32782",
		"population_proper": "32782"
	},
	{
		"city": "Tiznit",
		"lat": "29.7000",
		"lng": "-9.7269",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "74699",
		"population_proper": "74699"
	},
	{
		"city": "Tétouan",
		"lat": "35.5667",
		"lng": "-5.3667",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "380787",
		"population_proper": "380787"
	},
	{
		"city": "Warzat",
		"lat": "30.9167",
		"lng": "-6.9167",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "71067",
		"population_proper": "71067"
	},
	{
		"city": "Wislane",
		"lat": "30.2167",
		"lng": "-8.3833",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "",
		"population": "87910",
		"population_proper": "87910"
	},
	{
		"city": "Youssoufia",
		"lat": "32.2500",
		"lng": "-8.5300",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Marrakech-Safi",
		"capital": "",
		"population": "67628",
		"population_proper": "67628"
	},
	{
		"city": "Zagora",
		"lat": "30.3316",
		"lng": "-5.8376",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Drâa-Tafilalet",
		"capital": "",
		"population": "40067",
		"population_proper": "40067"
	},
	{
		"city": "Zawit Al Bour",
		"lat": "30.6748",
		"lng": "-8.1742",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Souss-Massa",
		"capital": "minor",
		"population": "",
		"population_proper": ""
	},
	{
		"city": "Zawyat an Nwaçer",
		"lat": "33.3611",
		"lng": "-7.6114",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Casablanca-Settat",
		"capital": "",
		"population": "23802",
		"population_proper": "23802"
	},
	{
		"city": "Zawyat ech Cheïkh",
		"lat": "32.6541",
		"lng": "-5.9214",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Béni Mellal-Khénifra",
		"capital": "",
		"population": "25388",
		"population_proper": "25388"
	},
	{
		"city": "Zaïo",
		"lat": "34.9396",
		"lng": "-2.7334",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "35806",
		"population_proper": "35806"
	},
	{
		"city": "Zeghanghane",
		"lat": "35.1575",
		"lng": "-3.0017",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Oriental",
		"capital": "",
		"population": "34025",
		"population_proper": "34025"
	},
	{
		"city": "Zoumi",
		"lat": "34.8032",
		"lng": "-5.3446",
		"country": "Morocco",
		"iso2": "MA",
		"admin_name": "Tanger-Tétouan-Al Hoceïma",
		"capital": "",
		"population": "40661",
		"population_proper": "40661"
	}
]