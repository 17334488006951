export const fr = {
  translation: {
    common: {
      save: "Sauvegarder",
      cancel: "Annuler",
      email: "address email",
      submit: "Soumettre",
      tags: "Tags",
      phone: "N° de téléphone",
      days: {
        monday: "Lundi",
        tuesday: "Mardi",
        wednesday: "Mercredi",
        thursday: "Jeudi",
        friday: "Vendredi",
        saturday: "Samedi",
        sunday: "Dimanche",
      },
      months: {
        1: "Janvier",
        2: "Février",
        3: "Mars",
        4: "Avril",
        5: "Mai",
        6: "Juin",
        7: "Juillet",
        8: "Août",
        9: "Septembre",
        10: "Octobre",
        11: "Novembre",
        12: "Décembre",
      },
    },
    emailVerification: {
      body: "Votre compte est en cours de verification, veuillez patienter",
      crash: "Une erreur est survenu, veuillez ressayer plus tard",
      website: {
        success:
          "Votre compte a été vérifier avec succès, vous serez rediriger dans quelque secondes",
        error:
          "Votre token a malheureusement expiré, veuillez ressayer depuis l'écran de connexion",
      },
      mobile: {
        success:
          "Votre compte a été vérifier avec succès, vous pouvez vous connecter depuis l'application mobile",
        error:
          "Votre token a malheureusement expiré, veuillez ressayer depuis l'application mobile",
      },
    },
    homePage: {
      header: "Attirez plus de clients",
      secondHeader: "Augmentez votre fréquentation",
      subheader:
        "Avec L'addition,<strong> gérez les réservations</strong> de votre restaurant, <strong>proposez des offres</strong> aux clients et <strong>augmentez votre visibilité</strong> en ligne, le tout dans un seul environnement!",
      discoverCta: "Découvrir",
      comingSoon: "Bientôt disponible",
    },
    footer: {
      about: "À propos",
      termsAndConditions: "Conditions d'utilisation",
      contact: "Contact",
      downloadTheApp: "Télécharger l'application :",
      phone: "Téléphone: {{phone}}",
    },
    profileDropDown: {
      changePassword: "Changer mon mot de passe",
      logout: "Se déconnecter",
      emailSent:
        "Un email a été envoyé, veuillez vérifier votre boîte de réception",
    },
    About: "À propos",
    signIn: {
      cta: "Se connecter",
      header: "Hey, Bon Retour!",
      subHeader: "Entrez vos informations pour vous connecter à votre compte",
      email: "Adresse Email",
      password: "Mot de passe",
      rememberMe: "Se souvenir de moi",
      forgotPassword: "Mot de passe oublié?",
      noAccount: "Vous n'avez pas de compte?",
    },
    signUp: {
      cta: "S'inscrire",
      header: "Créez votre compte",
      subheader:
        "Inscrivez-vous dès maintenant Entrez vos informations pour créer votre compte et commencez à gérer vos restaurants en toute liberté. Profitez des avantages de notre plateforme pour augmenter le trafic de vos clients et optimiser la gestion de votre établissement.",
      createAccount: "Soumettre ma demande",
      creationSuccess:
        "Votre demande a été soumise avec succès, nous la vérifierons et vous répondrons dès que possible",
      creationError: "Erreur lors de la création de votre demande",
      form: {
        firstName: "Prénom",
        lastName: "Nom",
        email: "Adresse Email",
        phone: "Numéro de téléphone",
        restaurantName: "Nom du restaurant",
        city: "Ville",
      },
    },
    booking: {
      header: "Réserver une table",
      subheader:
        "Réservez une table dans <strong>{{restaurant_name}}</strong> pour une expérience culinaire inoubliable",
      form: {
        firstName: "Prénom",
        lastName: "Nom",
        numberOfPeople: "Nombre de personnes",
        submit: "Suivant",
        email: "Adresse Email",
        phone: "Numéro de téléphone",
        book: "Réserver",
        cancel: "Annuler",
      },
      creationSuccessHeader: "Réservation réussie",
      creationSuccessHelper:
        "{{restaurant_name}} a bien reçu votre réservation, il vous contacterons pour confirmer votre réservation",
      creationError:
        "Une erreur est survenue lors de la réservation, veuillez réessayer",
      slotsFull:
        "Il semblerait que quelqu'un ait déjà réservé toutes les places pour ce créneau, veuillez en choisir un autre",
    },
    AboutPage: {
      Mission: {
        Header: "Notre Mission",
        Body: "Chez <strong>L'Addition</strong>, nous visons à révolutionner et enrichir l'industrie de la restauration haut de gamme au <strong>Maroc</strong> en connectant des restaurateurs passionnés et des clients enthousiastes.\n\nNous croyons en la création d'une communauté culinaire dynamique, où <strong>chaque restaurant a l'opportunité de briller</strong>, et où chaque client peut découvrir son prochain endroit préféré.\n\nExploitez la puissance d'Internet pour placer votre restaurant au centre de l'attention et dans le cœur de vos clients.",
      },
      Services: {
        Header: "Nos Services",
        Body: "Bienvenue sur <strong>L'Addition</strong>, la nouvelle plateforme en ligne pour les <strong>propriétaires de restaurants au Maroc</strong> afin de présenter leurs établissements et de gérer leurs services de réservation.\n\nCréez votre compte dès maintenant pour enregistrer vos restaurants et gérez tout, de vos horaires et menus à vos offres et réservations, de manière simple et efficace.",
        Restaurants: {
          Header: "Gestion des Restaurants",
          Body: "Vous pouvez enregistrer et gérer <strong>autant de restaurants que vous le souhaitez</strong> pour les mettre sur la carte.\n\nAyez un <strong>contrôle total</strong> sur leurs horaires respectifs, menus, offres, etc...",
        },
        Calendar: {
          Header: "Gestion du Calendrier",
          Body: "Établissez un calendrier d'heures d'ouverture et de fermeture pour informer les clients de votre semaine typique.\n\nAjoutez des jours de congé au calendrier pour gérer d'éventuelles constructions non planifiées ou vacances.",
        },
        Menu: {
          Header: "Gestion du Menu",
          Body: "Vous pouvez rendre votre menu disponible à tous vos clients, que ce soit sous forme de texte simple, d'images ou les deux.\n\nEffectuez toutes les modifications nécessaires quand vous le souhaitez et comme vous le souhaitez.",
        },
        Deals: {
          Header: "Gestion des Offres",
          Body: "Envie d'offrir des offres séduisantes à vos clients ? Mettez en place des offres avec une description personnalisée et activez ou désactivez facilement pour chaque restaurant.",
        },
        Reservations: {
          Header: "Gestion des Réservations",
          Body: "Le service principal de la plateforme est la capacité de gérer toutes vos futures réservations tout en gardant un œil sur celles du passé.",
        },
      },
    },
    contactPage: {
      header:
        "Vous rencontrez un problème avec notre site? Créez un ticket ci-dessous",
      subHeader:
        "Nos équipes feront de leur mieux pour résoudre votre soucis rapidement",
      form: {
        content: "Décrivez votre problème",
      },
    },
    sideBar: {
      mydashboard: "Mon Tableau de Bord",
      myRestaurants: "Mes Restaurants",
      bookings: "Reservations",
      deals: "Offres",
      statistics: "Statistiques",
      logout: "Déconnexion",
    },
    restaurant: {
      home: {
        header: "Bienvenue {{name}}!",
        kpi: {
          day: "Aujourd'hui",
          twoDays: "Ces deux derniers jours",
          week: "Cette semaine",
          twoWeeks: "Ces deux dernières semaines",
          month: "Ce mois-ci",
          year: "Cette année",
          bookings: "Nombre de réservations",
          bookingsTooltip: "Nombre de réservations pour chaque jour",
          visitors: "Nombre de visites",
        },
        bookingsHeader: "Réservations récentes",
        dealsHeader: "Offres récentes",
        seeAll: "Voir tout",
        seeStats: "Voir toutes les stats",
      },
      restaurantList: {
        header: "Liste des restaurants",
        restaurantName: "Nom du restaurant",
        city: "Ville",
        address: "Adresse",
        rowsPerPage: "Lignes par page",
      },
      addRestaurantPage: {},
      restaurantInfoPage: {
        restaurantInfo: {
          header: "Informations du restaurant",
          subHeader: "Mettez à jour les informations de votre restaurant",
          ImportantInformation: {
            header: "Informations importantes",
            uploadSuccess:
              "Informations du restaurant mises à jour avec succès",
            uploadError:
              "Erreur lors de la mise à jour des informations du restaurant",
            fields: {
              name: "Nom du restaurant",
              address: "Adresse",
              city: "Ville",
              phone: "Numéro de téléphone",
              instagram_link: "Lien Instagram",
              booze: "Servez-vous de l'alcool ?",
              animation: "Proposez-vous une animation ?",
              quarter: "Quartier",
              numberOfSeats: "Combien de personnes pouvez-vous accueillir ?",
              tpe: "Acceptez-vous le paiement par tpe ?",
            },
          },
          cover: {
            header: "Photo de couverture",
            replaceCover: "Remplacer la couverture",
            addCover: "Ajouter une photo de couverture",
            uploadSuccess: "Photo de couverture téléchargée avec succès",
            uploadError:
              "Erreur lors du téléchargement de la photo de couverture",
          },
        },
        imageGallery: {
          header: "Galerie du restaurant",
          subHeader: "Offrez à vos clients un aperçu de votre restaurant",
          uploadSuccess: "Images téléchargées avec succès",
          uploadError: "Erreur lors du téléchargement des images",
        },
        menu: {
          images: {
            header: "Galerie du menu",
            subheader: "Ajoutez des images de votre menu",
            uploadSuccess: "Images téléchargées avec succès",
            uploadError: "Erreur lors du téléchargement des images",
          },
          addSection: "Ajouter une section",
          addDish: "Ajouter un plat",
          save: "Sauvegarder",
          section: {
            name: "Nom de la section",
            itemName: "Nom du plat",
            itemDescription: "Description",
            itemPrice: "Prix",
            uploadSuccess: "Menu mis à jour avec succès",
            uploadError: "Erreur lors de la mise à jour du menu",
          },
        },
        hoursPicker: {
          header: "Horaires d'ouverture",
          subheader:
            "Définissez les horaires d'ouverture et de fermeture de votre restaurant pour chaque jour de la semaine.",
          open: "De",
          close: "À",
          applyToAll: "Ajouter un crénau type",
          typicalDay: "Journée type",
          applyToAllDays: "Appliquer un créneau type à toute la semaine.",
          registerMorning: "Enregistrer les heures du matin",
          registerAfternoon: "Enregistrer les heures du soir",
          uploadSuccess: "Horaires d'ouverture mis à jour avec succès",
          uploadError: "Erreur lors de la mise à jour des horaires d'ouverture",
          switch: "Durée moyenne d'une réservation",
        },
        leaveDays: {
          header: "Jours de congé",
          subheader:
            "Ajoutez des jours de congé à votre calendrier pour informer vos clients de vos fermetures temporaires",
          addLeaveDay: "Ajouter des jours fermés",
        },
        location: {
          header: "Localisation du restaurant",
          subheader:
            "Indiquez l'emplacement de votre restaurant sur la carte pour permettre à vos clients de vous trouver facilement",
          permissions: "Veuillez activer la localisation pour continuer",
          uploadSuccess: "Localisation mise à jour avec succès",
          uploadError: "Erreur lors de la mise à jour de la localisation",
          myPosition: "Prendre ma position",
        },
      },
    },
    bookings: {
      header: "Gestion des réservations",
      subheader:
        "Consultez et gérez toutes vos réservations passées et futures",
      status: {
        pending: "En Attente",
        cancelled: "Annulée",
        confirmed: "Confirmée",
        actions: {
          confirm: "Confirmer",
          cancel: "Annuler",
        },
      },
      table: {
        status: "Statut",
        username: "Nom complet",
        phone: "N° de téléphone",
        restaurant: "Restaurant",
        slot: "Horaire",
        deal: "Offre",
        numberOfPeople: "Nbre personnes",
        cancelBooking: "Annuler la réservation",
        confirmBooking: "Confirmer la réservation",
        noBookings: "Vous n'avez aucune réservations pour ce jour",
      },
      modal: {
        confirm: {
          title: "Confirmer la réservation",
          disclaimer: "Êtes-vous sûr de vouloir confirmer cette réservation ?",
        },
        cancel: {
          title: "Annuler la réservation",
          disclaimer: "Êtes-vous sûr de vouloir annuler cette réservation ?",
        },
      },
    },
    deals: {
      header: "Liste des offres",
      subheader: "Consultez et gérez toutes vos offres",
      event: "Evénement",
      deal: "Offre",
      active: "Actif",
      inactive: "Inactif",
      addNewDeal: {
        header: "Ajouter une nouvelle offre",
        editHeader: "Modifier l'offre",
        description: "Description de l'offre",
        startTime: "Début",
        endTime: "Fin",
        addSlot: "Ajouter un créneau",
      },
      table: {
        status: "Statut",
        description: "Description",
        restaurant: "Restaurant",
        type: "Type",
      },
      save: "Sauvegarder",
      cancel: "Annuler",
      updateSuccess: "Offre mise à jour avec succès",
      updateError: "Erreur lors de la mise à jour de l'offre",
    },
    statistics: {
      header: {
        title: "Tableau de bord ",
        chooseRestaurant: "Choisissez un restaurant",
        startDay: "Début",
        endDay: "Fin",
      },
      subheader: "Voici toutes les statistiques pour vos restaurants",
      noStatistics: "Vous n'avez pas de statistiques pour ce jour",
      visibilityKpi: {
        clickLabel: "Nombre de personnes qui ont vue votre restaurant",
        bookingLabel:
          "Nombre de personnes qui ont commencé le processus de réservation",
        menuLabel: "Nombre de personnes qui ont consulté votre menu",
      },
      usersCountryKpi: {
        header: "Origine des visiteurs",
        tourist: "Touristes",
        morrocan: "Locaux",
      },
      bookingsByStatusKpi: {
        header: "Réservations groupées par statut",
        pendingLabel: "En attente",
        confirmedLabel: "Confirmée",
        rejectedLabel: "Rejetée",
      },
      bookingsKpiLabel: "Nombre de réservations",
      avgPartySize: "Moyenne de personnes par réservation",
      occupancyLabel: "Taux de remplissage des tables",
      seatsFilledLabel: "Nombre de places remplies",
      visitorsLabel: "Nombre de clicks sur votre restaurant",
      bookingsByStatus: {
        title: "Réservations par statut",
        pending: "En attente",
        confirmed: "Confirmée",
        rejected: "Rejetée",
        tooltip: "Nombre de réservations divisé par statut",
      },
      bookingsTrendByDay: {
        title: "Tendance des réservations par jour",
        tooltip: "Tendance des réservations par jour selon l'heure",
        label: "Heure de la journée",
      },
    },
  },
};
