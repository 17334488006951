import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import {
  Filters,
  GetBookingsData,
  Booking,
} from "src/components/Main/Pages/Bookings/data";
import { Grid } from "@mui/material";
import BookingTable from "src/components/Main/Pages/Bookings/BookingsTable";
import { UserContext } from "src/Utils/Context/UserContext";
import { useContext } from "react";
import DateRangePopover from "src/components/Main/Pages/UserDashboard/components/DateRangePopover";
import { dateRangePickeOptions } from "src/components/Main/Pages/UserDashboard/components/UserDashboardHeader";

const BookingsPage = ({ restaurant_id }: { restaurant_id?: string }) => {
  const { userInfo } = useContext(UserContext);
  const today = dayjs().format("YYYY-MM-DD");
  const oneWeekAgo = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const [startDate, setStartDate] = useState<string>(oneWeekAgo.toString());
  const [endDate, setEndDate] = useState<string>(today.toString());

  const id = userInfo?.id;
  const session_token = userInfo?.session_token;

  const [bookings, setbookings] = useState<Booking[]>();
  const [fetching, setFetching] = useState<boolean>(false);
  const [filters, setFilters] = useState<Filters>({
    restaurantName: [],
    bookingHours: [],
    status: [{ name: "Confirmed" }, { name: "Pending" }, { name: "Cancelled" }],
  });

  const onConfirm = (bookingID: string) => {
    const selectedBooking = bookings?.find(
      (booking) => booking.id === bookingID
    );

    fetch(`https://laddition.ma/Website/UpdateReservation.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        bookingID: bookingID,
        status: "Confirmed",
        type: selectedBooking?.type,
        name: selectedBooking?.userName,
        restaurant_name: selectedBooking?.restaurantName,
        email: selectedBooking?.email,
        date: selectedBooking?.date,
        bookingHour: selectedBooking?.bookingHour,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFetching(true);
          Getbookings();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const onCancel = (bookingID: string) => {
    const selectedBooking = bookings?.find(
      (booking) => booking.id === bookingID
    );

    fetch(`https://laddition.ma/Website/UpdateReservation.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        bookingID: bookingID,
        status: "Cancelled",
        type: selectedBooking?.type,
        name: selectedBooking?.userName,
        restaurant_name: selectedBooking?.restaurantName,
        email: selectedBooking?.email,
        date: selectedBooking?.date,
        bookingHour: selectedBooking?.bookingHour,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setFetching(true);
          Getbookings();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const Getbookings = useCallback(() => {
    let path;
    if (restaurant_id) {
      path = "GetBookingsByRestaurant.php";
    } else {
      path = "GetBookings.php";
    }
    fetch(`https://laddition.ma/Website/${path}`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        restaurant_id: restaurant_id,
        startDate: startDate,
        endDate: endDate,
      }),
    })
      .then((response) => response.json())
      .then((data: GetBookingsData) => {
        if (data.success) {
          var newFilters = { ...filters };
          // array of possible restaurant name filtering out duplicates
          const restaurantNames = data.bookings.map(
            (booking) => booking.restaurantName
          );
          newFilters.restaurantName = Array.from(new Set(restaurantNames));
          // array of possible booking hours filtering out duplicates
          const bookingHours = data.bookings.map(
            (booking) => booking.bookingHour
          );
          newFilters.bookingHours = Array.from(new Set(bookingHours));
          setFilters(newFilters);
          setbookings(data.bookings);
        }
      })
      .catch((error) => {
        setFetching(false);
        console.error("Error:", error);
      });
  }, [id, session_token, startDate, endDate, restaurant_id, filters]);

  const onRangeSelected = (start: Dayjs, end: Dayjs) => {
    setStartDate(start.format("YYYY-MM-DD").toString());
    setEndDate(end.format("YYYY-MM-DD").toString());
  };

  useEffect(() => {
    setFetching(true);
    Getbookings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, restaurant_id]);

  useEffect(() => {
    if (bookings) {
      setFetching(false);
    }
  }, [bookings]);

  return (
    <>
      <div
        style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
      >
        <DateRangePopover
          startDate={startDate}
          endDate={endDate}
          options={dateRangePickeOptions}
          today={dayjs(today)}
          onRangeSelected={onRangeSelected}
          onSelectOption={(start, end) => {
            setStartDate(start);
            setEndDate(end);
          }}
        />
      </div>
      {bookings && (
        <Grid width={"100%"}>
          <BookingTable
            fetching={fetching}
            bookings={bookings}
            filters={filters}
            onCancel={(id) => onCancel(id)}
            onConfirm={(id) => onConfirm(id)}
          />
        </Grid>
      )}
    </>
  );
};

export default BookingsPage;
