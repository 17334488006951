import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Grid, Divider } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import dayjs, { Dayjs } from "dayjs";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers/PickersDay";
import {
  ToggleSelect,
  CustomText,
  CustomLoadingButton,
  SnackBar,
} from "src/design";
import { useTranslation } from "react-i18next";
import { RestaurantType } from "src/Utils/Data/Restaurant";
import useAddDeal from "src/data/deals/useAddDeal";
import useUpdateDeal from "src/data/deals/useUpdateDeal";
import { Deal, NewDeal } from "src/business/deal";

interface WeekPickerDayProps {
  date: Dayjs;
  selectedDays: NewDeal;
  pickersDayProps: PickersDayProps<Dayjs>;
}

const renderWeekPickerDay = ({
  date,
  selectedDays,
  pickersDayProps,
}: WeekPickerDayProps) => {
  var i = 0;
  if (selectedDays.days.length === 0) {
    return <PickersDay {...pickersDayProps} />;
  }

  selectedDays.days.forEach((value) => {
    if (date.format("YYYY-MM-DD") === value) {
      i = 1;
    }
  });
  if (!i) {
    return <PickersDay {...pickersDayProps} />;
  } else {
    return <PickersDay {...pickersDayProps} selected />;
  }
};

interface Props {
  restaurants: RestaurantType[];
  getDeals: () => void;
  handleClose: () => void;
  deal?: Deal;
}

export const AddNewDeal = ({
  restaurants,
  getDeals = () => {},
  handleClose,
  deal,
}: Props) => {
  const { t } = useTranslation();
  const { addDeal } = useAddDeal(getDeals);
  const { updateDeal, updating } = useUpdateDeal();

  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);

  const [newDeal, setNewDeal] = useState<NewDeal>({
    description: deal?.description || "",
    restaurant_id: deal?.restaurant_id || "",
    days: deal?.jours || [],
    hours: deal?.crenaux || [],
    is_event: !!deal?.is_event,
  });
  const [startTime, setStartTime] = useState(new Date().setHours(16, 0));
  const [endTime, setEndTime] = useState(new Date().setHours(22, 0));
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(false);

  const AddDeal = async () => {
    setSaving(true);
    setError(false);
    if (
      newDeal.hours.length > 0 &&
      newDeal.days.length > 0 &&
      newDeal.description &&
      newDeal.restaurant_id
    ) {
      await addDeal({
        ...newDeal,
      });
      setNewDeal({
        description: "",
        restaurant_id: "",
        days: [],
        hours: [],
        is_event: false,
      });
      setEndTime(new Date().getTime());
      setStartTime(new Date().getTime());
      getDeals();
      handleClose();
      setSaving(false);
    } else {
      setError(true);
      setSaving(false);
    }
  };

  const onUpdateDeal = async (deal: Deal) => {
    const response = await updateDeal({
      ...newDeal,
      id_offre: deal.id_offre,
      status: deal.status,
      jours: newDeal.days,
      crenaux: newDeal.hours,
      restaurantName: "",
    });

    if (response.success) {
      setUpdateSuccess(true);
    } else {
      setUpdateError(true);
    }

    setSaving(false);
  };

  return (
    <Box sx={{ padding: 2 }}>
      <CustomText label={t("deals.addNewDeal.header")} type="header" />
      <Grid container direction={"column"} spacing={2}>
        <Grid item sx={{ width: { xs: "80%", sm: "65%", md: "50%" } }}>
          <FormControl
            fullWidth
            variant="standard"
            error={newDeal.restaurant_id === "" && error}
            required
          >
            <InputLabel id="demo-simple-select-label">
              {t("deals.table.restaurant")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              margin="dense"
              value={newDeal.restaurant_id}
              onChange={(e) => {
                setNewDeal((oldDeal) => {
                  return { ...oldDeal, restaurant_id: e.target.value };
                });
              }}
            >
              {restaurants.map((res, index) => {
                return (
                  <MenuItem value={res.restaurant_id} key={index}>
                    {res.Nom}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sx={{ width: { xs: "80%", sm: "65%", md: "50%" } }}>
          <TextField
            fullWidth
            required
            error={newDeal.description === "" && error}
            id="outlined-basic"
            label={t("deals.addNewDeal.description")}
            variant="standard"
            margin="dense"
            value={newDeal.description}
            onChange={(e) => {
              setNewDeal((oldDeal) => {
                return { ...oldDeal, description: e.target.value };
              });
            }}
          />
        </Grid>
        <Grid item>
          <ToggleSelect
            label=""
            value={Number(newDeal.is_event)}
            onChange={(event: React.ChangeEvent<{ value: string }>) => {
              setNewDeal((oldDeal) => {
                return {
                  ...oldDeal,
                  is_event: !!Number(event.target.value),
                };
              });
            }}
            options={[1, 0]}
            names={[t("deals.event"), t("deals.deal")]}
          />
        </Grid>
        <Divider flexItem sx={{ borderWidth: 1.5, marginTop: 1 }} />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Grid container marginTop={1}>
            <Grid item md={6} xs={12}>
              <CalendarPicker
                date={null}
                disableHighlightToday
                disablePast
                onChange={(e: Dayjs | null) => {
                  if (!e) return;
                  const date = e.format("YYYY-MM-DD");
                  var newDates = [...newDeal.days];
                  const index = newDates.indexOf(date);
                  if (index === -1) {
                    newDates.push(date);
                  } else {
                    newDates.splice(index, 1);
                  }
                  setNewDeal({ ...newDeal, days: newDates });
                }}
                renderDay={(date, _, pickersDayProps) =>
                  renderWeekPickerDay({
                    selectedDays: newDeal,
                    pickersDayProps,
                    date,
                  })
                }
              />
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderWidth: 1.5 }}
            />
            <Grid
              container
              item
              md={5}
              xs={12}
              direction={"column"}
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item>
                <TimePicker
                  minutesStep={30}
                  ampm={false}
                  label={t("deals.addNewDeal.startTime")}
                  value={startTime}
                  onChange={(e) => {
                    if (!e) return;
                    setStartTime(e);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item>
                <TimePicker
                  minutesStep={30}
                  ampm={false}
                  disabled={!startTime}
                  minTime={dayjs(startTime)}
                  label={t("deals.addNewDeal.endTime")}
                  value={endTime}
                  onChange={(e) => {
                    if (!e) return;
                    setEndTime(e as any as number);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="outlined"
                  aria-label="delete"
                  size="small"
                  color="success"
                  style={{
                    fontSize: 14,
                    marginLeft: 10,
                  }}
                  disabled={!endTime}
                  onClick={() => {
                    var newHours = [...newDeal.hours];
                    const start = new Date(startTime);
                    const end = new Date(endTime);
                    newHours.push(
                      `${start.getHours()}:${
                        start.getMinutes() ? "30" : "00"
                      }-${end.getHours()}:${end.getMinutes() ? "30" : "00"}`
                    );

                    setNewDeal({
                      ...newDeal,
                      hours: newHours,
                    });
                  }}
                >
                  {t("deals.addNewDeal.addSlot")}
                </Button>
              </Grid>
              <Grid item>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  style={{ marginTop: 5 }}
                >
                  {newDeal.hours.map((value, index) => {
                    if (value) {
                      return (
                        <Grid
                          item
                          key={index}
                          alignContent="center"
                          alignItems="center"
                          style={{
                            margin: 5,
                            padding: 2,
                            display: "flex",
                            borderBottom: "0.5px grey solid",
                          }}
                        >
                          <DeleteForeverIcon
                            fontSize="small"
                            style={{
                              color: "crimson",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              const newHours = [...newDeal.hours];
                              newHours.splice(index, 1);
                              setNewDeal({
                                ...newDeal,
                                hours: newHours,
                              });
                            }}
                          />
                          <Typography>{value}</Typography>
                        </Grid>
                      );
                    }

                    return null;
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </LocalizationProvider>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          style={{ marginTop: 10 }}
        >
          <Button
            variant="outlined"
            color="error"
            onClick={() => {
              handleClose();
            }}
          >
            {t("deals.cancel")}
          </Button>
          <CustomLoadingButton
            variant="contained"
            color="success"
            loading={updating || saving}
            onSubmit={async () => {
              if (deal) {
                onUpdateDeal(deal);
                return;
              } else {
                AddDeal();
              }
            }}
            label={t("deals.save")}
          />
        </Grid>
      </Grid>
      <SnackBar
        open={updateSuccess}
        onClose={() => setUpdateSuccess(false)}
        message={t("deals.updateSuccess")}
        severity="success"
      />
      <SnackBar
        open={updateError}
        onClose={() => setUpdateError(false)}
        message={t("deals.updateError")}
        severity="error"
      />
    </Box>
  );
};
