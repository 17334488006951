import { Chip, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import type { AvailabilitiesType } from "src/business/availability";
import { emerald } from "src/design/ColorPalette";
import { CustomButton, CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import PromotionIcon from "src/assets/Icons/promotion_icon.png";

interface TimeSlotProps {
  time: string;
  empty_tables: number;
  deal: string;
  selected: boolean;
  onClick: () => void;
  number_of_people: number;
}

const TimeSlot = ({
  time,
  empty_tables,
  deal,
  selected,
  onClick,
  number_of_people,
}: TimeSlotProps) => {
  return (
    <Chip
      sx={[{ borderRadius: 2 }, selected ? { backgroundColor: emerald } : {}]}
      color={"success"}
      variant={selected ? "filled" : "outlined"}
      icon={
        deal ? (
          <img height={"100%"} src={PromotionIcon} alt="promotion" />
        ) : undefined
      }
      label={time}
      disabled={empty_tables === 0 || number_of_people > empty_tables}
      onClick={onClick}
    />
  );
};

interface Props {
  availabilities: AvailabilitiesType[];
  onBook: (time: string) => void;
  onCancel: () => void;
  creating: boolean;
  number_of_people: number;
}

const SlotsWrapper = ({
  availabilities,
  onBook,
  onCancel,
  creating,
  number_of_people,
}: Props) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<AvailabilitiesType>(
    availabilities[0]
  );

  useEffect(() => {
    setSelected(availabilities[0]);
  }, [availabilities]);

  return (
    <Grid item justifyContent={"center"} container>
      <Grid
        container
        item
        md={5}
        style={{
          justifyContent: "center",
          gap: 2,
        }}
      >
        {availabilities.map((availability, index) => (
          <Grid item key={index}>
            <TimeSlot
              number_of_people={number_of_people}
              time={availability.time}
              empty_tables={availability.empty_tables}
              deal={availability.deal}
              key={index}
              onClick={() => {
                setSelected(availability);
              }}
              selected={selected === availability}
            />
          </Grid>
        ))}
        {selected.deal && (
          <Grid item md={10}>
            <CustomText label={selected.deal} type="body" fontcolor="#BFA100" />
          </Grid>
        )}
      </Grid>
      <Grid container justifyContent={"space-around"} spacing={4}>
        <Grid item>
          <CustomButton
            sx={{
              paddingLeft: 10,
              paddingRight: 10,
              marginTop: 3,
            }}
            fullWidth
            variant="outlined"
            color="error"
            onClick={onCancel}
          >
            {t("booking.form.cancel")}
          </CustomButton>
        </Grid>
        <Grid item>
          <CustomButton
            sx={{ paddingLeft: 10, paddingRight: 10, marginTop: 3 }}
            fullWidth
            loading={creating}
            onClick={() => onBook(selected.time)}
            disabled={number_of_people > selected.empty_tables}
          >
            {t("booking.form.book")}
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SlotsWrapper;
