import { Snackbar } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import React from "react";

const Alert = React.forwardRef<HTMLDivElement, any>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface SnackBarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  severity: "error" | "warning" | "info" | "success";
}

export const SnackBar = ({
  open,
  message,
  onClose,
  severity,
}: SnackBarProps) => (
  <Snackbar open={open} autoHideDuration={5000} onClose={onClose}>
    <Alert onClose={onClose} severity={severity} sx={{ width: "100%" }}>
      {message}
    </Alert>
  </Snackbar>
);
