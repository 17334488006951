import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Checkbox,
  MenuItem,
  ListItemText,
} from "@mui/material";
import { Key } from "react";

interface Option {
  name: string;
}

interface MultiSelectProps {
  options: Option[];
  value: string[];
  onChange: (value: string[]) => void;
  label: string;
  size?: "small" | "medium";
  disabled?: boolean;
  variant?: "standard" | "outlined" | "filled";
}

export const MultiSelect = ({
  options,
  value,
  onChange,
  label,
  size = "medium",
  disabled,
  variant = "outlined",
}: MultiSelectProps) => (
  <Box>
    <FormControl fullWidth disabled={disabled}>
      <InputLabel>{label}</InputLabel>
      <Select
        variant={variant}
        size={size}
        multiple
        required
        value={value}
        label={label}
        renderValue={(selected) => (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
            {selected.map((value: string) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        onChange={(event) => onChange(event.target.value as string[])}
      >
        {options.map((option: { name: string }, index: Key) => (
          <MenuItem key={index} value={option.name}>
            <Checkbox checked={value.indexOf(option.name) > -1} />
            <ListItemText primary={option.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);
