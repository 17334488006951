import HomeHeader from "./Header";
import { Grid } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { Footer } from "./Footer";
import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  darkBlue,
  emerald,
  lightBlue,
  transparentEmerald,
} from "src/design/ColorPalette";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const theme = createTheme({
  palette: {
    emerald: {
      main: emerald,
      light: transparentEmerald,
    },
    blue: {
      main: darkBlue,
      light: lightBlue,
    },
  },
});

const Homepage = () => {
  const location = useLocation();
  const [isHomePage, setIsHomePage] = useState(false);
  useEffect(() => {
    console.log(location.pathname.includes("booking"));
    setIsHomePage(
      location.pathname === "/Home/About" ||
        location.pathname.includes("booking")
    );
  }, [location]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <HomeHeader />
      <Grid
        container
        spacing={10}
        sx={{ padding: isHomePage ? 2 : { xs: 5, md: 15 }, minHeight: "90vh" }}
      >
        <Outlet />
      </Grid>
      <Footer />
    </ThemeProvider>
  );
};

export default Homepage;
