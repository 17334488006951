import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";

export const InputSelect = ({
  label,
  required = false,
  value,
  onChange,
  options,
  size = "medium",
  multiple = false,
  variant = "outlined",
  disabled = false,
}) => (
  <Box>
    <FormControl disabled={disabled} fullWidth size={size} variant={variant}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple={multiple}
        required={required}
        value={value}
        label="Ville"
        size={size}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <MenuItem value={option} key={index}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  </Box>
);
