import { Grid } from "@mui/material";
import { Filters } from "src/components/Main/Pages/Bookings/data";
import { SelectedFilter } from "src/components/Main/Pages/Bookings/BookingsTable";
import { MultiSelect } from "src/design";
import { useTranslation } from "react-i18next";
import { multiSelectFormat } from "src/Utils/ObjectTreatment";

interface BookingsTableHeaderProps {
  Filters: Filters;
  SelectedFilter: SelectedFilter;
  setSelectedFilter: (value: SelectedFilter) => void;
}

const BookingsTableHeader = ({
  Filters,
  SelectedFilter,
  setSelectedFilter,
}: BookingsTableHeaderProps) => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="flex-start" alignItems={"center"}>
      <Grid item xs={12} md={10} container sx={{ margin: 1 }}>
        <Grid container width={"100%"} spacing={3}>
          <Grid item md={3} xs={7}>
            <MultiSelect
              variant="standard"
              size="small"
              label={t("bookings.table.status")}
              value={SelectedFilter.status}
              onChange={(value: string[]) =>
                setSelectedFilter({
                  ...SelectedFilter,
                  status: value,
                })
              }
              options={Filters.status}
            />
          </Grid>
          <Grid item md={3} xs={7}>
            <MultiSelect
              variant="standard"
              size="small"
              label={t("bookings.table.restaurant")}
              value={SelectedFilter.restaurantName}
              onChange={(value: string[]) =>
                setSelectedFilter({
                  ...SelectedFilter,
                  restaurantName: value,
                })
              }
              options={multiSelectFormat(Filters.restaurantName)}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BookingsTableHeader;
