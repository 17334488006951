import useGetRestaurantBySlug from "src/data/restaurant/useGetRestaurantBySlug";
import { capitalize, CircularProgress, Grid } from "@mui/material";
import { CalendarPicker } from "@mui/x-date-pickers/CalendarPicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import useGetRestaurantAvailabilities from "src/data/restaurant/useGetRestaurantAvailabilities";
import SlotsWrapper from "src/components/Booking/TimeSlots";
import { useEffect, useState } from "react";
import { CustomText, SnackBar } from "src/design";
import { darkBlue, emerald } from "src/design/ColorPalette";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import BookingForm from "src/components/Booking/BookingForm";
import type { CountryCode } from "libphonenumber-js";
import useCreateAnonymousBookings from "src/data/booking/useCreateAnonymousBookings";
import SuccessPage from "src/components/Booking/SuccessPage";

export interface BookingInfo {
  email: string;
  phone: string;
  firstName: string;
  lastName: string;
  numberOfPeople: string;
  country: CountryCode;
}

const today = dayjs();

const BookSlot = () => {
  const { t } = useTranslation();
  const { slug } = useParams<{ slug: string }>();
  const restaurantSlug = slug
    ?.replaceAll("-", " ")
    .split(" ")
    .map(capitalize)
    .join(" ");

  const [page, setPage] = useState(0);

  const [creationError, setCreationError] = useState(false);
  const [slotsFull, setSlotsFull] = useState(false);

  const { restaurant, isLoading } = useGetRestaurantBySlug();
  const {
    availabilities,
    isLoading: availabilitiesLoading,
    selectableDays,
  } = useGetRestaurantAvailabilities(restaurant?.id);

  const { isCreating, createAnonymousBooking } = useCreateAnonymousBookings();

  const [selectedDay, setSelectedDay] = useState<Dayjs>();
  const [bookingInfo, setBookingInfo] = useState<BookingInfo>({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    numberOfPeople: "2",
    country: "MA",
  });

  useEffect(() => {
    if (!availabilitiesLoading && availabilities.length > 0) {
      setSelectedDay(dayjs(availabilities[0].date));
    }
  }, [availabilities, availabilitiesLoading, selectableDays]);

  const onBook = (time: string) => {
    if (isCreating || !selectedDay) return;

    const selectedAvailability = availabilities.find(
      (availability) =>
        availability.time === time &&
        dayjs(availability.date).isSame(selectedDay, "day")
    );

    createAnonymousBooking({
      restaurant_id: restaurant?.id,
      date: selectedDay.format("YYYY-MM-DD"),
      time,
      first_name: bookingInfo.firstName,
      last_name: bookingInfo.lastName,
      email: bookingInfo.email,
      phone: bookingInfo.phone,
      deal: selectedAvailability?.deal || "",
      number_of_people: parseInt(bookingInfo.numberOfPeople),
      country: bookingInfo.country === "MA" ? "Morocco" : bookingInfo.country,
      restaurant_name: restaurantSlug || "",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          setPage(2);
        } else {
          if (data.error) {
            setSlotsFull(true);
          } else {
            setCreationError(true);
          }
        }
      })
      .catch(() => {
        setCreationError(true);
      });
  };

  if (isLoading || availabilitiesLoading || !selectedDay) {
    return (
      <Grid justifyContent={"center"} container alignContent={"center"}>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Grid
      container
      width="100%"
      sx={{ marginLeft: "80px" }}
      alignContent={"center"}
      justifyContent={"center"}
      spacing={5}
    >
      {page < 2 && (
        <Grid item md={8}>
          <CustomText
            fontcolor={emerald}
            label={t("booking.header")}
            type="header"
          />
          <Trans
            i18nKey={"booking.subheader"}
            values={{
              restaurant_name: restaurantSlug,
            }}
            size={16}
            color={darkBlue}
          />
        </Grid>
      )}
      {page === 0 ? (
        <BookingForm
          info={bookingInfo}
          setInfo={setBookingInfo}
          onContinue={() => setPage(1)}
        />
      ) : (
        <>
          {page === 1 ? (
            <>
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <CalendarPicker
                    date={selectedDay}
                    onChange={(date) => {
                      setSelectedDay(date as Dayjs);
                    }}
                    maxDate={today.add(1, "month")}
                    shouldDisableDate={(date) =>
                      !selectableDays.includes(date.format("YYYY-MM-DD"))
                    }
                  />
                </LocalizationProvider>
              </Grid>
              <SlotsWrapper
                availabilities={availabilities.filter((availability) => {
                  const date = dayjs(availability.date);
                  return date.isSame(selectedDay, "day");
                })}
                onBook={onBook}
                onCancel={() => setPage(0)}
                creating={isCreating}
                number_of_people={parseInt(bookingInfo.numberOfPeople)}
              />
            </>
          ) : (
            <SuccessPage restaurantSlug={restaurantSlug} />
          )}
        </>
      )}
      <SnackBar
        open={creationError}
        onClose={() => setCreationError(false)}
        message={t("booking.creationError")}
        severity="error"
      />
      <SnackBar
        open={slotsFull}
        onClose={() => setSlotsFull(false)}
        message={t("booking.slotsFull")}
        severity="error"
      />
    </Grid>
  );
};

export default BookSlot;
