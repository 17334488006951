import { Grid } from "@mui/material";
import { CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import DateRangePopover from "src/components/Main/Pages/UserDashboard/components/DateRangePopover";
import { dateRangePickeOptions } from "src/components/Main/Pages/UserDashboard/components/UserDashboardHeader";
import { useContext } from "react";
import dayjs, { Dayjs } from "dayjs";
import { OwnerContext } from "src/Utils/Context/OwnerContext";

interface Props {
  startDate: string;
  endDate: string;
  setStartDate: (date: string) => void;
  setEndDate: (date: string) => void;
  setOptionIndex: (index: number) => void;
}

const today = dayjs();

const HomeHeader = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  setOptionIndex,
}: Props) => {
  const { t } = useTranslation();
  const pickerOptions = dateRangePickeOptions.slice(0, 6);
  const { ownerInfo } = useContext(OwnerContext);

  const onRangeSelected = (start: Dayjs, end: Dayjs) => {
    setStartDate(start.format("YYYY-MM-DD").toString());
    setEndDate(end.format("YYYY-MM-DD").toString());
  };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        container
        style={{ marginBottom: 30, marginTop: 10 }}
        justifyContent={"space-between"}
      >
        <CustomText
          label={t("restaurant.home.header", { name: ownerInfo.name })}
          type="header"
          component="h1"
          fontFamily="sans-serif"
        />
        <Grid item>
          <DateRangePopover
            startDate={startDate}
            endDate={endDate}
            options={pickerOptions}
            today={today}
            onRangeSelected={onRangeSelected}
            onSelectOption={(start, end, index) => {
              setStartDate(start);
              setEndDate(end);
              setOptionIndex(index);
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HomeHeader;
