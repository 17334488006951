import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Grid } from "@mui/material";
import { CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import { emerald } from "src/design/ColorPalette";

const SuccessPage = ({ restaurantSlug }: { restaurantSlug?: string }) => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      direction="column"
    >
      <Grid container justifyContent={"center"}>
        <AutoAwesomeIcon sx={{ color: "#BFA100" }} />
        <CustomText
          component={"h1"}
          type="header"
          label={t("booking.creationSuccessHeader")}
          fontcolor={emerald}
        />
      </Grid>
      <CustomText
        component={"h2"}
        type="subheader"
        label={t("booking.creationSuccessHelper", {
          restaurant_name: restaurantSlug,
        })}
      />
    </Grid>
  );
};

export default SuccessPage;
