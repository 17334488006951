import { useCallback, useContext, useEffect, useState } from "react";
import { Booking } from "src/components/Main/Pages/Bookings/data";
import { UserContext } from "src/Utils/Context/UserContext";

const useGetBookings = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [bookings, setBookings] = useState<Booking[]>([]);
  const { userInfo } = useContext(UserContext);

  const Getbookings = useCallback(() => {
    setIsLoading(true);

    fetch("https://laddition.ma/Website/GetBookings.php", {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        limit: 5,
      }),
    })
      .then((response) => response.json())
      .then((data: { success: boolean; bookings: Booking[] }) => {
        if (data.success) {
          setBookings(data.bookings);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [userInfo?.id, userInfo?.session_token]);

  useEffect(() => {
    Getbookings();
  }, [Getbookings, userInfo?.id, userInfo?.session_token]);

  return { isLoading, bookings, refetch: Getbookings };
};

export default useGetBookings;
