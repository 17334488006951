import { Cities } from "../../../../Utils/Data";
import { useState, useContext, useEffect } from "react";
import ImageGallery from "./RestaurantInformation/ImageGallery";
import RestauInfo from "./RestaurantInformation/RestauInfo";
import Menu from "./RestaurantInformation/Menu";
import { Grid } from "@mui/material";
import Localisation from "./RestaurantInformation/Localisation";
import HoursPicker from "./RestaurantInformation/HoursPicker";
import { useTranslation } from "react-i18next";
import { UserContext } from "src/Utils/Context/UserContext";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { useParams } from "react-router-dom";
import { TailSpin } from "react-loader-spinner";

const HomePage = () => {
  const [fetching, setFetching] = useState(true);
  const { userInfo } = useContext(UserContext);
  const id = userInfo.id;
  const session_token = userInfo.session_token;
  const admin = userInfo.admin;

  const { restaurantId } = useParams();
  const { restaurants, isLoading } = useContext(RestaurantContext);

  const { t } = useTranslation();
  const [Info, setInfo] = useState();

  useEffect(() => {
    setFetching(true);
    const restaurant = restaurants.find(
      (restau) => restau.restaurant_id === restaurantId
    );
    setInfo({ ...restaurant });
  }, [restaurantId, restaurants]);

  useEffect(() => {
    if (Info) {
      setFetching(false);
    }
  }, [Info]);

  return !fetching ? (
    <Grid container rowSpacing={5} direction="column" alignItems="flex-start">
      <Grid item width={"100%"}>
        <RestauInfo
          restaurant_id={restaurantId}
          Info={Info}
          setInfo={setInfo}
          id={id}
          session_token={session_token}
          admin={admin}
        />
      </Grid>
      {admin && (
        <Localisation
          restaurant_id={restaurantId}
          City={Cities.find((city) => city.city === Info.City)}
          loading={isLoading}
          setInfo={setInfo}
          coordinates={Info.Coords}
          id={id}
          session_token={session_token}
        />
      )}
      <Grid item width={"100%"}>
        <HoursPicker
          Horaires={Info.Horaire}
          restaurant_id={restaurantId}
          id={id}
          session_token={session_token}
          booking_duration={Info.booking_duration}
        />
      </Grid>
      <Grid item container justifyContent="space-between" rowSpacing={4}>
        <ImageGallery
          header={t("restaurant.restaurantInfoPage.imageGallery.header")}
          subheader={t("restaurant.restaurantInfoPage.imageGallery.subHeader")}
          successMessage={t(
            "restaurant.restaurantInfoPage.imageGallery.uploadSuccess"
          )}
          errorMessage={t(
            "restaurant.restaurantInfoPage.imageGallery.uploadError"
          )}
          restaurant_id={restaurantId}
          id={id}
          session_token={session_token}
        />
        <ImageGallery
          header={t("restaurant.restaurantInfoPage.menu.images.header")}
          subheader={t("restaurant.restaurantInfoPage.menu.images.subheader")}
          successMessage={t(
            "restaurant.restaurantInfoPage.menu.images.uploadSuccess"
          )}
          errorMessage={t(
            "restaurant.restaurantInfoPage.menu.images.uploadError"
          )}
          restaurant_id={restaurantId}
          id={id}
          session_token={session_token}
          menu={1}
        />
      </Grid>
      <Grid item width="100%">
        <Menu
          restaurant_id={restaurantId}
          id={id}
          session_token={session_token}
        />
      </Grid>
    </Grid>
  ) : (
    <div
      style={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        marginTop: 20,
      }}
    >
      <TailSpin color="#00BFFF" height={80} width={80} />
    </div>
  );
};

export default HomePage;
