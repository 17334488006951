import { useState } from "react";
import { AnonymousBookingPayloadType } from "src/business/booking";

const useCreateAnonymousBookings = () => {
  const [isCreating, setIsCreating] = useState(false);

  const createAnonymousBooking = async (
    anonymousBookingPayload: AnonymousBookingPayloadType
  ) => {
    setIsCreating(true);

    const response = await fetch(
      "https://laddition.ma/Website/CreateAnonymousBooking.php",
      {
        method: "POST",
        body: JSON.stringify(anonymousBookingPayload),
      }
    );

    setIsCreating(false);
    return response;
  };

  return { isCreating, createAnonymousBooking };
};

export default useCreateAnonymousBookings;
