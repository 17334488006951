export const en = {
  translation: {
    common: {
      save: "Save",
      cancel: "Cancel",
      email: "email address",
      submit: "Submit",
      tags: "Tags",
      phone: "Phone number",
      days: {
        monday: "Monday",
        tuesday: "Tuesday",
        wednesday: "Wednesday",
        thursday: "Thursday",
        friday: "Friday",
        saturday: "Saturday",
        sunday: "Sunday",
      },
      months: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
      },
    },
    emailVerification: {
      body: "Your account if being verified please wait for a few seconds",
      crash: "An error occurred, please try again in a while",
      website: {
        success:
          "Your account was verified successfully, you will be redirected to Sign In",
        error:
          "Your token is unfortunately expired, please try again from the Sign In screen",
      },
      mobile: {
        success:
          "Your account was verified successfully, you can now sign in from the mobile app",
        error:
          "Your token is unfortunately expired, please try again from the mobile app",
      },
    },
    homePage: {
      header: "Attract more customers",
      secondHeader: "Increase your trafic",
      subheader:
        "With L'addition, <strong>manage your restaurant's reservations</strong>, <strong>offer deals</strong> to customers, and <strong>increase your online visibility</strong>, all in one environment!",
      discoverCta: "Discover",
      comingSoon: "Coming soon",
    },
    footer: {
      about: "About",
      termsAndConditions: "Terms and conditions",
      contact: "Contact",
      downloadTheApp: "Download the App for customers :",
      phone: "Phone: {{phone}}",
    },
    profileDropDown: {
      changePassword: "Change my password",
      logout: "Logout",
      emailSent: "An email has been sent, please check your inbox",
    },
    About: "About",
    signIn: {
      cta: "Login",
      header: "Hey, Welcome Back!",
      subHeader: "Enter your credentials to access your account",
      email: "Email Address",
      password: "Password",
      rememberMe: "Remember me",
      forgotPassword: "Forgot your password?",
      noAccount: "Don't have an account?",
    },
    signUp: {
      cta: "Sign Up",
      header: "Create your account",
      subheader:
        "Sign Up Now Enter your information to create your account and start managing your restaurants with ease. Take advantage of our platform to increase customer traffic and optimize your establishment's management.",
      createAccount: "Submit my request",
      creationSuccess:
        "Your request was successfully submitted, we will review it and get back to you as soon as possible",
      creationError: "An error occurred while creating your request",
      emailTaken: "This email is already taken",
      form: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        phone: "Phone Number",
        restaurantName: "Restaurant Name",
        city: "City",
      },
    },
    booking: {
      header: "Book a table",
      subheader:
        "Book a table at <strong>{{restaurant_name}}</strong> for a unique dining experience",
      form: {
        firstName: "First Name",
        lastName: "Last Name",
        email: "Email Address",
        phone: "Phone Number",
        numberOfPeople: "Number of people",
        submit: "Continue",
        book: "Book",
        cancel: "Cancel",
      },
      creationSuccessHeader: "Booking request sent",
      creationSuccessHelper:
        "{{restaurant_name}} has received your booking request, they will contact you to confirm your reservation",
      creationError:
        "An error occurred while creating your booking, please try again",
      slotsFull:
        "It seems like someone else just booked this slot, please choose another one",
    },
    AboutPage: {
      Mission: {
        Header: "Our Mission",
        Body: "At <strong>L'Addition</strong> we aim to revolutionize and enrich the fine dining industry in <strong>Morocco</strong> by connecting passionate restaurant owners and eager customers.\n\nWe believe in fostering a vibrant culinary community, where <strong>every restaurant has the opportunity to shine</strong>, and every diner can discover their next favorite spot.\n\nLeverage the power of the internet to put your restaurant at the center of attention and in the hearts of your customers.",
      },
      Services: {
        Header: "Our Services",
        Body: "Welcome to <strong>L'Addition</strong>, the new online platform for <strong>restaurant owners in Morocco</strong> to showcase their establishments and manage their reservation services.\n\nCreate your account right now to register your restaurants and effortlessly manage everything from your schedules and menus all the way to your deals and reservations.",
        Restaurants: {
          Header: "Restaurant Management",
          Body: "You can register and manage <strong>as many restaurants as you want</strong> to put them on the map.\n\nHave <strong>full control</strong> over their respective schedules, menus, deals etc...",
        },
        Calendar: {
          Header: "Calendar Management",
          Body: "Set up a calendar of opening and closing hours to keep customers informed on your typical week.\n\nAdd leave days in the calendar to manage any unplanned constructions or vacations.",
        },
        Menu: {
          Header: "Menu Management",
          Body: "You can make your menu available to all your customers, whether through plain text, images or both.\n\nMake any changes you require whenever you want and however you want.",
        },
        Deals: {
          Header: "Deals Management",
          Body: "Feeling like offering alluring deals to your customers? Set up some deals with custom description and effortlessly switch them on or off for each restaurant.",
        },
        Reservations: {
          Header: "Reservations Management",
          Body: "The core service of the platform is the ability to manage all your future reservations while keeping an eye on the past ones as well.",
        },
      },
    },
    contactPage: {
      header:
        "Facing an issue with our website? let us know by creating a ticket",
      subHeader: "Our teams will do their best to solve your issue asap",
      form: {
        content: "Describe your problem",
      },
    },
    sideBar: {
      mydashboard: "My Dashboard",
      myRestaurants: "My Restaurants",
      bookings: "Bookings",
      statistics: "Analytics",
      deals: "Deals",
      logout: "Logout",
    },
    restaurant: {
      home: {
        header: "Welcome {{name}}!",
        kpi: {
          day: "Today",
          twoDays: "These 2 days",
          week: "This week",
          month: "This month",
          year: "This year",
          twoWeeks: "These 2 weeks",
          bookings: "Number of bookings",
          bookingsTooltip: "Number of bookings made for your restaurants",
          visitors: "Number of visitors",
        },
        bookingsHeader: "Recent bookings",
        dealsHeader: "Recent deals",
        seeAll: "See all",
        seeStats: "See all statistics",
      },
      restaurantList: {
        header: "Your Restaurants",
        restaurantName: "Restaurant Name",
        city: "City",
        address: "Address",
        rowsPerPage: "Rows per page",
      },
      addRestaurantPage: {},
      restaurantInfoPage: {
        restaurantInfo: {
          header: "Restaurant Information",
          subHeader: "Update your restaurant's information",
          ImportantInformation: {
            header: "Important Information",
            uploadSuccess: "Restaurant information updated successfully",
            uploadError:
              "An error occurred while updating the restaurant information",
            fields: {
              name: "Restaurant Name",
              address: "Address",
              city: "City",
              phone: "Phone Number",
              instagram_link: "Instagram Link",
              booze: "Do you serve alcohol?",
              animation: "Do you propose any animation?",
              quarter: "Street",
              numberOfSeats: "How many people can you accommodate?",
              tpe: "Do you accept payment by TPE?",
            },
          },
          cover: {
            header: "Cover Photo",
            replaceCover: "Replace cover",
            addCover: "Add a cover photo",
            uploadSuccess: "Cover photo uploaded successfully",
            uploadError: "An error occurred while uploading the cover photo",
          },
        },
        imageGallery: {
          header: "Restaurant Gallery",
          subHeader: "Provide a glimpse of your restaurant to your customers",
          uploadSuccess: "Images uploaded successfully",
          uploadError: "An error occurred while uploading the images",
        },
        menu: {
          images: {
            header: "Menu Gallery",
            subheader: "Showcase your dishes with stunning images",
            uploadSuccess: "Images uploaded successfully",
            uploadError: "An error occurred while uploading the images",
          },
          addSection: "Add a section",
          addDish: "Add a dish",
          save: "Save",
          section: {
            name: "Section name",
            itemName: "Dish name",
            itemDescription: "Dish description",
            itemPrice: "Dish price",
            uploadSuccess: "Menu updated successfully",
            uploadError: "An error occurred while updating the menu",
          },
        },
        hoursPicker: {
          header: "Opening Hours",
          subheader:
            "Set up your restaurant's opening and closing hours for each day of the week.",
          open: "From",
          close: "To",
          applyToAll: "Apply to all",
          typicalDay: "Typical Day",
          applyToAllDays: "Apply an opening to all days",
          registerMorning: "Register Morning Hours",
          registerAfternoon: "Register Afternoon Hours",
          uploadSuccess: "Opening hours updated successfully",
          uploadError: "An error occurred while updating the opening hours",
          switch: "Average booking duration",
        },
        leaveDays: {
          header: "Leave Days",
          subheader:
            "Inform your customers about the days your restaurant will be closed",
          addLeaveDay: "Add a leave day",
        },
        location: {
          header: "Restaurant Location",
          subheader:
            "Allow your customers to find your restaurant easily by providing your location",
          myPosition: "Take My Position",
          permissions:
            "You need to allow location permissions to use this feature",
          uploadSuccess: "Location updated successfully",
          uploadError: "An error occurred while updating the location",
        },
      },
    },
    bookings: {
      header: "Your Bookings",
      subheader: "Here are all the bookings made for your restaurants",
      status: {
        pending: "Pending",
        confirmed: "Confirmed",
        cancelled: "Cancelled",
        actions: {
          confirm: "Confirm",
          cancel: "Cancel",
        },
      },
      table: {
        status: "Status",
        username: "Full Name",
        phone: "Phone Number",
        restaurant: "Restaurant",
        slot: "Slot",
        deal: "Deal",
        numberOfPeople: "Number of people",
        cancelBooking: "Cancel Reservation",
        confirmBooking: "Confirm Reservation",
        noBookings: "You have no bookings for this day",
      },
      modal: {
        confirm: {
          title: "Confirm Reservation",
          disclaimer: "Are you sure you want to confirm this reservation?",
        },
        cancel: {
          title: "Cancel Reservation",
          disclaimer: "Are you sure you want to cancel this reservation?",
        },
      },
    },
    deals: {
      header: "Your Deals",
      subheader: "Consult the deals you have created for your restaurants",
      event: "Event",
      deal: "Deal",
      active: "Active",
      inactive: "Inactive",
      addNewDeal: {
        header: "Create a new deal",
        editHeader: "Edit a deal",
        description: "Description of the deal",
        startTime: "Start Time",
        endTime: "End Time",
        addSlot: "Add a slot",
      },
      table: {
        status: "Status",
        description: "Description",
        restaurant: "Restaurant",
        type: "Type",
      },
      save: "Save",
      cancel: "Cancel",
      updateSuccess: "Deal updated successfully",
      updateError: "An error occurred while updating the deal",
    },
    statistics: {
      header: {
        title: "Executive Dashboard",
        chooseRestaurant: "Choose a restaurant",
        startDay: "Start Day",
        endDay: "End Day",
      },
      subheader: "Here are all the statistics for your restaurants",
      noStatistics: "You have no statistics for this day",
      visibilityKpi: {
        clickLabel: "Number of people who looked at your restaurant",
        bookingLabel: "Number of people who started the booking process",
        menuLabel: "Number of people who looked at your menu",
      },
      usersCountryKpi: {
        header: "Bookings by user origin",
        tourist: "Tourists",
        morrocan: "Locals",
      },
      bookingsByStatusKpi: {
        header: "Bookings grouped by status",
        pendingLabel: "Pending",
        confirmedLabel: "Confirmed",
        rejectedLabel: "Rejected",
      },
      bookingsKpiLabel: "Total Bookings",
      avgPartySize: "Average Party Size",
      occupancyLabel: "Occupancy Rate",
      seatsFilledLabel: "Seats Filled",
      visitorsLabel: "Number of clicks on your restaurant ",
      bookingsByStatus: {
        title: "Bookings by status",
        pending: "Pending",
        confirmed: "Confirmed",
        rejected: "Rejected",
        tooltip: "Number of bookings grouped by status",
      },
      bookingsTrendByDay: {
        title: "Bookings trend by day",
        tooltip: "Number of bookings per day",
        label: "Hour of the day",
      },
    },
  },
};
