export const emerald = "#2ecc71";
export const transparentEmerald = "rgb(46, 204, 113, 0.15)";
export const darkBlue = "#141824";
export const lightBlue = "#34495e";

export const green = "#82ca9d";
export const lightGreen = "#d0ed57";
export const darkGreen = "#82ca9d";
export const blue = "#83a6ed";
export const purple = "#8884d8";
export const orange = "#FF8042";
export const yellow = "#ffc658";
export const red = "red";

export const threeColorsPalette = [green, blue, purple];

export const sevenColorsPalette = [
  purple,
  blue,
  darkGreen,
  green,
  lightGreen,
  yellow,
  orange,
];

export const chartsPalette: Record<string, string[]> = {
  "0": [green],
  "1": [green],
  "2": [green, blue],
  "3": threeColorsPalette,
  "4": [green, blue, purple, orange],
  "5": [green, blue, purple, orange, yellow],
  "6": [green, blue, purple, orange, yellow, red],
  "7": sevenColorsPalette,
};
