import { useState, useEffect, useCallback } from "react";
import type { AvailabilitiesType } from "src/business/availability";

const useGetRestaurantAvailabilities = (restaurantId: string) => {
  const [availabilities, setAvailabilities] = useState<AvailabilitiesType[]>(
    []
  );
  const [selectableDays, setSelectableDays] = useState<string[]>([]);
  const [isLoading, setLoading] = useState(true);

  const getRestaurantAvailabilities = useCallback(async () => {
    const response = await fetch(
      `https://laddition.ma/Shared/V1/GetRestaurantAvailabilities.php?restaurant_id=${restaurantId}`
    );
    const availabilities = await response.json();
    return availabilities;
  }, [restaurantId]);

  useEffect(() => {
    if (!restaurantId) {
      return;
    }

    getRestaurantAvailabilities().then((response: AvailabilitiesType[]) => {
      let selectableDaysArray: string[] = [];
      response.forEach((availability) => {
        if (!selectableDaysArray.includes(availability.date)) {
          selectableDaysArray.push(availability.date);
        }
      });

      setSelectableDays(selectableDaysArray);
      setAvailabilities(response);
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [restaurantId, getRestaurantAvailabilities]);

  return { availabilities, isLoading, selectableDays };
};

export default useGetRestaurantAvailabilities;
