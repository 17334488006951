import {
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Grid,
} from "@mui/material";
import { CustomText, CustomButton } from "src/design";
import { TailSpin } from "react-loader-spinner";
import useGetBookings from "src/data/booking/useGetBookings";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const BookingTable = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { isLoading, bookings } = useGetBookings();

  return (
    <Grid
      container
      sx={{
        backgroundColor: "rgba(248, 248, 248, 1)",
        padding: 2,
        paddingTop: 1,
      }}
      direction={"column"}
      gap={2}
      width={"100%"}
    >
      <Grid item>
        <CustomText
          label={t("restaurant.home.bookingsHeader")}
          type="header"
          fontFamily="sans-serif"
          size={16}
        />
      </Grid>
      {bookings.length === 0 && !isLoading ? (
        <h3 style={{ textAlign: "center" }}>Vous n'avez pas encore de deal</h3>
      ) : (
        <>
          {isLoading ? (
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                marginTop: 20,
              }}
            >
              <TailSpin color="#00BFFF" height={80} width={80} />
            </div>
          ) : (
            <Grid item>
              <Table size="small" aria-label="bookings table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center" padding="none">
                      {t("bookings.table.status")}
                    </TableCell>
                    <TableCell align="center" padding="none">
                      {t("bookings.table.username")}
                    </TableCell>
                    <TableCell align="center" padding="none">
                      {t("bookings.table.phone")}
                    </TableCell>
                    <TableCell align="center" padding="none">
                      {t("bookings.table.restaurant")}
                    </TableCell>
                    <TableCell align="left" padding="none">
                      {t("bookings.table.slot")}
                    </TableCell>
                    <TableCell align="right" padding="none">
                      {t("bookings.table.numberOfPeople")}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {isLoading ? (
                    <TableRow>
                      <TableCell colSpan={6} align="center">
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "center",
                            marginTop: 20,
                          }}
                        >
                          <TailSpin color="#00BFFF" height={80} width={80} />
                        </div>
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {bookings.map((booking) => (
                        <TableRow key={booking.id}>
                          <TableCell align="left" component="th" scope="row">
                            {booking.status}
                          </TableCell>
                          <TableCell align="center">
                            {booking.userName}
                          </TableCell>
                          <TableCell align="left">{booking.phone}</TableCell>
                          <TableCell align="left">
                            {booking.restaurantName}
                          </TableCell>
                          <TableCell align="left">
                            {booking.bookingHour}
                          </TableCell>
                          <TableCell align="center">
                            {booking.numberOfPeople}
                          </TableCell>
                        </TableRow>
                      ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </Grid>
          )}
        </>
      )}
      <Grid item container justifyContent={"center"}>
        <CustomButton
          onClick={() => {
            navigate(`/restaurant/bookings`);
          }}
        >
          {t("restaurant.home.seeAll")}
        </CustomButton>
      </Grid>
    </Grid>
  );
};

export default BookingTable;
