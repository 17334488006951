import { Grid } from "@mui/material";
import { CustomLoadingButton, TextInput } from "src/design";
import { useTranslation } from "react-i18next";
import { BookingInfo } from "src/components/Booking";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { MuiTelInput } from "mui-tel-input";
import { isValidPhoneNumber } from "libphonenumber-js";

interface Props {
  setInfo: (info: BookingInfo) => void;
  info: BookingInfo;
  onContinue: () => void;
}

const BookingForm = ({ setInfo, info, onContinue }: Props) => {
  const { t } = useTranslation();
  const [canCreate, setCanCreate] = useState(false);
  const [infoError, setInfoError] = useState({
    firstName: false,
    lastName: false,
    phone: false,
    email: false,
    numberOfPeople: false,
  });

  const handleOnContinue = () => {
    if (
      info.firstName &&
      info.lastName &&
      info.phone &&
      info.email &&
      info.numberOfPeople &&
      isValidPhoneNumber(info.phone) &&
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(info.email) &&
      info.numberOfPeople !== "0"
    ) {
      onContinue();
    } else {
      setInfoError({
        firstName: !info.firstName,
        lastName: !info.lastName,
        phone: !info.phone || !isValidPhoneNumber(info.phone),
        email:
          !info.email ||
          !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(info.email),
        numberOfPeople: !info.numberOfPeople || info.numberOfPeople === "0",
      });
    }
  };

  useEffect(() => {
    if (
      info.firstName &&
      info.lastName &&
      info.phone &&
      info.email &&
      info.numberOfPeople
    ) {
      setCanCreate(true);
    } else {
      setCanCreate(false);
    }
  }, [info]);

  return (
    <Grid
      container
      width="100%"
      alignContent={"center"}
      justifyContent={"center"}
      spacing={5}
      marginTop={2}
    >
      <Grid item container spacing={4} md={8} xs={10}>
        <Grid
          container
          item
          spacing={4}
          justifyContent="space-between"
          columnSpacing={3}
        >
          <Grid item md={6} xs={10}>
            <TextInput
              error={infoError.firstName}
              fullwidth
              label={t("booking.form.firstName")}
              onChange={(value) => setInfo({ ...info, firstName: value })}
              value={info.firstName}
            />
          </Grid>
          <Grid item md={6} xs={10}>
            <TextInput
              error={infoError.lastName}
              fullwidth
              label={t("booking.form.lastName")}
              onChange={(value) => setInfo({ ...info, lastName: value })}
              value={info.lastName}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item container spacing={4} md={8} xs={10}>
        <Grid item md={8} xs={10}>
          <MuiTelInput
            fullWidth
            defaultCountry="MA"
            error={infoError.phone}
            label={t("signUp.form.phone")}
            onChange={(value, muiInfo) => {
              setInfo({
                ...info,
                phone: value,
                country: muiInfo?.countryCode || "MA",
              });
            }}
            value={info.phone}
          />
        </Grid>
        <Grid item md={4} xs={10}>
          <TextInput
            fullwidth
            error={infoError.numberOfPeople}
            type="number"
            label={t("booking.form.numberOfPeople")}
            onChange={(value) => setInfo({ ...info, numberOfPeople: value })}
            value={info.numberOfPeople}
          />
        </Grid>
      </Grid>
      <Grid item container md={8} xs={10}>
        <Grid item md={6}>
          <TextInput
            fullwidth
            error={infoError.email}
            label={t("booking.form.email")}
            onChange={(value) => setInfo({ ...info, email: value })}
            value={info.email}
            type="email"
          />
        </Grid>
      </Grid>
      <Grid item md={8} xs={10}>
        <Box sx={{ width: "50%", marginRight: "auto", marginLeft: "auto" }}>
          <CustomLoadingButton
            disabled={!canCreate}
            onSubmit={handleOnContinue}
            loading={false}
            label={t("booking.form.submit")}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BookingForm;
