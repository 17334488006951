import { useContext, useEffect, useState } from "react";
import { groupedBookingsByDateType } from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { UserContext } from "src/Utils/Context/UserContext";

interface DashEvent {
  bookingsProgress: number;
  seatsFilled: number;
  seatsFilledProgress: number;
  totalBookings: number;
  groupedBookingsByDate: groupedBookingsByDateType;
}

interface DashEventResponse {
  success: boolean;
  bookingEvents: DashEvent;
}

interface Props {
  start_date: string;
  end_date: string;
  previous_kpi_start_date: string;
  restaurant_ids: string;
}

const useGetDashEvents = ({
  start_date,
  end_date,
  previous_kpi_start_date,
  restaurant_ids,
}: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashEvents, setDashEvents] = useState<DashEvent>({
    bookingsProgress: 0,
    seatsFilled: 0,
    seatsFilledProgress: 0,
    totalBookings: 0,
    groupedBookingsByDate: {},
  });
  const { userInfo } = useContext(UserContext);

  useEffect(() => {
    setIsLoading(true);
    fetch("https://laddition.ma/Website/GetMainDashEvents.php", {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        selected_frame_start: start_date,
        selected_frame_end: end_date,
        previous_frame_start: previous_kpi_start_date,
        previous_frame_end: start_date,
        restaurant_ids: restaurant_ids,
      }),
    })
      .then((response) => response.json())
      .then((data: DashEventResponse) => {
        if (data.success) {
          setDashEvents(data.bookingEvents);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  }, [
    end_date,
    previous_kpi_start_date,
    start_date,
    userInfo?.id,
    userInfo?.session_token,
    restaurant_ids,
  ]);

  return { isLoading, dashEvents };
};

export default useGetDashEvents;
