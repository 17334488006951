import { Typography } from "@mui/material";
import { ElementType, useEffect, useState } from "react";
import { darkBlue } from "src/design/ColorPalette";
import "typeface-nunito-sans";

export interface CustomTextProps {
  component?: ElementType;
  label: string;
  type?: "header" | "subheader" | "section" | "body" | "helper";
  size?: React.CSSProperties["fontSize"];
  weight?: React.CSSProperties["fontWeight"];
  fontcolor?: React.CSSProperties["color"];
  wrap?: boolean;
  textWrap?: React.CSSProperties["textWrap"];
  innerHtml?: boolean;
  fontFamily?: React.CSSProperties["fontFamily"];
}

export const CustomText = ({
  label,
  type,
  size,
  component = "p",
  weight = "normal",
  fontcolor = darkBlue,
  wrap,
  textWrap,
  innerHtml = false,
  fontFamily = "typeface-nunito-sans",
}: CustomTextProps) => {
  const [fontWeight, setfontWeight] =
    useState<React.CSSProperties["fontWeight"]>(weight);
  const [color, setcolor] = useState<React.CSSProperties["color"]>(fontcolor);
  const [fontSize, setfontSize] = useState<React.CSSProperties["fontSize"]>();

  useEffect(() => {
    setcolor(fontcolor);
  }, [fontcolor]);

  const changeColor = (selectedColor: React.CSSProperties["color"]) => {
    if (fontcolor === "rgb(82, 91, 117)") {
      setcolor(selectedColor);
    }
  };

  useEffect(() => {
    switch (type) {
      case "header":
        setfontWeight("bold");
        changeColor("rgb(20, 24, 36)");
        setfontSize("30px");
        break;
      case "subheader":
        setfontSize("16px");
        break;
      case "section":
        setfontWeight("bold");
        changeColor("rgb(20, 24, 36)");
        setfontSize("28px");
        break;
      case "body":
        setfontSize("18px");
        break;
      case "helper":
        setfontSize("12px");
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  return innerHtml ? (
    <Typography
      dangerouslySetInnerHTML={{ __html: label }}
      whiteSpace={wrap ? "pre-line" : "nowrap"}
      sx={{
        textWrap: textWrap || "balance",
      }}
      component={component}
      fontSize={size || fontSize}
      color={color}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
    />
  ) : (
    <Typography
      sx={{ textWrap: textWrap || "balance" }}
      component={component}
      fontSize={size || fontSize}
      color={color}
      fontWeight={fontWeight}
      fontFamily={fontFamily}
    >
      {label}
    </Typography>
  );
};
