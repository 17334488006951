import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { en } from "./Utils/Translations/En";
import { fr } from "./Utils/Translations/Fr";

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "fr",
    resources: {
      en: en,
      fr: fr,
    },
  });
