import { groupedBookingsByDayType } from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { BarChart } from "src/design/BarChart";
import { useTranslation } from "react-i18next";
import { ElevatedPaper } from "src/design";

interface Props {
  groupedBookingsByDay?: groupedBookingsByDayType;
}

const BookingTrendByDay = ({ groupedBookingsByDay }: Props) => {
  const { t, i18n } = useTranslation();
  const language = i18n.language;
  if (!groupedBookingsByDay) return null;

  const dataKeys =
    language === "en"
      ? ["mon", "tue", "wed", "thu", "fri", "sat", "sun"]
      : ["lun", "mar", "mer", "jeu", "ven", "sam", "dim"];

  const data =
    groupedBookingsByDay.length > 0
      ? (Array(23)
          .fill(0)
          .map((_, index) => {
            return language === "en"
              ? {
                  name: (index + 1).toString(),
                  mon: groupedBookingsByDay[0][`${index}`] || 0,
                  tue: groupedBookingsByDay[1][`${index}`] || 0,
                  wed: groupedBookingsByDay[2][`${index}`] || 0,
                  thu: groupedBookingsByDay[3][`${index}`] || 0,
                  fri: groupedBookingsByDay[4][`${index}`] || 0,
                  sat: groupedBookingsByDay[5][`${index}`] || 0,
                  sun: groupedBookingsByDay[6][`${index}`] || 0,
                }
              : {
                  name: (index + 1).toString(),
                  lun: groupedBookingsByDay[0][`${index}`] || 0,
                  mar: groupedBookingsByDay[1][`${index}`] || 0,
                  mer: groupedBookingsByDay[2][`${index}`] || 0,
                  jeu: groupedBookingsByDay[3][`${index}`] || 0,
                  ven: groupedBookingsByDay[4][`${index}`] || 0,
                  sam: groupedBookingsByDay[5][`${index}`] || 0,
                  dim: groupedBookingsByDay[6][`${index}`] || 0,
                };
          }) as any as Record<string, string | number>[])
      : [];

  return (
    <ElevatedPaper
      elevation={8}
      padding={0}
      outerPadding={0}
      borderTop={"4px solid #1abc9c"}
      innerRadius={0}
      outerRadius={0}
      textAlign="center"
    >
      <BarChart
        data={data}
        dataKeys={dataKeys}
        tooltip={t("statistics.bookingsTrendByDay.tooltip")}
        title={t("statistics.bookingsTrendByDay.title")}
        Xlabel={t("statistics.bookingsTrendByDay.label")}
      />
    </ElevatedPaper>
  );
};

export default BookingTrendByDay;
