import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import { useMediaQuery } from "@mui/material";
import Paper from "@mui/material/Paper";
import { useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { Grid } from "@mui/material";
import { ElevatedPaper, CustomText, ImageUpload } from "src/design";
import { SnackBar } from "src/design";

export interface Image {
  id: string;
  data_url: string;
  file: any;
}

interface ImageGalleryProps {
  restaurant_id: string;
  id: string;
  session_token: string;
  menu?: number;
  header: string;
  subheader: string;
  successMessage: string;
  errorMessage: string;
}

const ImageGallery = ({
  restaurant_id,
  id,
  session_token,
  menu = 0,
  header,
  successMessage,
  errorMessage,
  subheader,
}: ImageGalleryProps) => {
  const [canSave, setCanSave] = useState(false);
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const [images, setImages] = useState<Image[]>([]);
  const [saving, setSaving] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(3);
  const [uploadSucess, setUploadSucess] = useState(false);
  const [uploadError, setUploadError] = useState(false);

  const handleChangePage = (
    event: any,
    newPage: React.SetStateAction<number>
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: {
    target: { value: string | number };
  }) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChange = (imageList: React.SetStateAction<Image[]>) => {
    setCanSave(true);
    setImages(imageList);
  };

  useEffect(() => {
    if (images.length == 0) {
      GetImages();
    }
  }, []);

  const GetImages = () => {
    fetch(`https://laddition.ma/Website/GetRestaurantImages.php`, {
      method: "POST",
      body: JSON.stringify({
        restaurant_id: restaurant_id,
        ownerId: id,
        ownerToken: session_token,
        menu: menu,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setImages(response.images);
        }
      });
  };

  const uploadimg = () => {
    setSaving(true);
    const formData = new FormData();
    if (images.length > 0) {
      images.forEach((image, index) => {
        if (image.file) {
          formData.append("images[]", image.file);
        }
      });
    }
    formData.append("ownerId", id);
    formData.append("menu", menu.toString());
    formData.append("ownerToken", session_token);
    formData.append("restaurant_id", restaurant_id);
    fetch(`https://laddition.ma/Website/UploadRestaurantImages.php`, {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          GetImages();
          setUploadSucess(true);
        } else {
          setUploadError(true);
        }
        setSaving(false);
      });
  };

  const DeleteImg = (value: Image) => {
    const index = images.indexOf(value);
    if (images[index].id) {
      fetch(`https://laddition.ma/Website/DeleteImage.php`, {
        method: "POST",
        body: JSON.stringify({
          ownerId: id,
          ownerToken: session_token,
          id: images[index].id,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          GetImages();
          setSaving(false);
        });
    }
    var newImageList = [...images];
    newImageList.splice(index, 1);
    setImages(newImageList);
  };

  return (
    <Grid item md={5.8} xs={12}>
      <ElevatedPaper elevation={5} fullwidth padding={20}>
        <CustomText label={header} type="header" />
        <CustomText label={subheader} />
        {images.length == 0 ? (
          <Row className="align-items-center">
            <Col className="text-center">
              <img
                style={{ width: "50%", height: "50%" }}
                src={require("src/assets/NoImage.png")}
              />
            </Col>
          </Row>
        ) : (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="Table of images">
              <TableHead>
                <TableRow>
                  <TableCell>Image</TableCell>
                  <TableCell align="center">Supprimer</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {images
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((value, index) => {
                    const uri = `https://laddition.ma/Website/restaurant_images/restaurant${restaurant_id}/${restaurant_id}_${value["data_url"]}.webp`;
                    return (
                      <TableRow key={index}>
                        <TableCell>
                          <img
                            src={value["id"] ? uri : value["data_url"]}
                            height={matchDownSM ? "50" : "100"}
                            width={matchDownSM ? "100" : "150"}
                          />
                        </TableCell>
                        <TableCell align="center">
                          <IconButton
                            color="error"
                            size={matchDownSM ? "small" : "medium"}
                            edge="end"
                            aria-label="comments"
                            onClick={() => DeleteImg(value)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[3, 5, 10]}
              component="div"
              count={images.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              sx={{
                alignItems: "center",
                "& p": {
                  margin: 0,
                },
              }}
            />
          </TableContainer>
        )}
        <div>
          <ImageUpload
            image={images}
            uploadimg={uploadimg}
            uploading={saving}
            setImages={(imageList: any) => onChange(imageList)}
            multiple
            uploadLabel="Ajouter une photo"
            canSave={canSave}
          />
        </div>
      </ElevatedPaper>
      <SnackBar
        onClose={() => setUploadSucess(false)}
        open={uploadSucess}
        message={successMessage}
        severity="success"
      />
      <SnackBar
        onClose={() => setUploadError(false)}
        open={uploadError}
        message={errorMessage}
        severity="error"
      />
    </Grid>
  );
};

export default ImageGallery;
