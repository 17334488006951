import { TextKpi } from "src/design";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface Props {
  occupancyRate?: number;
  progress?: number;
}

const OccupancyKpi = ({ occupancyRate, progress }: Props) => {
  const { t } = useTranslation();

  return (
    <Grid item md={6} xs={12}>
      <TextKpi
        title={t("statistics.occupancyLabel")}
        value={
          occupancyRate
            ? Math.floor(occupancyRate * 100).toString() + "%"
            : "N/A"
        }
        progress={progress}
      />
    </Grid>
  );
};

export default OccupancyKpi;
