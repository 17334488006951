import { Cities } from "../../../Utils/Data";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { useState, useContext } from "react";
import Col from "react-bootstrap/Col";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { MuiTelInput } from "mui-tel-input";
import { TextInput } from "../../../design/TextInput";
import { ConfigurationContext } from "src/Utils/Context/ConfigurationContext";
import { MultiSelect } from "src/design";
import { useTranslation } from "react-i18next";
import { UserContext } from "src/Utils/Context/UserContext";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { useNavigate } from "react-router-dom";

const style = {
  p: 4,
  borderRadius: 12,
};

export const AddRestaurant = () => {
  const { userInfo } = useContext(UserContext);
  const { refetch } = useContext(RestaurantContext);
  const { id, session_token } = userInfo;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { constants } = useContext(ConfigurationContext);
  const [error, setError] = useState(false);
  const [wrongInfo, setwrongInfo] = useState({
    Addresse: false,
    NomRestaurant: false,
    Cuisine: false,
    Places: false,
    City: false,
  });
  const [loading, setloading] = useState(false);
  const [Info, setInfo] = useState({
    TPE: false,
    Addresse: "",
    NomRestaurant: "",
    Cuisine: [],
    Places: 20,
    City: -1,
    Quartier: "",
    Phone: "",
    Booze: false,
    IgLink: "",
    Animation: false,
  });

  const handleClose = () => {
    setInfo({
      TPE: false,
      Addresse: "",
      NomRestaurant: "",
      Cuisine: [],
      Places: 20,
      City: -1,
      Quartier: "",
      Phone: "",
      Booze: false,
      IgLink: "",
      Animation: false,
    });
    navigate("/restaurant/restaurant-list");
    setloading(false);
  };
  const HandleChange = (text, type) => {
    setInfo((oldInfo) => {
      switch (type) {
        case "NomRestaurant":
          return { ...oldInfo, NomRestaurant: text };
        case "Addresse":
          return { ...oldInfo, Addresse: text };
        case "Places":
          return { ...oldInfo, Places: text };
        case "Phone":
          return { ...oldInfo, Phone: text };
        case "IgLink":
          return { ...oldInfo, IgLink: text };
        case "Booze":
          return { ...oldInfo, Booze: text };
        case "Animation":
          return { ...oldInfo, Animation: text };
        case "Quartier":
          return { ...oldInfo, Quartier: text };
      }
    });
  };

  const handleSubmit = () => {
    setloading(true);
    if (
      Info.NomRestaurant &&
      Info.Places > 0 &&
      !isNaN(Info.Places) &&
      Info.Cuisine.length > 0 &&
      Info.Addresse &&
      Info.City != -1 &&
      Info.Phone &&
      Info.Quartier
    ) {
      setError(false);
      setwrongInfo({
        NomRestaurant: false,
        Places: false,
        Cuisine: false,
        Addresse: false,
        City: false,
      });
      Submit();
    } else {
      setloading(false);
      setError(true);
      setwrongInfo({
        NomRestaurant: !Info.NomRestaurant,
        Places: !(Info.Places > 0 && !isNaN(Info.Places)),
        Cuisine: !Info.Cuisine.length > 0,
        Addresse: !Info.Addresse,
        City: Info.City === -1,
      });
    }
  };

  const Submit = () => {
    fetch(`https://laddition.ma/Website/AddRestaurant.php`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        name: Info.NomRestaurant,
        city: Info.City,
        quartier: Info.Quartier,
        phone: Info.Phone,
        addresse: Info.Addresse,
        payment_method: Info.TPE,
        category: Info.Cuisine.join(";"),
        number_tables: Info.Places,
        booze: Info.Booze,
        animation: Info.Animation,
        insta_link: Info.IgLink,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          refetch();
          setloading(false);
          handleClose();
        }
      })
      .catch((e) => {
        setloading(false);
      });
  };

  return (
    <Box sx={style}>
      <h2
        id="parent-modal-title"
        style={{
          color: "#16a085",
          fontFamily: "Garamond, serif",
          marginBottom: 20,
        }}
      >
        Entrez les informations relatives à votre restaurant
      </h2>
      {error ? (
        <Alert variant={"danger"}>
          <Alert.Heading>Certaines Informations sont erronées!</Alert.Heading>
          {wrongInfo.NomRestaurant ? (
            <p>- Vous devez Renseigner le nom de votre Restaurant</p>
          ) : (
            <></>
          )}
          {wrongInfo.Places ? (
            <p>
              - Vous devez Renseigner le nombre de tables à votre disposition
            </p>
          ) : (
            <></>
          )}
          {wrongInfo.Cuisine ? (
            <p>- Vous devez Choisir au moins un type de cuisine</p>
          ) : (
            <></>
          )}
          {wrongInfo.Addresse ? (
            <p>
              - Vous devez Renseigner l'address physique de votre Restaurant
            </p>
          ) : (
            <></>
          )}
        </Alert>
      ) : (
        <></>
      )}
      <Form style={{ display: "grid", gap: 16 }}>
        <TextInput
          value={Info.NomRestaurant}
          onChange={(value) => {
            HandleChange(value, "NomRestaurant");
          }}
          color={wrongInfo.NomRestaurant ? "warning" : "primary"}
          label="Quel est le nom de votre restaurant ?"
          fullwidth
          required
        />
        <TextInput
          value={Info.Addresse}
          onChange={(value) => {
            HandleChange(value, "Addresse");
          }}
          color={wrongInfo.Addresse ? "warning" : "primary"}
          label="Addresse Physique (rue, code postale, ville)"
          fullwidth
          required
        />
        <TextInput
          value={Info.Quartier}
          onChange={(value) => {
            setInfo((oldInfo) => ({ ...oldInfo, Quartier: value }));
          }}
          label="Quartier"
          fullwidth
          required
        />
        <Box>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Ville</InputLabel>
            <Select
              required
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={Info.City}
              label="Ville"
              onChange={(event) => {
                setInfo((oldInfo) => {
                  return { ...oldInfo, City: event.target.value };
                });
              }}
            >
              {Cities.map((value) => (
                <MenuItem value={value.city}>{value.city}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        <MultiSelect
          value={Info.Cuisine}
          label={t("common.tags")}
          options={constants.tags}
          onChange={(value) => {
            setInfo((oldInfo) => {
              return { ...oldInfo, Cuisine: value };
            });
          }}
        />
        <MuiTelInput
          required
          value={Info.Phone}
          onChange={(value) => {
            HandleChange(value, "Phone");
          }}
          defaultCountry="MA"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <h5 style={{ width: "50%" }}>Acceptez-vous le TPE ?</h5>
          <Button
            color="success"
            as={Col}
            variant={Info.TPE ? "contained" : "outlined"}
            style={{
              marginRight: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, TPE: true };
              });
            }}
          >
            Oui
          </Button>
          <Button
            color="success"
            variant={!Info.TPE ? "contained" : "outlined"}
            as={Col}
            style={{
              marginLeft: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, TPE: false };
              });
            }}
          >
            Non
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <h5 style={{ width: "50%" }}>
            Votre Restaurant offre-t-il de l'animation ?
          </h5>
          <Button
            color="success"
            as={Col}
            variant={Info.Animation ? "contained" : "outlined"}
            style={{
              marginRight: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, Animation: true };
              });
            }}
          >
            Oui
          </Button>
          <Button
            color="success"
            variant={!Info.Animation ? "contained" : "outlined"}
            as={Col}
            style={{
              marginLeft: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, Animation: false };
              });
            }}
          >
            Non
          </Button>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: 15,
          }}
        >
          <h5 style={{ width: "50%" }}>
            Votre restaurant offre-t-il de l'acool ?
          </h5>
          <Button
            color="success"
            as={Col}
            variant={Info.Booze ? "contained" : "outlined"}
            style={{
              marginRight: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, Booze: true };
              });
            }}
          >
            Oui
          </Button>
          <Button
            color="success"
            variant={!Info.Booze ? "contained" : "outlined"}
            as={Col}
            style={{
              marginLeft: 7,
              cursor: "pointer",
              textAlign: "center",
            }}
            onClick={() => {
              setInfo((oldInfo) => {
                return { ...oldInfo, Booze: false };
              });
            }}
          >
            Non
          </Button>
        </div>
        <TextInput
          value={Info.Places}
          onChange={(value) => {
            HandleChange(value, "Places");
          }}
          label="Combien de personnes votre restaurant peut-il acceuillir?"
          color={wrongInfo.Places ? "warning" : "primary"}
          fullwidth
          type="number"
          required
        />
        <TextInput
          value={Info.IgLink}
          onChange={(value) => {
            HandleChange(value, "IgLink");
          }}
          label="Instagram Link"
          fullwidth
        />
      </Form>
      <Box style={{ display: "flex", justifyContent: "end", marginTop: 20 }}>
        <Button
          color="success"
          variant="contained"
          onClick={handleSubmit}
          disabled={loading}
        >
          {loading ? "Creation ..." : "Sauveguarder"}
        </Button>
      </Box>
    </Box>
  );
};
