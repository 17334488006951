import { useState, useEffect } from "react";
import bookingEvents from "src/components/Main/Pages/UserDashboard/types/bookingEvents";
import { userEvent } from "src/components/Main/Pages/UserDashboard/types/userEvent";
import dayjs from "dayjs";

interface GetRestaurantEventsResponse {
  success: boolean;
  userEvent: userEvent;
  bookingEvents: bookingEvents;
}

interface Events {
  userEvent: userEvent;
  bookingEvents: bookingEvents;
}

interface Props {
  id?: string;
  session_token?: string;
  start_date?: string;
  end_date?: string;
  restaurant_id?: string;
  current_kpi_start?: string;
  current_kpi_end?: string;
  previous_kpi_start?: string;
}

const generateDateArray = ({
  startDate,
  endDate,
}: {
  startDate: string;
  endDate: string;
}) => {
  let currentDate = dayjs(startDate);
  const end = dayjs(endDate);
  const dateArray = [];

  while (currentDate.isBefore(end) || currentDate.isSame(end)) {
    dateArray.push(currentDate.format("YYYY-MM-DD"));
    currentDate = currentDate.add(1, "day");
  }

  return dateArray;
};

const useGetEvents = ({
  id,
  session_token,
  start_date,
  end_date,
  restaurant_id,
  current_kpi_start,
  current_kpi_end,
  previous_kpi_start,
}: Props) => {
  const [events, setEvents] = useState<Events>();
  const [loading, setLoading] = useState<boolean>(true);
  const [dateArray, setDateArray] = useState<string[]>([]);
  const [temporaryEvents, setTemporaryEvents] = useState<Events>();

  useEffect(() => {
    if (!temporaryEvents) return;

    var newEvents = { ...temporaryEvents };

    dateArray.forEach((value, index) => {
      if (!newEvents.userEvent.MenuOpenned[value]) {
        newEvents.userEvent.MenuOpenned[value] = 0;
      }
      if (!newEvents.userEvent.RestaurantClicked[value]) {
        newEvents.userEvent.RestaurantClicked[value] = 0;
      }
      if (!newEvents.userEvent.BookingsOpened[value]) {
        newEvents.userEvent.BookingsOpened[value] = 0;
      }
    });

    setEvents({
      userEvent: newEvents.userEvent,
      bookingEvents: newEvents.bookingEvents,
    });

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [dateArray, temporaryEvents]);

  useEffect(() => {
    setLoading(true);
    if (!id || !session_token || !start_date || !restaurant_id) return;
    setDateArray(
      generateDateArray({
        startDate: start_date,
        endDate: end_date || start_date,
      })
    );
    fetch("https://laddition.ma/Website/GetRestaurantEvents.php", {
      method: "POST",
      body: JSON.stringify({
        ownerId: id,
        ownerToken: session_token,
        selected_frame_start: start_date,
        selected_frame_end: end_date,
        previous_frame_start: previous_kpi_start,
        previous_frame_end: start_date,
        restaurant_id: restaurant_id,
      }),
    })
      .then((response) => response.json())
      .then((data: GetRestaurantEventsResponse) => {
        if (data.success) {
          setTemporaryEvents({
            userEvent: data.userEvent,
            bookingEvents: data.bookingEvents,
          });
        }
      });
  }, [
    current_kpi_end,
    current_kpi_start,
    end_date,
    id,
    previous_kpi_start,
    restaurant_id,
    session_token,
    start_date,
  ]);

  return { events, loading, dateArray };
};

export default useGetEvents;
