import { useState, useContext } from "react";
import { TailSpin } from "react-loader-spinner";
import Paper from "@mui/material/Paper";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AddIcon from "@mui/icons-material/Add";
import { Grid, MenuItem, FormControl, InputLabel } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import { CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { transparentEmerald } from "src/design/ColorPalette";
import { Cities } from "src/Utils/Data";
import { UserContext } from "src/Utils/Context/UserContext";
import Toolbar from "@mui/material/Toolbar";
import Select from "@mui/material/Select";

const TableHeader = ({ CityFilter, SelectedCity, setSelectedFilter }) => (
  <Toolbar
    sx={{
      pl: { sm: 2 },
      pr: { xs: 1, sm: 1 },
    }}
  >
    <Grid container>
      <Grid item xs={12} md={6} lg={4}>
        <Grid container width={"60%"} justifyContent="space-evenly">
          <FormControl fullWidth variant="standard">
            <InputLabel id="demo-simple-select-label">
              Restaurant City
            </InputLabel>
            <Select
              label={"City"}
              value={SelectedCity}
              onChange={(event) => setSelectedFilter(event.target.value)}
              fullWidth
            >
              {CityFilter.map((res, index) => {
                return (
                  <MenuItem value={res} key={index}>
                    {res}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  </Toolbar>
);

const lightTheme = createTheme({ palette: { mode: "light" } });

const Acceuil = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { userInfo } = useContext(UserContext);
  const { isLoading, restaurants } = useContext(RestaurantContext);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [selectedCity, setSelectedCity] = useState("");
  const CitiesFilter = Cities.map((city) => city.city);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const onChange = (value) => {
    setPage(0);
    setSelectedCity(value);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <CustomText label={t("restaurant.restaurantList.header")} type="header" />
      {isLoading ? (
        <TailSpin color="#00BFFF" height={80} width={80} />
      ) : (
        <Paper sx={{ width: "100%", marginTop: 2 }}>
          {userInfo.admin && (
            <TableHeader
              CityFilter={CitiesFilter}
              SelectedCity={selectedCity}
              setSelectedFilter={onChange}
            />
          )}
          <TableContainer sx={{ maxHeight: 480 }}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell align="left"></TableCell>
                  <TableCell align="center">
                    {t("restaurant.restaurantList.restaurantName")}
                  </TableCell>
                  <TableCell align="center">
                    {t("restaurant.restaurantList.city")}
                  </TableCell>
                  <TableCell align="center">
                    {t("restaurant.restaurantList.address")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {userInfo.admin && (
                  <TableRow>
                    <TableCell
                      align="left"
                      sx={{
                        cursor: "pointer",
                        ":hover": {
                          backgroundColor: transparentEmerald,
                        },
                      }}
                      colSpan={5}
                      onClick={() => {
                        navigate("/restaurant/add-restaurant");
                      }}
                    >
                      <Grid container alignItems="center">
                        <AddIcon fontSize="medium" />
                        <Typography variant="h6">
                          Ajouter un nouveau restaurant
                        </Typography>
                      </Grid>
                    </TableCell>
                  </TableRow>
                )}
                {restaurants
                  .filter(
                    (res) => selectedCity === "" || res.City === selectedCity
                  )
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((res, index) => {
                    const uri = `https://laddition.ma/Website/restaurant_images/restaurant${res.restaurant_id}/${res.restaurant_id}_${res.Uri}.webp`;
                    return (
                      <TableRow
                        key={index}
                        sx={{ ":hover": { cursor: "pointer" } }}
                        onClick={() => {
                          navigate(`/restaurant/${res.restaurant_id}`);
                        }}
                      >
                        <TableCell align="left">
                          <img
                            src={
                              res.Uri != null
                                ? uri
                                : require("src/assets/resIcon.png")
                            }
                            width="110"
                            height="70"
                            style={{
                              borderRadius: 16,
                            }}
                            alt=""
                          />
                        </TableCell>
                        <TableCell align="center">
                          <CustomText label={res.Nom} />
                        </TableCell>
                        <TableCell align="center">
                          <CustomText label={res.City} />
                        </TableCell>
                        <TableCell align="center">
                          <CustomText label={res.Addresse} />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            labelRowsPerPage={t("restaurant.restaurantList.rowsPerPage")}
            rowsPerPageOptions={[5, 10, 25, 100]}
            component="div"
            count={restaurants.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            sx={{
              alignItems: "center",
              "& p": {
                margin: 0,
              },
            }}
          />
        </Paper>
      )}
    </ThemeProvider>
  );
};

export default Acceuil;
