import { Box, Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

const LanguageChangeButton = ({ left }: { left?: boolean }) => {
  const { i18n } = useTranslation();

  return (
    <Box
      sx={[
        { position: "fixed", bottom: 0, zIndex: 9999 },
        left ? { left: 0 } : { right: 0 },
      ]}
    >
      <Grid container direction="column">
        <Button
          onClick={() => {
            i18n.changeLanguage("en");
          }}
        >
          <img
            src={require("src/assets/English.png")}
            style={{ width: 25, height: 20 }}
            alt="English language"
          />
        </Button>
        <Button
          onClick={() => {
            i18n.changeLanguage("fr");
          }}
        >
          <img
            src={require("src/assets/French.png")}
            style={{ width: 25, height: 20 }}
            alt="French language"
          />
        </Button>
      </Grid>
    </Box>
  );
};

export default LanguageChangeButton;
