import { useParams } from "react-router-dom";
import BookingsPage from "src/components/Main/Pages/Bookings";
import { CustomText } from "src/design";

const AdminBookings = () => {
  const { restaurantId } = useParams();

  if (!restaurantId)
    return (
      <div>
        <CustomText label="Select a restaurant to proceed" type="section" />
      </div>
    );

  return <BookingsPage restaurant_id={restaurantId} />;
};

export default AdminBookings;
