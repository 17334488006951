import { Grid, Typography } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import IconButton from "@mui/material/IconButton";
import StarIcon from "@mui/icons-material/Star";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Dispatch, SetStateAction } from "react";
import type {
  SectionItem,
  Section,
} from "src/components/Main/Pages/Home/RestaurantInformation/Menu";
import ItemField from "src/components/Main/Pages/Home/RestaurantInformation/Menu/ItemField";
import { useTranslation } from "react-i18next";

interface MenuSectionProps {
  sectionName: string;
  sectionActive: boolean;
  items: SectionItem[];
  featuredItem: number;
  index: number;
  setSections: Dispatch<SetStateAction<Section[]>>;
  editing: boolean;
  setEditing: Dispatch<SetStateAction<boolean>>;
}

const MenuSection = ({
  sectionName,
  sectionActive,
  items,
  featuredItem,
  index,
  setSections,
  editing,
  setEditing,
}: MenuSectionProps) => {
  const { t } = useTranslation();

  const HandleChange = (
    type: keyof SectionItem,
    value: string,
    itemIndex: number
  ) => {
    setSections((oldSections: Section[]) => {
      var newSections = [...oldSections];
      Object.defineProperty(newSections[index].items[itemIndex], type, {
        value: value,
      });
      return newSections;
    });
  };

  const HandleDelete = (itemIndex: number) => {
    setSections((oldSections: Section[]) => {
      var newSections = [...oldSections];
      if (newSections[index].items.length === 1) {
        newSections[index].items[itemIndex] = {
          name: "",
          price: "",
          description: "",
          featured: 1,
        };
      } else {
        if (featuredItem === itemIndex) {
          HandleFavorite(0);
        }
        newSections[index].items.splice(itemIndex, 1);
      }
      return newSections;
    });
  };

  const HandleFavorite = (itemIndex: number) => {
    setSections((oldSections: Section[]) => {
      var newSections = [...oldSections];
      newSections[index].featuredItem = itemIndex;
      return newSections;
    });
  };

  return (
    <Grid item md={7} key={index}>
      <Grid container alignItems="center" style={{ marginBottom: 15 }}>
        <Grid item>
          <ItemField
            label="Section name"
            disabled={!sectionActive}
            editing={editing}
            value={sectionName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSections((oldSections: Section[]) => {
                let newSections = [...oldSections];
                newSections[index].name = event.target.value;
                return newSections;
              });
            }}
            color="#1abc9c"
            type="section"
          />
        </Grid>
        <IconButton
          onClick={() => {
            setSections((oldSections: Section[]) => {
              var newSections = [...oldSections];
              newSections[index].active = !sectionActive;
              return newSections;
            });
          }}
        >
          <CheckCircleOutlineIcon
            fontSize="small"
            color={sectionActive ? "success" : "disabled"}
          />
        </IconButton>
      </Grid>
      {items.map((item, itemIndex) => (
        <Grid container alignItems="flex-start" key={itemIndex}>
          <Grid item md={1}>
            <IconButton
              color="warning"
              onClick={() => HandleFavorite(itemIndex)}
              disabled={!sectionActive}
            >
              <StarIcon
                color={featuredItem === itemIndex ? "warning" : "disabled"}
              />
            </IconButton>
            {editing && (
              <IconButton
                color="error"
                onClick={() => HandleDelete(itemIndex)}
                disabled={!sectionActive}
              >
                <DeleteForeverIcon
                  color={sectionActive ? "error" : "disabled"}
                />
              </IconButton>
            )}
          </Grid>
          <Grid item md={10}>
            <Grid container justifyContent="space-between">
              <Grid item md={8}>
                <ItemField
                  editing={editing}
                  label={t(
                    "restaurant.restaurantInfoPage.menu.section.itemName"
                  )}
                  disabled={!sectionActive}
                  fullWidth
                  value={item.name}
                  onChange={(event) => {
                    HandleChange("name", event.target.value, itemIndex);
                  }}
                />
              </Grid>
              <Grid item md={2}>
                <ItemField
                  endAdornment="dh"
                  editing={editing}
                  disabled={!sectionActive}
                  label={t(
                    "restaurant.restaurantInfoPage.menu.section.itemPrice"
                  )}
                  inputType="number"
                  value={item.price}
                  onChange={(event) => {
                    HandleChange("price", event.target.value, itemIndex);
                  }}
                />
              </Grid>
            </Grid>
            <ItemField
              disabled={!sectionActive}
              label={t(
                "restaurant.restaurantInfoPage.menu.section.itemDescription"
              )}
              editing={editing}
              type="subheader"
              fullWidth
              value={item.description}
              onChange={(event) => {
                HandleChange("description", event.target.value, itemIndex);
              }}
            />
          </Grid>
        </Grid>
      ))}
      <IconButton
        disabled={
          !sectionActive ||
          items[items.length - 1].name === "" ||
          items[items.length - 1].price === ""
        }
        style={{ marginTop: 10 }}
        color="primary"
        onClick={() => {
          setSections((oldSections: Section[]) => {
            var newSections = [...oldSections];
            newSections[index].items.push({
              name: "",
              price: "",
              description: "",
              featured: 1,
            });
            return newSections;
          });
          setEditing(true);
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            gap: 5,
          }}
        >
          <AddCircleOutlineIcon
            fontSize="small"
            color={
              !sectionActive ||
              items[items.length - 1].name === "" ||
              items[items.length - 1].price === ""
                ? "disabled"
                : "primary"
            }
          />
          <Typography
            color={
              !sectionActive ||
              items[items.length - 1].name === "" ||
              items[items.length - 1].price === ""
                ? "disabled"
                : "primary"
            }
          >
            {t("restaurant.restaurantInfoPage.menu.addDish")}
          </Typography>
        </div>
      </IconButton>
    </Grid>
  );
};

export default MenuSection;
