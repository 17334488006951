import { useState, useRef } from "react";
import { Button, Grid, Popover, Box, Typography } from "@mui/material";
import { Divider } from "src/design";
import dayjs, { Dayjs } from "dayjs";
import { RangeCalendar } from "src/design/RangeCalendar";
import { transparentEmerald } from "src/design/ColorPalette";

interface Props {
  startDate: string;
  endDate: string;
  options: {
    starteIcon: JSX.Element;
    title: string;
    value: {
      startDay: Dayjs;
      endDay: Dayjs;
    } | null;
  }[];
  today: Dayjs;
  onRangeSelected: (startDay: Dayjs, endDay: Dayjs) => void;
  onSelectOption: (start: string, end: string, index: number) => void;
}

const DateRangePopover = ({
  startDate,
  endDate,
  options,
  today,
  onRangeSelected,
  onSelectOption,
}: Props) => {
  const anchorEl = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const [selectSpecificDate, setSelectSpecificDate] = useState(false);
  const [selectedOption, setSelectedOption] = useState<number | null>(null);

  return (
    <>
      <Popover
        anchorEl={anchorEl.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={open}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={() => setOpen(false)}
      >
        <Grid container width={anchorEl.current?.offsetWidth}>
          {selectSpecificDate ? (
            <RangeCalendar
              onRangeSelected={(start, end) => {
                onRangeSelected(start, end);
                setOpen(false);
                setSelectSpecificDate(false);
              }}
            />
          ) : (
            <Grid item xs={12}>
              {options.map((option, index) => (
                <Box
                  key={index}
                  sx={{
                    width: "100%",
                    textAlign: "left",
                    ":hover": {
                      backgroundColor: transparentEmerald,
                    },
                    backgroundColor:
                      selectedOption === index ? transparentEmerald : "white",
                  }}
                  onClick={() => {
                    setSelectedOption(index);
                    if (option.value === null) {
                      setSelectSpecificDate(true);
                    } else {
                      onSelectOption(
                        option.value.startDay.format("YYYY-MM-DD"),
                        today.format("YYYY-MM-DD"),
                        index
                      );

                      setOpen(false);
                    }
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      ":hover": {
                        backgroundColor: transparentEmerald,
                        cursor: "pointer",
                      },
                      gap: 2,
                      padding: 1,
                    }}
                  >
                    <>{option.starteIcon}</>
                    <Grid item>
                      <Typography>{option.title}</Typography>
                    </Grid>
                  </Box>
                  <Divider />
                </Box>
              ))}
            </Grid>
          )}
        </Grid>
      </Popover>
      <Button
        ref={anchorEl}
        sx={{
          borderRadius: 0,
          height: "100%",
          fontSize: "12px",
          lineBreak: "auto",
          whiteSpace: "pre-line",
        }}
        variant="outlined"
        color="info"
        onClick={() => setOpen(true)}
      >
        {`${dayjs(startDate).format("dddd, DD MMM YYYY").toString()} - ${dayjs(
          endDate
        )
          .format("dddd, DD MMM YYYY")
          .toString()}`}
      </Button>
    </>
  );
};

export default DateRangePopover;
