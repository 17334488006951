import { useGeolocated } from "react-geolocated";
import { useEffect, useState, useRef } from "react";
import GoogleMapReact from "google-map-react";
import { TailSpin } from "react-loader-spinner";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { Grid, Button } from "@mui/material";
import { ElevatedPaper, CustomText } from "src/design";
import { useTranslation } from "react-i18next";
import { CustomLoadingButton, SnackBar } from "src/design";

function Localisation({
  restaurant_id,
  City,
  loading,
  setInfo,
  coordinates,
  id,
  session_token,
}) {
  const {
    coords,
    isGeolocationAvailable,
    isGeolocationEnabled,
  } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const { t } = useTranslation();

  const [saving, setSaving] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [Marker, setMarker] = useState({
    lat: 0,
    lng: 0,
  });
  const [center, setcenter] = useState({
    lat: 0,
    lng: 0,
  });

  useEffect(() => {
    if (center.lat == 0 && center.lng == 0 && City != -1) {
      setcenter({
        lat: Number(City.lat),
        lng: Number(City.lng),
      });
    }
  }, [City]);

  useEffect(() => {
    if (coordinates.latitude != null) {
      setMarker({
        lat: coordinates.latitude,
        lng: coordinates.longitude,
      });
    }
  }, [coordinates]);

  const GetMapOptions = (maps) => {
    return {
      streetViewControl: false,
      scaleControl: true,
      fullscreenControl: false,
      styles: [
        {
          featureType: "poi.business",
          elementType: "labels",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
      ],
      gestureHandling: "greedy",
      disableDoubleClickZoom: true,
      minZoom: 1,
      maxZoom: 18,

      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.HORIZONTAL_BAR,
        position: maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [
          maps.MapTypeId.ROADMAP,
          maps.MapTypeId.SATELLITE,
          maps.MapTypeId.HYBRID,
        ],
      },

      zoomControl: true,
      clickableIcons: false,
    };
  };

  const SaveCoords = () => {
    if (Marker.lat != 0 && Marker.lng != 0) {
      setSaving(true);
      fetch(`https://laddition.ma/Website/SetRestaurantCoordinates.php`, {
        method: "POST",
        body: JSON.stringify({
          ownerId: id,
          ownerToken: session_token,
          restaurant_id: restaurant_id,
          latitude: Marker.lat,
          longitude: Marker.lng,
        }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setCanSave(false);
            setUpdateSuccess(true);
          } else {
            setUpdateError(true);
          }
          setSaving(false);
        });
    }
  };

  return (
    <Grid item width="100%">
      <ElevatedPaper elevation={5} fullwidth padding={20}>
        <CustomText
          label={t("restaurant.restaurantInfoPage.location.header")}
          type="header"
        />
        <CustomText
          label={t("restaurant.restaurantInfoPage.location.subheader")}
          type="subheader"
        />
        {loading && !coordinates ? (
          <div>
            <TailSpin color="#00BFFF" height={80} width={80} />
          </div>
        ) : (
          <>
            <div
              style={{
                height: "60vh",
                width: "90%",
                marginTop: 15,
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <GoogleMapReact
                options={GetMapOptions}
                bootstrapURLKeys={{
                  key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
                }}
                defaultCenter={center}
                center={{
                  lat:
                    coordinates == null
                      ? Number(City.lat)
                      : Number(coordinates.latitude),
                  lng:
                    coordinates == null
                      ? Number(City.lng)
                      : Number(coordinates.longitude),
                }}
                defaultZoom={15}
                onClick={(e) => {
                  setCanSave(true);
                  setInfo((oldInfo) => {
                    return { ...oldInfo, coords: e.lat + ";" + e.lng };
                  });
                  setMarker({
                    lat: e.lat,
                    lng: e.lng,
                  });
                }}
              >
                {Marker.lat != 0 && Marker.lng != 0 ? (
                  <div
                    style={{
                      height: "20px",
                      width: "20px",
                      borderRadius: 20,
                      border: "solid black 0.2px",
                      backgroundColor: "red",
                    }}
                    lat={Number(Marker.lat)}
                    lng={Number(Marker.lng)}
                  />
                ) : (
                  <></>
                )}
              </GoogleMapReact>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                style={{ marginTop: 20 }}
                variant="outlined"
                color="primary"
                ref={target}
                onClick={() => {
                  if (!isGeolocationEnabled) {
                    setShow(true);
                  } else {
                    setCanSave(true);
                    setInfo((oldInfo) => {
                      return {
                        ...oldInfo,
                        Coords: {
                          latitude: coords.latitude,
                          longitude: coords.longitude,
                        },
                      };
                    });
                    setMarker({
                      lat: coords.latitude,
                      lng: coords.longitude,
                    });
                    setShow(false);
                  }
                }}
              >
                {t("restaurant.restaurantInfoPage.location.myPosition")}
              </Button>
              <Overlay target={target.current} show={show} placement="right">
                {(props) => (
                  <Tooltip id="overlay-example" {...props}>
                    {t("restaurant.restaurantInfoPage.location.permissions")}
                  </Tooltip>
                )}
              </Overlay>
              <CustomLoadingButton
                style={{ marginTop: 20 }}
                color="success"
                variant="outlined"
                disabled={!canSave}
                loading={saving}
                onSubmit={SaveCoords}
                label={t("common.save")}
              />
            </div>
          </>
        )}
      </ElevatedPaper>
      <SnackBar
        open={updateSuccess}
        onClose={() => setUpdateSuccess(false)}
        severity="success"
        message={t("restaurant.restaurantInfoPage.location.uploadSuccess")}
      />
      <SnackBar
        severity="error"
        open={updateError}
        onClose={() => setUpdateError(false)}
        message={t("restaurant.restaurantInfoPage.location.uploadError")}
      />
    </Grid>
  );
}

export default Localisation;
