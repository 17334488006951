import { useState } from "react";
import DayHoursPicker from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/DayHoursPicker";
import { CustomModal, CustomText } from "src/design";
import { DEFAULT_HOURS } from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker/constants";
import type { DayHours } from "src/components/Main/Pages/Home/RestaurantInformation/HoursPicker";
import { Button, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

interface HoursPickerModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  onSave: (selectedHours: DayHours) => void;
}

const HoursPickerModal = ({ show, setShow, onSave }: HoursPickerModalProps) => {
  const { t } = useTranslation();
  const [typicalHours, setTypicalHours] = useState<DayHours>({
    ...DEFAULT_HOURS.monday,
  });

  const onClose = () => {
    setTypicalHours({ ...DEFAULT_HOURS.monday });
    setShow(false);
  };

  return (
    <CustomModal show={show} setShow={setShow}>
      <Grid container rowSpacing={3} justifyContent={"center"} width={"100%"}>
        <Grid item>
          <CustomText
            label={t(
              "restaurant.restaurantInfoPage.hoursPicker.applyToAllDays"
            )}
            type="header"
          />
        </Grid>
        <Grid item>
          <DayHoursPicker
            day={t("restaurant.restaurantInfoPage.hoursPicker.typicalDay")}
            hours={typicalHours}
            setHours={setTypicalHours}
            fullwidth
          />
        </Grid>
        <Grid item container justifyContent={"space-between"}>
          <Button variant="outlined" color="error" onClick={onClose}>
            {t("common.cancel")}
          </Button>
          <Button
            color="success"
            onClick={() => {
              onSave(typicalHours);
              onClose();
            }}
            variant="outlined"
          >
            {t("restaurant.restaurantInfoPage.hoursPicker.applyToAll")}
          </Button>
        </Grid>
      </Grid>
    </CustomModal>
  );
};

export default HoursPickerModal;
