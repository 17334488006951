import { Button, Grid } from "@mui/material";
import { CustomModal, CustomText } from "src/design";

interface BookingsModalProps {
  onSubmit: () => void;
  setShow: (show: boolean) => void;
  disclaimer: string;
  title: string;
  show: boolean;
}

const BookingsModal = ({
  onSubmit,
  show,
  setShow,
  disclaimer,
  title,
}: BookingsModalProps) => (
  <CustomModal show={show} setShow={setShow}>
    <Grid container direction="column" rowSpacing={5} alignItems={"center"}>
      <Grid item>
        <CustomText label={title} type="header" />
      </Grid>
      <Grid item>
        <CustomText label={disclaimer} type="body" />
      </Grid>
      <Grid item container justifyContent={"space-between"}>
        <Button color="error" onClick={() => setShow(false)}>
          Cancel
        </Button>
        <Button color="success" onClick={() => onSubmit()}>
          Confirm
        </Button>
      </Grid>
    </Grid>
  </CustomModal>
);

export default BookingsModal;
