import { useEffect, useState, useContext } from "react";
import type { Deal } from "src/business/deal";
import { RestaurantContext } from "src/Utils/Context/RestaurantContext";
import { UserContext } from "src/Utils/Context/UserContext";

interface Props {
  restaurant_id?: string;
  limit?: number;
}

const useGetDeals = ({ restaurant_id, limit }: Props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [deals, setDeals] = useState<Deal[]>([]);
  const { userInfo } = useContext(UserContext);
  const { restaurants, isLoading: restaurantsLoading } =
    useContext(RestaurantContext);

  const path = restaurant_id ? `GetDealsByRestaurant.php` : `GetDeals.php`;

  const fetchDeals = () => {
    fetch(`https://laddition.ma/Website/${path}`, {
      method: "POST",
      body: JSON.stringify({
        ownerId: userInfo?.id,
        ownerToken: userInfo?.session_token,
        limit: limit,
        restaurant_id: restaurant_id,
      }),
    })
      .then((response) => response.json())
      .then((data: { success: boolean; deals: Deal[] }) => {
        console.log(data);
        if (data.success) {
          let deals = data.deals;
          deals.forEach((deal) => {
            deal.restaurantName = restaurants.find(
              (restaurant) => restaurant.restaurant_id === deal.restaurant_id
            )?.Nom as string;
          });
          setDeals(deals);
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    setIsLoading(true);

    if (!restaurantsLoading) {
      fetchDeals();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.id, userInfo?.session_token, restaurantsLoading, restaurants]);

  return { isLoading, deals, refetch: fetchDeals };
};

export default useGetDeals;
