import { Box } from "@mui/system";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import TrendingDownIcon from "@mui/icons-material/TrendingDown";
import { CustomText } from "src/design/CustomText";
import { emerald } from "src/design/ColorPalette";

export const TrendChip = ({ progress }: { progress: number }) =>
  progress > 0 ? (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 0.5,
      }}
    >
      <CustomText
        label={`${Math.round(progress * 100)} %`}
        type="helper"
        fontcolor={emerald}
        fontFamily="sans-serif"
      />
      <TrendingUpIcon color="success" fontSize="small" />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        padding: 0.5,
      }}
    >
      <CustomText
        label={`${Math.round(progress * 100)}%`}
        type="helper"
        fontcolor="rgba(229, 9, 20)"
      />
      <TrendingDownIcon color="error" fontSize="small" />
    </Box>
  );
