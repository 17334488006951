import { UserContext } from "src/Utils/Context/UserContext";
import { useContext, useState } from "react";
import { Deal } from "src/business/deal";

const useUpdateDeal = () => {
  const { userInfo } = useContext(UserContext);
  const [updating, setUpdating] = useState(false);

  const updateDeal = async (deal: Deal) => {
    setUpdating(true);
    const response = await fetch(
      `https://laddition.ma/Website/UpdateDeal.php`,
      {
        method: "POST",
        body: JSON.stringify({
          ownerId: userInfo?.id,
          ownerToken: userInfo?.session_token,
          id: deal.id_offre,
          description: deal.description,
          is_event: Number(deal.is_event),
          restaurant_id: deal.restaurant_id,
          hours: deal.crenaux,
          days: deal.jours,
        }),
      }
    );

    const updateResponse = response.json();
    setUpdating(false);
    return updateResponse;
  };

  return { updateDeal, updating };
};

export default useUpdateDeal;
